import * as conditionRulesEnums from "./conditionRules"

export * from "./conditionRules"

export const userRoles = {
    owner: 1,
    administrator: 2,
    manager: 3,
    talent: 4,
    imageProcessor: 999,
} as const

export const userRolesMap = {
    1: "owner",
    2: "administrator",
    3: "manager",
    4: "talent",
} as const

export const relationsNames = {
    buddy: "Buddy",
    hrManager: "HR Manager",
    manager: "Manager",
} as const

export const userStates = {
    draft: 1,
    active: 2,
    archived: 3,
    deleted: 4,
    imported: 5,
} as const

export const activityType = {
    unknown: 1,
    invalidPasswordAttempt: 2,
    login: 3,
    logout: 4,
    partnerCreated: 5,
} as const

export const associationType = {
    userAssociation: 1,
    companyAssociation: 2,
    companyRoleAssociation: 3,
} as const

export const companyRoles = {
    system: 1,
    partner: 2,
    clientCompany: 3,
    subdivision: 4,
} as const

export const journeyStates = {
    draft: 1,
    live: 2,
    archived: 3,
    deleted: 4,
} as const

export enum fileType {
    image = 1,
    video = 2,
    document = 3,
    other = 4,
}

export const region = {
    global: "global",
    europe: "europe",
} as const

export const pageElementType = {
    header: 1,
    text: 2,
    image: 3,
    video: 4,
    externalReference: 5,
    button: 6,
    score: 7,
    essay: 8,
    multipleChoice: 9,
    opinionScale: 10,
    rating: 11,
    ranking: 12,
    matching: 13,
    embed: 14,
} as const

export const interactiveElementTypes = {
    essay: 8,
    multipleChoice: 9,
    opinionScale: 10,
    rating: 11,
    ranking: 12,
    matching: 13,
} as const

export enum fileVersionType {
    original = "original",
    thumbnail = "thumbnail",
    contentLogo = "contentLogo",
    fullHD = "fullhd",
    standardHD = "standardhd",
    desktop = "desktop",
    mobile = "mobile",
    avatar = 1,
}

export const contentType = {
    content: "content",
    message: "message",
    todo: "todo",
} as const

export enum serverFileVersion {
    original = 1,
    desktop = 2,
    mobile = 3,
    thumbnail = 4,
    contentLogo = 5,
    fullHD = 6,
    standardHD = 7,
}

export const serverFileVersionMap = {
    [serverFileVersion.original]: fileVersionType.original,
    [serverFileVersion.desktop]: fileVersionType.desktop,
    [serverFileVersion.mobile]: fileVersionType.mobile,
    [serverFileVersion.thumbnail]: fileVersionType.thumbnail,
    [serverFileVersion.contentLogo]: fileVersionType.contentLogo,
    [serverFileVersion.fullHD]: fileVersionType.fullHD,
    [serverFileVersion.standardHD]: fileVersionType.standardHD,
} as const

export const inviteStatus = {
    pending: 1,
    accepted: 2,
    declined: 3,
    revoked: 4,
    expired: 5,
} as const

export const userJourneyStatus = {
    draft: 1, // used when the invite is in pending
    active: 2,
    archived: 3,
    deleted: 4,
} as const

// only first four valid. The rest check with backend when will be implemented
export const integrations = {
    smartRecruiters: 1,
    homeRun: 2,
    recruitee: 3,
    bambooHr: 4,
    greenhouse: 5,
    workday: 6,
    hrOffice: 7,
    talentSoft: 8,
} as const

export const validations = {
    lengthCorrect: 1,
    upperAndLowerCase: 2,
    oneNumber: 3,
    oneSpecialChar: 4,
} as const

export const answerStatus = {
    correct: 1,
    incorrect: 2,
    neutral: 3,
} as const

export const communicationTypes = {
    email: 1,
    textMessage: 2,
    pushNotification: 3,
} as const

export enum eventTypes {
    custom = 3,
    journeyLeft = 2,
    journeyAccepted = 1,
}

export const pageTypes = {
    header: 1,
    text: 2,
    image: 3,
    video: 4,
    externalReference: 5,
    button: 6,
    score: 7,
    essay: 8,
    multipleChoice: 9,
    opinionScale: 10,
    rating: 11,
    ranking: 12,
    matching: 13,
    embed: 14,
} as const

export const roleStates = {
    draft: 1,
    active: 2,
    archived: 3,
} as const

export const introductionStoryTypes = {
    introduction: 2,
    introductionTemplate: 3,
} as const
export const storyTypes = {
    content: 1,
    ...introductionStoryTypes,
} as const

export const accessTypes = {
    /// <summary>
    /// Indicates that a user has direct access to the company
    /// </summary>
    direct: 1,
    /// <summary>
    /// Indicates that a user has a user role in a company higher in the company tree
    /// </summary>
    inherited: 2,
} as const

export enum serverLanguages {
    english = 1,
    dutch = 2,
    spanish = 3,
}

export default {
    userRoles,
    activityType,
    associationType,
    companyRoles,
    journeyStates,
    fileType,
    region,
    pageElementType,
    fileVersionType,
    inviteStatus,
    integrations,
    validations,
    answerStatus,
    communicationTypes,
    eventTypes,
    pageTypes,
    ...conditionRulesEnums,
}
