import { createSelector } from "reselect"

import { createReducer } from "redux/utility"

import { createUiFeatureSelector } from "../helpers"
import * as selectors from "./selectors"
import { initialState, reducers } from "./reducers"
import { reducerName } from "./actionNames"

export * from "./reducers"

// selectors
export const selectFeature = createUiFeatureSelector(reducerName)

export const selectSelectedTheme = createSelector(
    selectFeature,
    selectors.selectSelectedTheme,
)

export const selectSelectedLanguage = createSelector(
    selectFeature,
    selectors.selectSelectedLanguage,
)

export const selectShowInheritedRoles = createSelector(
    selectFeature,
    selectors.selectShowInheritedRoles,
)

export const reducer = createReducer(reducers, initialState)
