import get from "lodash/get"
import { createSelector } from "reselect"
import { outerJoin } from "lib/utils/helpers/data-sets"

// Selectors
export const selectData = state => state?.data
export const selectIdentifiers = state => state?.identifiers
export const selectSelectedHash = state => state?.selectedHash
export const selectIsLoading = state => state?.isLoading
export const selectHasFetched = state => state?.hasFetched
export const selectHasFetchedUser = state => state?.hasFetchedLoggedIn
export const selectHasFetchedJourneyUsers = state =>
    state?.hasFetchedJourneyUsers
export const selectLoadingError = state => state?.loadingError
export const selectCurrentInversedRelations = state =>
    state?.currentInversedRelations

export const selectLoggedInHash = state => state?.loggedInUserHash

export const selectUsers = createSelector(
    selectIdentifiers,
    selectData,
    outerJoin,
)

export const selectLoggedInUser = createSelector(
    selectData,
    selectLoggedInHash,
    get,
)
export const selectUserFirstName = createSelector(
    selectLoggedInUser,
    user => user?.firstName,
)

export const selectLoggedInUserFullName = createSelector(
    selectLoggedInUser,
    user => `${user?.firstName} ${user?.lastName}`,
)

export const selectSelectedUser = createSelector(
    selectData,
    selectSelectedHash,
    get,
)

export const selectCurrentUserRelations = createSelector(
    selectSelectedUser,
    user => user?.userRelations || [],
)

export const selectCurrentUserInversedRelations = createSelector(
    selectSelectedUser,
    user => user?.inversedRelations || [],
)
