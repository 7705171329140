import keys from "lodash/keys"
import map from "lodash/map"
import get from "lodash/get"
import first from "lodash/first"
import difference from "lodash/difference"
import find from "lodash/find"

import { userRoles } from "constants/enums"
import { merge } from "lib/utils/helpers/instances"

import { CREATE, DELETE, SELECT, UPDATE, ADD } from "redux/utility"
import { ERROR, LOADED, LOADING } from "redux/middleware/actionNames"
import { mapInviteData } from "redux/mappers/users/helpers"

import {
    LOGOUT,
    LOGIN,
    VALIDATE_EMAIL,
} from "redux/reducers/core/authentication/actionNames"
import { COMPANY } from "redux/reducers/entities/companies/actionNames"
import { INVITE } from "redux/reducers/entities/invites/actionNames"
import { USER_INVITE } from "redux/reducers/entities/userInvites/actionNames"
import { JOURNEY } from "redux/reducers/entities/journeys/actionNames"

import normalize from "./schema"
import {
    mapInviteOntoExisting,
    mapInviteToNew,
    mapCurrentJourneyTalentResult,
} from "./helpers"
import {
    selectData,
    selectIdentifiers,
    selectLoggedInHash,
    selectLoggedInUser,
    selectHasFetched,
} from "./selectors"
import {
    USER,
    LOGGED_IN_USER,
    USER_RESEND_MAIL,
    USERS,
    JOURNEY_TALENTS,
    USER_RELATIONS,
    USER_RELATIONS_INVERSED,
    MULTIPLE,
    USER_ROLES,
} from "./actionNames"

// required variables
export const initialState = {
    /* Holds the normalized users redux object */
    data: {},
    /* Holds the currently logged in user */
    loggedInUserHash: null,
    /* Holds the identifiers for the users */
    identifiers: [],
    /* Indicates whether or not users have been fetched */
    hasFetched: false,
    /* Indicates whether or not users of the current journey have been fetched */
    hasFetchedJourneyUsers: false,
    /* Indicates whether or not logged in user has been fetched */
    hasFetchedLoggedIn: false,
    /* Are we loading new courses */
    isLoading: false,
    /* Loading courses resulted in an error */
    loadingError: null,
    /* Holds the hash of the logged in user */
    selectedHash: null,
}

export const reducers = {
    [LOGGED_IN_USER + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [LOGGED_IN_USER + LOADED](
        state,
        { result, originalPayload: { companyHash } },
    ) {
        const data = selectData(state)
        const oldIdentifiers = selectIdentifiers(state)
        const { userHash, companies, email, ...userData } = result
        const loggedInUser = data[userHash] || {}
        const selectedCompany =
            find(companies, { hash: companyHash }) || first(companies)
        const roles = selectedCompany?.roles || []
        let identifiers = oldIdentifiers

        if (!oldIdentifiers.includes(userHash)) {
            identifiers = [userHash, ...oldIdentifiers]
        }

        return {
            ...state,
            data: {
                ...data,
                [userHash]: {
                    ...loggedInUser,
                    emailAddress: email,
                    ...userData,
                    userHash,
                    roles,
                },
            },
            identifiers,
            loggedInUserHash: userHash,
            isLoading: false,
            hasFetchedLoggedIn: true,
        }
    },

    [LOGGED_IN_USER + ERROR](state, payload) {
        const { result } = payload || {}

        return {
            ...state,
            isLoading: false,
            hasFetchedLoggedIn: true,
            loadingError: result,
        }
    },

    [USER + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER + LOADED](
        state,
        { result: { user }, originalPayload: { userHash } },
    ) {
        const oldData = selectData(state)
        const data = {
            ...oldData,
            [userHash]: {
                ...user,
            },
        }
        const identifiers = keys(data)

        return {
            ...state,
            isLoading: false,
            data,
            identifiers,
        }
    },

    [USER + ERROR](state, payload) {
        return {
            ...state,
            isLoading: false,
            loadingError: payload.result,
        }
    },

    [USERS + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [USERS + LOADED](state, { result }) {
        const users = map(result, "user")
        const [identifiers, data] = normalize(users)

        return {
            ...state,
            data,
            identifiers,
            isLoading: false,
            hasFetched: true,
            loadingError: null,
        }
    },

    [USERS + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError,
        }
    },

    [JOURNEY_TALENTS + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [JOURNEY_TALENTS + LOADED](
        state,
        { result, originalPayload: { journeyHash } },
    ) {
        const users = mapCurrentJourneyTalentResult(result, journeyHash)
        const oldData = selectData(state)
        const oldIdentifiers = selectIdentifiers(state)

        const [newIdentifiers, newData] = normalize(users)
        const data = merge(oldData, newData)
        const diff = difference(newIdentifiers, oldIdentifiers)

        return {
            ...state,
            data,
            identifiers: [...oldIdentifiers, ...diff],
            isLoading: false,
            hasFetchedJourneyUsers: true,
        }
    },

    [JOURNEY_TALENTS + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            isLoading: false,
            hasFetchedJourneyUsers: true,
            loadingError,
        }
    },

    [USER + CREATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER + CREATE + LOADED](
        state,
        {
            result: { hash: userHash },
            originalPayload: { password, passwordConfirmation, ...userData },
        },
    ) {
        const data = selectData(state)
        const identifiers = selectIdentifiers(state)

        return {
            ...state,
            isLoading: false,
            data: {
                ...data,
                [userHash]: {
                    userHash,
                    ...userData,
                },
            },
            identifiers: [...identifiers, userHash],
            loggedInUserHash: userHash,
        }
    },

    [USER + CREATE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [USER + UPDATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER + UPDATE + LOADED](state, { originalPayload: { ...updatedUser } }) {
        const data = selectData(state)
        const hash = selectLoggedInHash(state)
        const currentEditedUser = data[hash]

        return {
            ...state,
            isLoading: false,
            data: {
                ...data,
                [hash]: {
                    ...currentEditedUser,
                    ...updatedUser,
                },
            },
        }
    },

    [USER + UPDATE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [USER + DELETE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER + DELETE + LOADED](state, { originalPayload: { user } }) {
        const identifiers = selectIdentifiers(state)
        if (!identifiers.includes(user.userHash)) return state

        const data = { ...selectData(state) }
        delete data[user.userHash]

        return {
            ...state,
            data,
            isLoading: false,
            identifiers: identifiers.filter(id => id !== user.userHash),
            selectedHash: null,
        }
    },

    [USER + DELETE + ERROR](state, { result }) {
        return {
            ...state,
            isLoading: false,
            loadingError: result,
        }
    },

    [COMPANY + CREATE + LOADED](state) {
        const data = selectData(state)
        const { userHash, ...userData } = selectLoggedInUser(state)

        return {
            ...state,
            data: {
                ...data,
                [userHash]: {
                    ...userData,
                    roles: [userRoles.owner],
                },
            },
        }
    },

    [USER_RESEND_MAIL + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER_RESEND_MAIL + LOADED](state) {
        return { ...state, isLoading: false }
    },

    [USER_RESEND_MAIL + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [USER + SELECT](state, { selectedHash }) {
        return { ...state, selectedHash }
    },

    [USER + USER_RELATIONS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER + USER_RELATIONS + LOADED](state, { result, originalPayload }) {
        const { userRelations } = result
        const { userHash } = originalPayload
        const data = selectData(state)
        const user = data[userHash]

        return {
            ...state,
            data: {
                ...data,
                [userHash]: {
                    ...user,
                    userRelations,
                },
            },
            isLoading: false,
        }
    },

    [USER + USER_RELATIONS + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [USER + USER_RELATIONS_INVERSED + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER + USER_RELATIONS_INVERSED + LOADED](
        state,
        { result, originalPayload },
    ) {
        const { userRelations } = result
        const { userHash } = originalPayload
        const data = selectData(state)
        const user = data[userHash]

        return {
            ...state,
            data: {
                ...data,
                [userHash]: {
                    ...user,
                    inversedRelations: userRelations,
                },
            },
            isLoading: false,
            currentInversedRelations: userRelations,
        }
    },

    [USER + USER_RELATIONS_INVERSED + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [USER + ADD + USER_RELATIONS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER + ADD + USER_RELATIONS + LOADED](state, { originalPayload }) {
        const { userRelations, userHash } = originalPayload
        const data = selectData(state)
        const user = data[userHash]

        return {
            ...state,
            data: {
                ...data,
                [userHash]: {
                    ...user,
                    userRelations,
                },
            },
            isLoading: false,
        }
    },

    [USER + ADD + USER_RELATIONS + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [USER + UPDATE + USER_RELATIONS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER + UPDATE + USER_RELATIONS + LOADED](state, { originalPayload }) {
        const { userRelations, userHash } = originalPayload
        const data = selectData(state)
        const user = data[userHash]

        return {
            ...state,
            data: {
                ...data,
                [userHash]: {
                    ...user,
                    userRelations,
                },
            },
            isLoading: false,
        }
    },

    [USER + UPDATE + USER_RELATIONS + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [USER + UPDATE + MULTIPLE + USER_RELATIONS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER + UPDATE + MULTIPLE + USER_RELATIONS + LOADED](
        state,
        { originalPayload },
    ) {
        const { userRelations } = originalPayload
        const users = selectData(state)
        let updatedUsers = { ...users }

        for (let idx = 0; idx < userRelations?.length; idx += 1) {
            const { targetUserHash } = userRelations[idx]
            const previousUserRelations = users[targetUserHash]?.userRelations
            const previousRelations = previousUserRelations?.filter(
                previousRelation => {
                    const updatedRelation = userRelations[
                        idx
                    ].userRelations.find(
                        relation =>
                            relation.relationDefinitionHash ===
                            previousRelation.relationDefinitionHash,
                    )
                    if (updatedRelation) {
                        return false
                    }
                    return true
                },
            )
            const updatedRelations = [
                ...previousRelations,
                ...userRelations[idx].userRelations,
            ]

            updatedUsers = {
                ...updatedUsers,
                [targetUserHash]: {
                    ...users[targetUserHash],
                    userRelations: updatedRelations,
                },
            }
        }

        return {
            ...state,
            data: {
                ...updatedUsers,
            },
            isLoading: false,
        }
    },

    [USER + UPDATE + MULTIPLE + USER_RELATIONS + ERROR](
        state,
        { result: loadingError },
    ) {
        return { ...state, isLoading: false, loadingError }
    },

    [USER_ROLES + DELETE + LOADED](
        state,
        { originalPayload: { userHash, invites } },
    ) {
        const data = selectData(state)
        const user = data[userHash]

        const { user: updatedUser } = mapInviteData(null, {
            user: { ...user },
            invites,
        })

        if (!updatedUser.inviteHashes.length) {
            const identifiers = selectIdentifiers(state)

            const updatedData = { ...data }
            delete updatedData[userHash]

            return {
                ...state,
                data: updatedData,
                identifiers: identifiers.filter(id => id !== userHash),
            }
        }

        return {
            ...state,
            data: {
                ...data,
                [userHash]: updatedUser,
            },
        }
    },

    // INVITES RELATED ACTIONS

    [INVITE + CREATE + LOADED](
        state,
        { result: invite, originalPayload: { role, journeyName, journeyHash } },
    ) {
        const identifiers = selectIdentifiers(state)
        const data = selectData(state)
        const existingUser = get(data, invite.userHash)
        const user = existingUser
            ? mapInviteOntoExisting(existingUser, invite, journeyName, role)
            : mapInviteToNew(invite, journeyName, role, journeyHash)

        return {
            ...state,
            data: {
                ...data,
                [user.userHash]: user,
            },
            identifiers: existingUser
                ? identifiers
                : [...identifiers, user.userHash],
        }
    },

    [INVITE + DELETE + LOADED](
        state,
        { originalPayload: { userHash, invites } },
    ) {
        const data = { ...selectData(state) }
        const user = get(data, userHash)

        const { user: updatedUser } = mapInviteData(null, {
            user: { ...user },
            invites,
        })

        if (!updatedUser.inviteHashes.length) {
            const identifiers = selectIdentifiers(state)

            const updatedData = { ...data }
            delete updatedData[userHash]

            return {
                ...state,
                data: updatedData,
                identifiers: identifiers.filter(id => id !== userHash),
            }
        }

        return {
            ...state,
            data: {
                ...data,
                [userHash]: updatedUser,
            },
        }
    },

    [USER_INVITE + UPDATE + LOADED](
        state,
        { originalPayload: { company: result } },
    ) {
        const loggedInUser = selectLoggedInUser(state)

        if (!result || result?.userHash !== loggedInUser?.userHash) return state

        return {
            ...state,
            data: {
                [loggedInUser?.userHash]: {
                    ...loggedInUser,
                    roles: [...loggedInUser.roles, first(result.roles)],
                },
            },
        }
    },

    // AUTH RELATED ACTIONS
    [LOGIN + LOADED](state, { result, originalPayload: { username } }) {
        const { userHash, companies, email, ...userData } = result
        const roles = first(companies)?.roles

        return {
            ...state,
            data: {
                [userHash]: {
                    ...userData,
                    userHash,
                    emailAddress: username,
                    roles,
                },
            },
            loggedInUserHash: userHash,
            hasFetchedLoggedIn: true,
        }
    },

    [VALIDATE_EMAIL + LOADED](state, { result }) {
        const data = selectData(state)
        const { userHash, companies, email } = result
        const user = data[userHash] || {}
        const roles = first(companies)?.roles || []

        return {
            ...state,
            data: {
                ...data,
                [userHash]: {
                    ...user,
                    userHash,
                    emailAddress: email,
                    roles,
                },
            },
            loggedInUserHash: userHash,
        }
    },

    // Reset reducers
    [JOURNEY + SELECT](state) {
        const hasFetchedJourneyUsers = selectHasFetched(state)

        return {
            ...state,
            hasFetchedJourneyUsers,
        }
    },

    [COMPANY + SELECT](state) {
        const loggedInUser = selectLoggedInUser(state)

        return {
            ...state,
            data: {
                [loggedInUser?.userHash]: loggedInUser,
            },
            identifiers: [loggedInUser?.userHash],
            hasFetched: false,
            loadingError: null,
        }
    },

    [LOGOUT + LOADED]() {
        return initialState
    },
}
