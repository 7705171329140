import { RequestError } from "redux/reducers/entities/types"
import { Authentication, AuthenticationState } from "./types"

// Selectors
export const selectData = (
    state: AuthenticationState,
): Authentication | null | undefined => state?.data
export const selectIsLoggedIn = (state: AuthenticationState): boolean =>
    !!state?.data?.token
export const selectAuthToken = (
    state: AuthenticationState,
): string | undefined => state?.data?.token
export const selectRefreshToken = (
    state: AuthenticationState,
): string | undefined => state?.data?.refreshToken
export const selectIsRefreshingAuthToken = (
    state: AuthenticationState,
): boolean => state?.isRefreshingAuthToken
export const selectEmailChecked = (state: AuthenticationState): boolean =>
    state?.emailChecked

export const selectConfirmationEmailSent = (
    state: AuthenticationState,
): boolean => state?.confirmationEmailSent
export const selectRedirectUrl = (state: AuthenticationState): string =>
    state.redirectUrl
export const selectIsLoading = (state: AuthenticationState): boolean =>
    state?.isLoading
export const selectLoadingError = (
    state: AuthenticationState,
): RequestError | null | undefined => state?.data?.loadingError
export const selectHasConfirmedEmail = (state: AuthenticationState): boolean =>
    !!state?.data?.hasConfirmedEmail
export const selectDidLogout = (state: AuthenticationState): boolean =>
    state?.didLogout
export const selectCurrentEmail = (
    state: AuthenticationState,
): string | undefined => state?.data?.email
