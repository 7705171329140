import { webSocketUrl } from "constants/index"
import { selectLoggedInHash } from "redux/reducers/entities/users"
import {
    WEBSOCKET_CONNECT,
    WEBSOCKET_DISCONNECT,
    WEBSOCKET_DISCONNECTED,
    WEBSOCKET_CONNECTED,
} from "./actionNames"

export const createWebSocketConnectAction = () => (dispatch, getState) => {
    const state = getState()
    const loggedInUserHash = selectLoggedInHash(state)

    if (loggedInUserHash) {
        dispatch({
            type: WEBSOCKET_CONNECT,
            payload: {
                url: `${webSocketUrl}?userHash=${loggedInUserHash}`,
            },
        })
    }
}
export const createWebSocketConnectedAction = () => ({
    type: WEBSOCKET_CONNECTED,
})
export const createWebSocketDisconnectAction = () => ({
    type: WEBSOCKET_DISCONNECT,
})
export const createWebSocketDisconnectedAction = () => ({
    type: WEBSOCKET_DISCONNECTED,
})
