import get from "lodash/get"

const styles = theme => ({
    root: {
        width: "576px",
        height: "478px",
        backgroundColor: get(theme, "palette.primary.main"),
        padding: `${theme.spacing(15)}px ${theme.spacing(9)}px ${theme.spacing(
            8,
        )}px`,
        borderRadius: get(theme, "appicalTheme.sizes.borderRadius"),

        [theme.breakpoints.down("xs")]: {
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            margin: 0,
            borderRadius: "0",
        },
    },

    title: {
        ...theme.typography.h4,
        textAlign: "center",
        padding: 0,
    },

    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        "& > *": {
            marginTop: theme.spacing(3),
        },
    },

    buttonGroup: {
        marginTop: theme.spacing(7),

        "& > * + *": {
            marginTop: theme.spacing(3),
        },
    },
})

export default styles
