import { schema, normalize } from "normalizr"

export const userSchema = new schema.Entity(
    "users",
    {},
    { idAttribute: "userHash" },
)

export default originalData => {
    const {
        result: keys,
        entities: { users: normalizedData },
    } = normalize(originalData, [userSchema])

    return [keys || [], normalizedData || {}]
}
