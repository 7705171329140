import React from "react"
import get from "lodash/get"
import PropTypes from "prop-types"
import { compose } from "redux"
import { withTranslation } from "react-i18next"
import withStyles from "@material-ui/core/styles/withStyles"

import { validations } from "../../../../constants/enums"

import styles from "./styles"

import CheckWithText from "../../CheckWithText"

const ValidationCheckBoxes = ({ t, classes, checks, error }) => (
    <div className={classes.checkBoxesRoot}>
        <CheckWithText
            error={error}
            isValid={get(checks, `${validations.lengthCorrect}`)}
            text={t("main:8_char_long")}
        />
        <CheckWithText
            error={error}
            isValid={get(checks, `${validations.upperAndLowerCase}`)}
            text={t("main:1_upper_and_lower_case")}
        />
        <CheckWithText
            error={error}
            isValid={get(checks, `${validations.oneNumber}`)}
            text={t("main:1_number")}
        />
        <CheckWithText
            error={error}
            isValid={get(checks, `${validations.oneSpecialChar}`)}
            text={t("main:1_special_character")}
        />
    </div>
)

export default compose(
    withStyles(styles),
    withTranslation(),
)(ValidationCheckBoxes)

ValidationCheckBoxes.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    checks: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

ValidationCheckBoxes.defaultProps = {
    checks: {},
    error: null,
}
