import get from "lodash/get"
import { createSelector } from "reselect"

export const selectData = state => get(state, "data")
export const selectIdentifiers = state => get(state, "identifiers")
export const selectIsLoading = state => get(state, "isLoading")
export const selectHasFetched = state => get(state, "hasFetched")
export const selectLoadingError = state => get(state, "loadingError")
export const selectSelectedHash = state => get(state, "selectedHash")

export const selectLibraryRootHash = createSelector(
    selectData,
    selectIdentifiers,
    (data, ids) =>
        ids.find(
            folderHash => get(data, `${folderHash}.name`) === "FileLibrary",
        ),
)

export const selectFolders = createSelector(
    selectData,
    selectLibraryRootHash,
    (data, rootHash) =>
        get(data, `${rootHash}.folderHashes`, []).map(id => data[id]),
)
