import get from "lodash/get"
import { createSelector } from "reselect"

export const selectData = state => get(state, "data")
export const selectIdentifiers = state => get(state, "identifiers")
export const selectIsLoading = state => get(state, "isLoading")
export const selectHasFetched = state => get(state, "hasFetched")
export const selectLoadingError = state => get(state, "loadingError")
export const selectSelectedHash = state => get(state, "selectedHash")

export const selectThemes = createSelector(
    selectData,
    selectIdentifiers,
    (data, identifiers) =>
        identifiers
            .map(id => data[id])
            .sort(theme => (theme.isTemplate ? -1 : 0)),
)

export const selectActiveTheme = createSelector(selectThemes, themes =>
    themes.reduce(
        (active, theme) => (!active && theme.isActive ? theme : active),
        null,
    ),
)
