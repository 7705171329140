import analytics from "./analytics"
import enums from "./enums"
import uri from "./uri"
import toastTypes from "./toastTypes"
import externalKeys from "./externalKeys"
import internal from "./internal"
import dndTypes from "./dndTypes"
import uploadStatus from "./uploadStatus"
import AWS from "./AWS"
import embedly from "./embedly"
import { environmentTypes } from "./environment"

const apiUrlForEnvironment = () => {
    const { REACT_APP_API_URL, REACT_APP_ENV } = process.env

    if (REACT_APP_API_URL) return REACT_APP_API_URL

    switch (REACT_APP_ENV) {
        case environmentTypes.dev:
            return "https://api.appical.io"
        case environmentTypes.translation:
        case environmentTypes.uat:
            return "https://gizem.appic.al"
        case environmentTypes.demo:
            return "https://appical.sale"
        case environmentTypes.prod:
            return "https://ironelf.rocks"
        default:
            return "https://api.appical.io"
    }
}

const webSocketUrlEnvironment = () => {
    const { REACT_APP_ENV } = process.env
    const baseWebSocketUrl =
        "wss://lavujojoza.execute-api.eu-central-1.amazonaws.com"

    switch (REACT_APP_ENV) {
        case environmentTypes.translation:
        case environmentTypes.uat:
            return `${baseWebSocketUrl}/uat`
        case environmentTypes.demo:
            return `${baseWebSocketUrl}/demo`
        case environmentTypes.prod:
            return "wss://5gyoqw4tcl.execute-api.eu-central-1.amazonaws.com/production"
        case environmentTypes.dev:
        default:
            return `${baseWebSocketUrl}/dev`
    }
}

export const webSocketUrl = webSocketUrlEnvironment()

export const baseUrl = apiUrlForEnvironment()

export default {
    baseUrl,
    ...enums,
    uri,
    toastTypes,
    externalKeys,
    dndTypes,
    internal,
    uploadStatus,
    AWS,
    embedly,
    analytics,
}
