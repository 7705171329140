import i18n from "i18next"
import get from "lodash/get"
import flatMap from "lodash/flatMap"
import map from "lodash/map"

import { customFolders } from "../../../../constants/internal"

export const isCustomFolder = name =>
    name === customFolders.giphy || name === customFolders.unsplash

export const mapFolder = ({ folderHash, name, files = [], folders = [] }) => {
    const isCustom = isCustomFolder(name)

    return {
        folderHash,
        name: isCustom ? i18n.t(`main:${name}`) : name,
        isCustom,
        customProviderId: isCustom ? name : null,
        folderHashes: map(folders, "folderHash"),
        fileHashes: map(files, "hash"),
    }
}

export const mapFolders = results =>
    flatMap(results, folder => [
        ...get(folder, "folders", []).map(mapFolder),
        mapFolder(folder),
    ])

export default mapFolders
