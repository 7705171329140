import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Trans, withTranslation } from "react-i18next"
import { reduxForm, Form, Field } from "redux-form"
import { compose } from "redux"
import { Link } from "react-router-dom"

import { withStyles, Typography, Hidden } from "@material-ui/core"
import ExternalLink from "@material-ui/core/Link"

import { policyType } from "constants/policies"

import {
    isRequired,
    isSameAs,
    isValidEmail,
    isValidPassword,
} from "lib/utils/helpers/validation"

import TextField from "lib/views/Fields/TextField"
import ValidatedFormField from "lib/views/Fields/ValidatedFormField"
import CheckboxField from "lib/views/Fields/CheckboxField"

import Button from "lib/views/Button/Button"
import WelcomeTo from "lib/views/WelcomeTo"

import { formStyles as styles } from "./styles"

const hasAValue = errorText =>
    errorText ? isRequired(errorText) : isRequired()
const isAValidEmail = isValidEmail()
const isStrongEnough = isValidPassword()
const sameAsPassword = isSameAs("password", "main:password_confirm_mismatch")
const hasAccepted = isRequired("main:policy_accept_required")

export const formName = "registerUser"

export const CreateUserForm = ({
    t,
    classes,
    handleSubmit,
    isLoading,
    invalid,
    initialValues,
    inviteHash,
    termsOfServicePolicy,
    privacyPolicy,
    isPolicyLoading,
    companyName,
}) => {
    const errors = {
        firstName: t("main:enter_first_name"),
        lastName: t("main:enter_last_name"),
        emailAddress: t("main:enter_email"),
        password: t("main:enter_password"),
        passwordConfirm: t("main:confirm_password"),
    }

    const termOfServiceContent = useMemo(
        () =>
            termsOfServicePolicy?.url ? (
                <ExternalLink
                    color="secondary"
                    rel="noopener"
                    target="_blank"
                    href={termsOfServicePolicy?.url}
                    onClick={e => {
                        e.stopPropagation()
                    }}
                >
                    {t("main:terms_of_service")}
                </ExternalLink>
            ) : (
                t("main:terms_of_service")
            ),
        [t, termsOfServicePolicy],
    )
    const privacyPolicyContent = useMemo(
        () =>
            privacyPolicy?.url ? (
                <ExternalLink
                    color="secondary"
                    rel="noopener"
                    target="_blank"
                    href={privacyPolicy?.url}
                    onClick={e => {
                        e.stopPropagation()
                    }}
                >
                    {t("main:privacy_policy")}
                </ExternalLink>
            ) : (
                t("main:privacy_policy")
            ),
        [t, privacyPolicy],
    )

    return (
        <div className={classes.root}>
            <WelcomeTo
                screenType={inviteHash ? "invite" : "signup"}
                companyName={companyName}
                userState={initialValues?.userState}
            />
            <Form className={classes.bottomSpace} onSubmit={handleSubmit}>
                <div className={classes.formField}>
                    <Field
                        name="firstName"
                        label={t("main:firstName")}
                        component={TextField}
                        validate={
                            initialValues?.invite
                                ? null
                                : [hasAValue(errors.firstName)]
                        }
                        disabled={isLoading}
                        defaultValue={
                            initialValues?.invite
                                ? initialValues?.firstName
                                : ""
                        }
                        autoFocus
                    />
                </div>

                <div className={classes.formField}>
                    <Field
                        name="lastName"
                        label={t("main:lastName")}
                        component={TextField}
                        validate={
                            initialValues?.invite
                                ? null
                                : [hasAValue(errors.lastName)]
                        }
                        disabled={isLoading}
                        defaultValue={
                            initialValues?.invite ? initialValues?.lastName : ""
                        }
                    />
                </div>

                <div className={classes.formField}>
                    <Field
                        name="emailAddress"
                        type="email"
                        label={t("main:email")}
                        component={TextField}
                        validate={
                            initialValues?.invite
                                ? null
                                : [
                                      hasAValue(errors.emailAddress),
                                      isAValidEmail,
                                  ]
                        }
                        disabled={isLoading || initialValues?.invite}
                        defaultValue={
                            initialValues?.invite
                                ? initialValues?.emailAddress
                                : ""
                        }
                    />
                </div>

                <div className={classes.formField}>
                    <Field
                        name="password"
                        type="password"
                        label={t("main:password")}
                        component={ValidatedFormField}
                        disableValidationAdornment
                        disableAutoCapitalize
                        showStrength
                        validate={[hasAValue(errors.password), isStrongEnough]}
                        disabled={isLoading}
                    />
                </div>

                <div className={classes.formFieldLast}>
                    <Field
                        name="passwordConfirmation"
                        type="password"
                        label={t("main:password_confirmation")}
                        strongPassword
                        component={ValidatedFormField}
                        validate={[
                            hasAValue(errors.passwordConfirm),
                            sameAsPassword,
                        ]}
                        disableValidationAdornment
                        disableAutoCapitalize
                        disabled={isLoading}
                    />
                </div>

                {inviteHash && (
                    <React.Fragment>
                        <div className={classes.formField}>
                            <Field
                                required
                                name="agreesToAllPolicies"
                                label={
                                    <React.Fragment>
                                        {t("main:agree_appical")}
                                        {termOfServiceContent}
                                        {" & "}
                                        {privacyPolicyContent}
                                    </React.Fragment>
                                }
                                component={CheckboxField}
                                validate={hasAccepted}
                                disabled={isPolicyLoading || isLoading}
                                small
                            />
                        </div>

                        <div className={classes.formField}>
                            <Field
                                name="wantsPromotionalMail"
                                label={t("main:agree_promotional_mail")}
                                component={CheckboxField}
                                disabled={isLoading}
                                small
                                noValidation
                            />
                        </div>
                    </React.Fragment>
                )}

                <Button
                    className={classes.button}
                    color="secondary"
                    type="submit"
                    variant="contained"
                    disabled={isLoading || invalid}
                    loader={isLoading}
                >
                    {t("main:create_account_link")}
                </Button>

                <Hidden smDown={initialValues?.invite} smUp>
                    <Typography className={classes.center} variant="body1">
                        <Trans i18nKey="main:have_account">
                            <Link className={classes.link} to="/login">
                                Login
                            </Link>
                        </Trans>
                    </Typography>
                </Hidden>
            </Form>
        </div>
    )
}

CreateUserForm.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    isLoading: PropTypes.bool,
    initialValues: PropTypes.object,
    inviteHash: PropTypes.string,
    companyName: PropTypes.string,
    isPolicyLoading: PropTypes.bool,
    termsOfServicePolicy: PropTypes.shape({
        type: PropTypes.oneOf([...Object.values(policyType)]),
        url: PropTypes.string,
    }),
    privacyPolicy: PropTypes.shape({
        type: PropTypes.oneOf([...Object.values(policyType)]),
        url: PropTypes.string,
    }),
}

CreateUserForm.defaultProps = {
    invalid: false,
    isLoading: false,
    initialValues: null,
    inviteHash: null,
    isPolicyLoading: false,
    termsOfServicePolicy: null,
    privacyPolicy: null,
    companyName: "",
}

export default compose(
    withStyles(styles, { name: "CreateUserForm" }),
    withTranslation(),
    reduxForm({
        form: formName,
        destroyOnUnmount: false,
    }),
)(CreateUserForm)
