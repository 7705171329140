import { Dispatch } from "redux"
import { SELECT } from "redux/utility"

import { CombinedState } from "redux/reducers/types"
import { ENVIRONMENT } from "./actionNames"
import { selectData } from "./index"

export const createLoadingAction = (name: string) => ({
    type: ENVIRONMENT,
    request: {
        method: "GET",
        url: `/editor/Company/Settings/${name}`,
    },
    payload: { name },
})

export const createSelectEnvironmentAction = (
    name: string,
    isSubdomain: boolean,
    isReady = false,
) => ({
    type: ENVIRONMENT + SELECT,
    payload: { name, isReady, isSubdomain },
})

export const createSelectLoadEnvironmentAction = (
    name: string,
    isSubdomain = false,
) => (dispatch: Dispatch, getState: () => CombinedState) => {
    const state = getState()
    const hasEnvironment = !!selectData(state)?.name

    if (name && !hasEnvironment) {
        dispatch(createLoadingAction(name))
    }

    dispatch(createSelectEnvironmentAction(name, isSubdomain, hasEnvironment))
}
