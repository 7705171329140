const styles = theme => ({
    root: {
        display: "none",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        flexWrap: "wrap",
        flexDirection: "column",
        padding: `0 ${theme.spacing()}px`,
        color: theme?.palette?.primary?.dark,
        backgroundColor: theme?.appicalTheme?.mainBg,

        "&$loading": {
            display: "flex",
        },

        "&$transparent": {
            backgroundColor: "transparent",
        },
    },

    primary: {
        color: theme?.palette?.primary?.dark,
    },

    secondary: {
        color: theme?.palette?.secondary?.dark,
    },

    textPrimary: {
        color: theme?.palette?.primary?.contrastText,
    },

    textSecondary: {
        color: theme?.palette?.secondary?.contrastText,
    },

    loading: {},
    transparent: {},
    background: {},

    appicalLogo: {
        textAlign: "center",
        marginBottom: "32px",
    },

    loader: {
        width: "100%",
        maxWidth: "350px",
    },
})

export default styles
