import { create } from "jss"
import extend from "jss-plugin-extend"
import { jssPreset } from "@material-ui/core/styles"

const configureJss = () =>
    create({
        plugins: [...jssPreset().plugins, extend()],
    })

export default configureJss
