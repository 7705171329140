import { createSelector } from "reselect"
import get from "lodash/get"
import { createBrowserHistory } from "history"
import { matchPath } from "react-router"

import { routesApp } from "App/RoutesApp/helpers"
import { createReducer, createFeatureSelector } from "redux/utility"

import {
    selectIsSubdomain,
    selectActiveName,
    selectEnvironmentCompanies,
} from "redux/reducers/core/environment"
import { reducers, initialState } from "redux/reducers/router/reducers"
import { reducerName } from "redux/reducers/router/actionNames"

import * as selectors from "redux/reducers/router/selectors"

export * from "redux/reducers/router/reducers"

export const history = createBrowserHistory()

export const selectFeature = createFeatureSelector(reducerName)

export const selectCompanyRoutePrefix = createSelector(
    selectIsSubdomain,
    selectEnvironmentCompanies,
    selectActiveName,
    (isSubdomain, [company], activeName) =>
        isSubdomain ? "" : `/${activeName || get(company, "environment")}`,
)

export const selectTrails = createSelector(
    selectFeature,
    selectors.selectTrails,
)

export const selectPathname = createSelector(
    selectFeature,
    selectors.selectPathname,
)

export const selectLastVisitedUrl = createSelector(
    selectTrails,
    selectPathname,
    selectCompanyRoutePrefix,
    (trails, pathname, routePrefix) => {
        const lastVisitedUrl = trails.find(trail => trail !== pathname)
        const isUserEditing = pathname.includes("editor")

        return (
            lastVisitedUrl?.replace(routePrefix, "") ||
            `${routePrefix}${isUserEditing ? "/editor" : ""}`
        )
    },
)

export const selectIsFirstRendering = createSelector(
    selectFeature,
    selectors.selectIsFirstRendering,
)

export const selectPreviewingMatch = createSelector(selectPathname, path =>
    matchPath(path, `*${routesApp.preview}`),
)

export const selectIsPreviewing = createSelector(
    selectPreviewingMatch,
    match => !!match,
)

export const selectIsGlossaryOpen = createSelector(
    selectPathname,
    path => !!matchPath(path, `*${routesApp.glossary}`),
)

export const selectIsTodoListOpen = createSelector(
    selectPathname,
    path => !!matchPath(path, `*${routesApp.todo}`),
)

export const selectIsJourneyOverlay = createSelector(
    selectPathname,
    path => !!matchPath(path, `*${routesApp.playerJourney}`),
)

export const selectIsJourneyEdit = createSelector(
    selectPathname,
    path => !!matchPath(path, `*${routesApp.journeyEdit}`),
)

export const selectIsReporting = createSelector(
    selectPathname,
    path => !!matchPath(path, `*${routesApp.reporting}`),
)

export const selectIsTheming = createSelector(
    selectPathname,
    path => !!matchPath(path, `*${routesApp.theming}`),
)

export const selectIsEditor = createSelector(
    selectPathname,
    path => !!matchPath(path, `*${routesApp.editor}`),
)

export const selectIsIntroduction = createSelector(
    selectPathname,
    path => !!matchPath(path, `*${routesApp.introduction}`),
)
export const reducer = createReducer(reducers, { ...initialState, ...history })
