import first from "lodash/first"
import isArray from "lodash/isArray"
import { logError } from "../logging"

export const reorder = (source, from, to) => {
    source.splice(to, 0, first(source.splice(from, 1)))

    return source
}

export const count = (array, predicate, start = 0) =>
    array.reduce(
        (currentCount, value, index) => currentCount + predicate(value, index),
        start,
    )

export const arrayify = items => (Array.isArray(items) ? items : [items])

export const emptyArray = []

export const pickRandom = values =>
    values[Math.round(Math.random() * (values.length - 1))]

/**
 * @name updateOrder
 * @type function
 * @param {array} array | list of items to reorder
 * @param {number} currentOrder | reordered item current order
 * @param {number} destinationOrder | reordered item destination order
 * @param {string} orderAttr | optional property in case the order attribute isn't called order
 */
export const updateOrder = (
    array,
    currentOrder,
    destinationOrder,
    options = {
        weight: 1,
        innerAttr: undefined,
        orderAttr: "order",
    },
) => {
    if (options?.weight > 1 && !options?.innerAttr) {
        logError(
            "ConfigurationError",
            "UnknownInnerProperty: when declaring a weigh of the reordered item higher than one you must pass the innerAttr property in the options object",
        )
        return array
    }
    const { innerAttr, orderAttr, weight } = options || {}
    const movedUpwards = currentOrder < destinationOrder

    return array.map(current => {
        const { [orderAttr]: order } = current
        let inner

        if (order === currentOrder) {
            if (isArray(current[innerAttr])) {
                inner = current[innerAttr]?.map((currentInner, idx) => ({
                    ...currentInner,
                    [orderAttr]: movedUpwards
                        ? destinationOrder - (weight - (idx + 1))
                        : destinationOrder + idx,
                }))
            } else if (innerAttr && !isArray(current[innerAttr])) {
                logError(
                    "ConfigurationError",
                    `InvalidInnerProperty: the inner property ${innerAttr} must be an array`,
                )
            }

            return {
                ...current,
                [orderAttr]: movedUpwards
                    ? destinationOrder - (weight - 1)
                    : destinationOrder,
                [innerAttr]: inner,
            }
        }

        if (movedUpwards) {
            if (order < currentOrder || order > destinationOrder) return current

            if (current[innerAttr] && isArray(current[innerAttr])) {
                inner = current[innerAttr]?.map(
                    ({ [orderAttr]: currentInnerOrder, ...currentInner }) => ({
                        ...currentInner,
                        [orderAttr]: currentInnerOrder - weight,
                    }),
                )
            } else if (current[innerAttr] && !isArray(current[innerAttr])) {
                logError(
                    "ConfigurationError",
                    `InvalidInnerProperty: the inner property ${innerAttr} must be an array`,
                )
            }

            return {
                ...current,
                [orderAttr]: order - weight,
                [innerAttr]: inner,
            }
        }

        if (order > currentOrder || order < destinationOrder) return current

        if (current[innerAttr] && isArray(current[innerAttr])) {
            inner = current[innerAttr]?.map(
                ({ [orderAttr]: currentInnerOrder, ...currentInner }) => ({
                    ...currentInner,
                    [orderAttr]: currentInnerOrder + weight,
                }),
            )
        } else if (current[innerAttr] && !isArray(current[innerAttr])) {
            logError(
                "ConfigurationError",
                `InvalidInnerProperty: the inner property ${innerAttr} must be an array`,
            )
        }

        return {
            ...current,
            [orderAttr]: order + weight,
            [innerAttr]: inner,
        }
    })
}
