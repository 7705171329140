import get from "lodash/get"
import { createStyles, StyleRules, Theme } from "@material-ui/core"
import { fade } from "@material-ui/core/styles/colorManipulator"

const styles = (theme: Theme): StyleRules =>
    createStyles({
        formControl: {
            width: "100%",
            margin: 0,
        },

        labelContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: "row",
        },

        aboveLabel: {
            "marginBottom": get(theme, "appicalTheme.spacing.gap"),
            "fontWeight": "normal",
            "color": fade(get(theme, "palette.primary.contrastText"), 0.5),

            "& > span": {
                color: theme?.palette?.error?.main,
            },
        },

        textField: {
            "backgroundColor": fade(get(theme, "palette.secondary.main"), 0.05),
            "height": "42px",

            "&.Mui-disabled": {
                backgroundColor: "transparent",
            },
        },

        error: {
            margin: `${get(theme, "appicalTheme.spacing.gap")} 0 0`,
            color: theme?.palette?.error?.main,
        },

        errorContainer: {
            display: "flex",
            alignItems: "center",
        },
    })

export default styles
