import get from "lodash/get"

const styles = theme => ({
    root: {
        display: "flex",
    },

    barContainerMultiBar: {
        flex: "1",
        marginRight: "4px",
    },

    lastBar: {
        flex: "1",
    },

    progressBar: {
        backgroundColor: get(theme, "appicalTheme.black20"),
        borderRadius: "0px",
        height: "4px",
        "-webkit-backface-visibility": "hidden",
        "-moz-backface-visibility": "hidden",
        "-webkit-transform": "translate3d(0, 0, 0)",
        "-moz-transform": "translate3d(0, 0, 0)",
    },

    rounded: {
        overflow: "hidden",
        borderRadius: get(theme, "appicalTheme.sizes.borderRadius"),

        "& > $bar": {
            borderRadius: get(theme, "appicalTheme.sizes.borderRadius"),
        },
    },

    bar: {
        backgroundColor: "transparent",
        transition: "background-color 300ms linear, transform 300ms linear",
    },
})

export default styles
