import get from "lodash/get"

const styles = theme => ({
    root: {
        textAlign: "center",
        maxWidth: "440px",
        margin: "auto",
    },
    title: {
        marginBottom: get(theme, "appicalTheme.spacing.smallest"),
    },
    dog: {
        marginBottom: get(theme, "appicalTheme.spacing.smallest"),
    },
})

export default styles
