import { LOCATION_CHANGE } from "connected-react-router"
import { selectTrails } from "redux/reducers/router/selectors"

// Required variables
export const initialState = {
    /* Holds request actions in the queue until the token is refreshed */
    trails: [],
}

// Reducer
export const reducers = {
    [LOCATION_CHANGE](state, { location, ...rest }) {
        const { pathname } = location
        const trails = selectTrails(state)

        if (trails.length > 10) {
            return {
                ...state,
                location,
                trails: [pathname, ...trails.slice(0, 8)],
                ...rest,
            }
        }

        return trails.length === 10
            ? {
                  ...state,
                  location,
                  trails: [pathname, ...trails.slice(0, 1)],
                  ...rest,
              }
            : {
                  ...state,
                  location,
                  trails: [pathname, ...trails],
                  ...rest,
              }
    },
}
