import React, {
    ElementType,
    FunctionComponent,
    MouseEventHandler,
    useMemo,
} from "react"
import classNames from "classnames"

// @material-ui/core
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import MuiButton from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"

import { convertColorToMui } from "./helpers"

import styles from "./styles"

interface ButtonProps extends WithStyles<typeof styles> {
    component?: ElementType | FunctionComponent
    children?: Node | string | null
    loader?: boolean
    rightIcon?: Element | null
    leftIcon?: Element | null
    disabled?: boolean
    rounded?: boolean
    className?: string
    color?:
        | "default"
        | "inherit"
        | "primary"
        | "secondary"
        | "success"
        | "error"
    variant?: "text" | "outlined" | "contained"
    size?: "smallest" | "small" | "medium" | "large"
    onClick?: MouseEventHandler
    helperText?: string
}

export const Button = React.forwardRef(
    (
        {
            children,
            classes,
            color,
            loader,
            rounded,
            component,
            className,
            helperText,
            rightIcon,
            leftIcon,
            variant,
            size,
            ...other
        }: ButtonProps,
        ref,
    ) => {
        const Component = component || MuiButton
        const endIcon = useMemo(
            () =>
                loader ? (
                    <CircularProgress
                        className={classes.loader}
                        size={17}
                        color="inherit"
                    />
                ) : (
                    rightIcon
                ),
            [loader, rightIcon],
        )

        return (
            <div className={classes.root}>
                <Component
                    ref={ref}
                    {...other}
                    variant={variant}
                    className={classNames(
                        className,
                        color === "success" && classes.success,
                        color === "error" && classes.error,
                        size && classes[size],
                        variant === "text" && classes.textVariant,
                        rounded && classes.rounded,
                    )}
                    classes={{
                        label: classes.label,
                    }}
                    color={convertColorToMui(color)}
                    startIcon={leftIcon}
                    endIcon={endIcon}
                >
                    {children}
                </Component>

                {helperText && (
                    <Typography
                        variant="caption"
                        className={classes.helperText}
                    >
                        {helperText}
                    </Typography>
                )}
            </div>
        )
    },
)

Button.defaultProps = {
    component: MuiButton,
    children: null,
    loader: false,
    rounded: true,
    rightIcon: null,
    leftIcon: null,
    disabled: false,
    className: "",
    color: "default",
    variant: "contained",
    size: "medium",
    onClick: () => {},
    helperText: "",
}

export default React.memo(withStyles(styles, { name: "Button" })(Button))
