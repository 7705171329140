import { journeyStates } from "constants/enums"

import {
    CREATE,
    DELETE,
    DUPLICATE,
    SELECT,
    UPDATE,
    MOVE,
    REPUBLISH,
    PUBLISH,
    UNPUBLISH,
    TALENT,
    EDIT,
} from "redux/utility"
import { ERROR, LOADED, LOADING } from "redux/middleware/actionNames"

import { LOGOUT } from "../../core/authentication/actionNames"
import { COMPANY } from "../companies/actionNames"
import { WORKSPACE } from "../workspaces/actionNames"
import { JOURNEY_STEPS } from "../journeySteps/actionNames"

import { normalizer, journeySchema } from "./schema"
import { selectData, selectIdentifiers } from "./selectors"
import { JOURNEY, JOURNEYS } from "./actionNames"

// Required variables
export const initialState = {
    /* Holds the normalized journeys redux object */
    data: {},
    /* Holds the identifiers for the journeys */
    identifiers: [],
    /* Holds the normalized talent topic identifiers */
    talentIdentifiers: [],
    /* Indicates whether or not journeys on the player side have been fetched */
    hasFetchedTalent: false,
    /* Indicates whether or not journeys have been fetched */
    hasFetched: false,
    /* Are we loading new journeys */
    isLoading: false,
    /* Loading journeys resulted in an error */
    loadingError: null,
    /* holds the journey that is currently being edited */
    selectedHash: null,
}

// Reducer
export const reducers = {
    /* Load journeys overview */
    [JOURNEYS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [JOURNEYS + LOADED](state, { result }) {
        const normalizedData = normalizer(result, [journeySchema])
        const { journeys } = normalizedData.entities
        const identifiers = normalizedData.result

        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            hasFetchedTalent: false,
            loadingError: null,
            data: journeys,
            identifiers,
        }
    },

    [JOURNEYS + ERROR](state, payload) {
        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError: payload.result,
        }
    },

    /* Create a journey */
    [JOURNEY + CREATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },
    [JOURNEY + CREATE + LOADED](state, payload) {
        const {
            result: { hash },
            originalPayload: { workspaceHash, journeyName },
        } = payload
        const data = selectData(state)
        const identifiers = selectIdentifiers(state)

        return {
            ...state,
            isLoading: false,
            data: {
                ...data,
                [hash]: {
                    hash,
                    name: journeyName,
                    state: journeyStates.draft,
                    inUse: false,
                    workSpaces: [workspaceHash],
                    relationDefinitionDependencies: [],
                },
            },
            identifiers: [...identifiers, hash],
            selectedHash: hash,
        }
    },
    [JOURNEY + CREATE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    /* Duplicate a journey */
    [JOURNEY + DUPLICATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [JOURNEY + DUPLICATE + LOADED](state, payload) {
        const {
            result: { hash },
            originalPayload: { newName, journeyHash },
        } = payload
        const data = selectData(state)
        const duplicatedJourney = data[journeyHash]
        const identifiers = selectIdentifiers(state)

        // TODO: implement actual result
        return {
            ...state,
            isLoading: false,
            data: {
                ...data,
                [hash]: {
                    hash,
                    name: newName || `Copy of ${duplicatedJourney.name}`,
                    state: journeyStates.draft,
                    inUse: false,
                    workSpaces: duplicatedJourney.workSpaces,
                },
            },
            identifiers: [...identifiers, hash],
        }
    },

    [JOURNEY + DUPLICATE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    /* Delete a journey */

    [JOURNEY + DELETE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [JOURNEY + DELETE + LOADED](state, payload) {
        const {
            originalPayload: { journeyHash },
        } = payload
        const data = { ...selectData(state) }
        const currentIdentifiers = selectIdentifiers(state)
        const { liveJourneyHash } = data[journeyHash] || {}

        if (liveJourneyHash && data[liveJourneyHash]) {
            const { editJourneyHash, ...liveJourney } = data[liveJourneyHash]
            data[liveJourneyHash] = liveJourney
        }

        delete data[journeyHash]
        const identifiers = currentIdentifiers.filter(
            hash => hash !== journeyHash,
        )

        return {
            ...state,
            isLoading: false,
            data,
            identifiers,
        }
    },

    [JOURNEY + DELETE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    /* Rename a journey */
    [JOURNEY + UPDATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [JOURNEY + UPDATE + LOADED](state, payload) {
        const { name, journeyHash } = payload.originalPayload
        const data = selectData(state)
        const { [journeyHash]: changedJourney } = data

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: {
                ...data,
                [journeyHash]: {
                    ...changedJourney,
                    name,
                },
            },
        }
    },

    [JOURNEY + UPDATE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [JOURNEY + UPDATE](state, { journeyHash, ...journeyUpdatedField }) {
        const data = selectData(state)
        const journey = data[journeyHash]

        return {
            ...state,
            data: {
                ...data,
                [journeyHash]: {
                    ...journey,
                    ...journeyUpdatedField,
                },
            },
        }
    },

    /* Move a journey */
    [JOURNEY + MOVE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [JOURNEY + MOVE + LOADED](state, payload) {
        const { workspaceHash, journeyHash } = payload.originalPayload
        const data = selectData(state)
        const { [journeyHash]: changedJourney } = data

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: {
                ...data,
                [journeyHash]: {
                    ...changedJourney,
                    workSpaces: [workspaceHash],
                },
            },
        }
    },

    [JOURNEY + MOVE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    /* Publish a journey */
    [JOURNEY + PUBLISH + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [JOURNEY + PUBLISH + LOADED](state, payload) {
        const { journeyHash } = payload.originalPayload
        const data = selectData(state)
        const { [journeyHash]: changedJourney } = data
        const { liveJourneyHash, ...finalJourney } = changedJourney
        const updatedData = {
            ...data,
            [journeyHash]: {
                ...finalJourney,
                state: journeyStates.live,
            },
        }

        if (data[liveJourneyHash]) {
            delete updatedData[liveJourneyHash]
        }

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: updatedData,
        }
    },

    [JOURNEY + PUBLISH + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    /* Unpublish a journey */
    [JOURNEY + UNPUBLISH + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [JOURNEY + UNPUBLISH + LOADED](state, payload) {
        const { journeyHash } = payload.originalPayload
        const data = selectData(state)
        const { [journeyHash]: changedJourney } = data

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: {
                ...data,
                [journeyHash]: {
                    ...changedJourney,
                    state: journeyStates.draft,
                },
            },
        }
    },

    [JOURNEY + UNPUBLISH + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [JOURNEY + REPUBLISH + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [JOURNEY + REPUBLISH + LOADED](state) {
        return { ...state, isLoading: false }
    },

    [JOURNEY + REPUBLISH + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [JOURNEY + SELECT](state, { selectedHash }) {
        return { ...state, selectedHash }
    },

    [JOURNEYS + TALENT + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [JOURNEYS + TALENT + LOADED](state, { result }) {
        const normalizedData = normalizer(result, [journeySchema])
        const { journeys } = normalizedData.entities
        const talentIdentifiers = normalizedData.result

        return {
            ...state,
            data: journeys,
            talentIdentifiers,
            hasFetchedTalent: true,
            hasFetched: false,
            isLoading: false,
        }
    },

    [JOURNEYS + TALENT + ERROR](state, { result }) {
        return {
            ...state,
            isLoading: false,
            hasFetchedTalent: true,
            loadingError: result,
        }
    },

    [JOURNEY + EDIT + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [JOURNEY + EDIT + LOADED](state, { result, originalPayload }) {
        const { journeyHash: liveJourneyHash } = originalPayload
        const { hash: editJourneyHash, stepItems, ...journeyData } = result
        const data = selectData(state)
        const identifiers = selectIdentifiers(state)
        const liveJourney = data[liveJourneyHash]

        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            data: {
                ...data,
                [editJourneyHash]: {
                    ...journeyData,
                    hash: editJourneyHash,
                    liveJourneyHash,
                },
                [liveJourneyHash]: {
                    ...liveJourney,
                    editJourneyHash,
                },
            },
            identifiers: [editJourneyHash, ...identifiers],
        }
    },

    [JOURNEY + EDIT + ERROR](state, payload) {
        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError: payload.result,
        }
    },

    // journey steps actions

    [JOURNEY_STEPS + LOADED](state, { result }) {
        const { editJourneyHash, hash: currentJourneyHash } = result || {}
        const data = selectData(state)
        const currentJourney = data[currentJourneyHash]

        return {
            ...state,
            data: {
                ...data,
                [currentJourneyHash]: {
                    ...currentJourney,
                    editJourneyHash,
                },
            },
        }
    },

    // respond to actions related to Workspaces
    [WORKSPACE + DELETE + LOADED](state, payload) {
        const {
            workspaceHash,
            moveToWorkspaceHash: targetHash,
        } = payload.originalPayload
        const journeys = selectData(state)
        const identifiers = selectIdentifiers(state)

        const data = identifiers.reduce((map, hash) => {
            const { [hash]: journey } = journeys

            return journey.workSpaces.includes(workspaceHash)
                ? { ...map, [hash]: { ...journey, workSpaces: [targetHash] } }
                : { ...map, [hash]: journey }
        }, {})

        return { ...state, data }
    },

    /* Reset scenario's */
    [COMPANY + SELECT]() {
        return initialState
    },

    [LOGOUT + LOADED]() {
        return initialState
    },
}
