export const uri = {
    journeys: 1,
    users: 2,
    topic: 3,
    story: 3,
    introduction: 3,

    journeysedit: 4,
    contentedit: 5,

    // Journey Edit
    journey: 6,
    reporting: 7,

    glossary: 3,

    dashboard: 8,
    topics: 9,

    settings: 10,
}

export const screenToNameMap = {
    journeys: "journeys",
    users: "members",
    topic: "create",
    story: "create",
    glossary: "create",
    introduction: "create",
    settings: "settings",
    theming: "design",
}

export const screens = {
    settings: "settings",
    journeys: "journeys",
    members: "users",
    topics: "topic",
    stories: "story",
    billing: "billing",
    glossary: "glossary",
    company: "company",
    profile: "profile",
    introduction: "introduction",
}

export const registerForm = {
    user: "user",
    region: "choose-region",
    email: "email-sent",
    verifyEmail: "verify-email",
    validate: "validate",
    company: "company",
    choosePlan: "choose-plan",
    payment: "payment",
    billing: "billing",
    completed: "completed",
}

export const settings = {
    "company": 1,
    "billing": 2,
    "profile": 3,
    "theming": 4,
    "choose-plan": 5,
    "payment": 2,
}

export const theming = {
    company: 1,
    login: 2,
}

export const mainSections = {
    editor: "editor",
    player: "dashboard",
    preview: "preview",
}

export default uri
