import { connect } from "react-redux"

import {
    selectIsReady,
    selectIsSubdomain,
    selectLoadingError,
} from "redux/reducers/core/environment"
import { createSelectLoadEnvironmentAction as activateEnvironment } from "redux/reducers/core/environment/actions"

const mapStateToProps = state => ({
    isReady: selectIsReady(state),
    isSubdomain: selectIsSubdomain(state),
    hasLoadError: !!selectLoadingError(state),
})

const mapDispatchToProps = {
    activateEnvironment: name => activateEnvironment(name, true),
}

export default connect(mapStateToProps, mapDispatchToProps)
