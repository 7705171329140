import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { compose } from "redux"
import { withTranslation } from "react-i18next"

import withStyles from "@material-ui/core/styles/withStyles"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"

import { MdClose as Close } from "react-icons/md"
import toastTypes from "constants/toastTypes"

import styles from "./styles"

const defaultTitle = type =>
    ({
        [toastTypes.error]: "main:error",
        [toastTypes.warning]: "main:warning",
        [toastTypes.success]: "main:success",
        [toastTypes.info]: "main:info",
    }[type])

const className = (classes, type) =>
    classNames(classes.root, {
        [classes.error]: type === toastTypes.error,
        [classes.warning]: type === toastTypes.warning,
        [classes.success]: type === toastTypes.success,
        [classes.info]: type === toastTypes.info,
    })

const Toast = ({
    t,
    classes,
    title,
    message,
    type,
    interpolations,
    onClose,
    canClose,
}) => (
    <Paper className={className(classes, type)}>
        <div className={classes.content}>
            <Typography className={classes.title} variant="h6">
                {t(title) || t(defaultTitle(type))}
            </Typography>
            <Typography variant="body1" className={classes.message}>
                {t(message, interpolations)}
            </Typography>
        </div>

        {canClose && (
            <IconButton onClick={onClose} className={classes.closeButton}>
                <Close />
            </IconButton>
        )}
    </Paper>
)

Toast.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.exact({
        root: PropTypes.string,
        content: PropTypes.string,
        title: PropTypes.string,
        message: PropTypes.string,
        closeButton: PropTypes.string,
        error: PropTypes.string,
        warning: PropTypes.string,
        success: PropTypes.string,
        info: PropTypes.string,
        base: PropTypes.string,
    }).isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.number,
    interpolations: PropTypes.object,
    canClose: PropTypes.bool,
    onClose: PropTypes.func,
}

Toast.defaultProps = {
    title: null,
    message: "main:generic_error",
    type: toastTypes.warning,
    interpolations: {},
    canClose: false,
    onClose: () => null,
}

export default compose(
    withStyles(styles, { name: "Toast" }),
    withTranslation(),
    React.memo,
)(Toast)
