import React from "react"
import ReactDOM from "react-dom"
import ReactQuill from "react-quill"

import { MdClose as CloseIcon } from "react-icons/md"

import Chip from "lib/views/Chip"

const Embed = ReactQuill.Quill.import("blots/embed")

class TagBlot extends Embed {
    static create(value) {
        const node = super.create()

        node.setAttribute("data-key", `${value.key}`)
        node.setAttribute("data-name", value.value)
        node.setAttribute("contentEditable", false)

        ReactDOM.render(
            <Chip
                clickable={false}
                component="span"
                classes={{
                    chip: "ql-chip",
                    label: "ql-chipLabel",
                    deleteIcon: "ql-chipDeleteIcon",
                }}
                label={value.value}
                deleteIcon={<CloseIcon />}
                onDelete={() => {
                    node.remove()
                }}
            />,
            node,
        )

        return node
    }

    static value(domNode) {
        return {
            key: domNode.getAttribute("data-key"),
            value: domNode.getAttribute("data-name"),
        }
    }
}

TagBlot.blotName = "chip"
TagBlot.className = "ql-tagBlot"
TagBlot.tagName = "span"

export default TagBlot