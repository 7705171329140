import { createSelector } from "reselect"
import filter from "lodash/filter"
import find from "lodash/find"
import orderBy from "lodash/orderBy"
import groupBy from "lodash/groupBy"
import keys from "lodash/keys"
import some from "lodash/some"
import flatMap from "lodash/flatMap"

import { userRoles } from "constants/enums"
import { APPICAL_PARTNER_COMPANY_HASH, userType } from "constants/internal"

import hasUserTypeByRoles from "lib/utils/helpers/hasUserTypeByRoles"
import { createReducer } from "redux/utility"

import {
    selectCompanies,
    selectCurrentCompany,
} from "redux/reducers/entities/companies"
import { hasActiveRoles } from "redux/reducers/entities/companies/helpers"
import {
    selectRelations,
    selectData as selectRelationsData,
} from "redux/reducers/entities/relations"
import {
    selectSelectedHash as selectActiveJourneyHash,
    selectCurrentJourney,
} from "redux/reducers/entities/journeys"
import { createEntitiesFeatureSelector } from "../helpers"
import * as selectors from "./selectors"
import { reducers, initialState } from "./reducers"
import { reducerName } from "./actionNames"
import { mapToStripeRequestDetails } from "./helpers"

export * from "./reducers"
export * from "./schema"

export const selectFeature = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)

export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)

export const selectLoggedInHash = createSelector(
    selectFeature,
    selectors.selectLoggedInHash,
)

export const selectSelectedHash = createSelector(
    selectFeature,
    selectors.selectSelectedHash,
)

export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)

export const selectHasFetched = createSelector(
    selectFeature,
    selectors.selectHasFetched,
)
export const selectHasFetchedUser = createSelector(
    selectFeature,
    selectors.selectHasFetchedUser,
)
export const selectHasFetchedJourneyUsers = createSelector(
    selectFeature,
    selectors.selectHasFetchedJourneyUsers,
)

export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)

export const selectUsers = createSelector(selectFeature, selectors.selectUsers)

export const selectLoggedInUser = createSelector(
    selectFeature,
    selectors.selectLoggedInUser,
)
export const selectUserByEmail = createSelector(
    selectUsers,
    (_, emailAddress) => emailAddress,
    (users, emailAddress) => find(users, { emailAddress }),
)
export const selectUserFirstName = createSelector(
    selectFeature,
    selectors.selectUserFirstName,
)
export const selectLoggedInUserFullName = createSelector(
    selectFeature,
    selectors.selectLoggedInUserFullName,
)
export const selectSelectedUser = createSelector(
    selectFeature,
    selectors.selectSelectedUser,
)
export const selectCurrentUserRelations = createSelector(
    selectFeature,
    selectors.selectCurrentUserRelations,
)

export const selectAllInversedRelations = createSelector(
    selectFeature,
    selectors.selectCurrentUserRelations,
)

export const selectCurrentInversedRelations = createSelector(
    selectFeature,
    selectors.selectCurrentInversedRelations,
)
export const selectCurrentUserRelationsAsFormValues = createSelector(
    selectCurrentUserRelations,
    selectData,
    selectRelations,
    (userRelations, data, relations) => {
        const mappedUserRelations = []

        for (let idx = 0; idx < relations?.length; idx += 1) {
            const { hash: relationDefinitionHash, relationName } = relations[
                idx
            ]
            const userRelation = find(userRelations, { relationDefinitionHash })
            const relationUserHash = userRelation?.userHash

            userRelation
                ? mappedUserRelations.push({
                      relationDefinitionHash,
                      relationName,
                      userHash: data[relationUserHash] ? relationUserHash : "",
                      userFullName: data[relationUserHash]
                          ? `${data[relationUserHash]?.firstName} ${data[relationUserHash]?.lastName}`
                          : "",
                      userFullNameWithEmail: data[relationUserHash]
                          ? `${data[relationUserHash]?.firstName} ${data[relationUserHash]?.lastName}, ${data[relationUserHash]?.emailAddress}`
                          : "",
                  })
                : mappedUserRelations.push({
                      relationDefinitionHash,
                      relationName,
                      userHash: "",
                      userFullNameWithEmail: "",
                      userFullName: "",
                  })
        }

        return mappedUserRelations
    },
)

export const selectCurrentUserInversedRelations = createSelector(
    selectFeature,
    selectors.selectCurrentUserInversedRelations,
)

export const selectUsersWithRelations = createSelector(selectUsers, users =>
    users.filter(user => user.userRelations),
)

export const selectCurrentUserInversedRelationsCombinedByUser = createSelector(
    selectCurrentInversedRelations,
    selectRelationsData,
    selectData,
    (inversedRelations, relationsData, usersData) => {
        const usersRelationsGrouppedByUser = groupBy(
            inversedRelations,
            "userHash",
        )
        const modifiedUsersHashes = keys(usersRelationsGrouppedByUser)
        const uniqueUsersRelations = [
            ...new Set(
                inversedRelations?.map(user => user.relationDefinitionHash),
            ),
        ]
        const relations = []

        for (let idx = 0; idx < modifiedUsersHashes?.length; idx += 1) {
            const targetUserHash = modifiedUsersHashes[idx]
            const deletedUserRelations =
                usersRelationsGrouppedByUser[targetUserHash]
            const userRelations = uniqueUsersRelations?.map(
                relationDefinitionHash => {
                    const exists = find(deletedUserRelations, {
                        relationDefinitionHash,
                    })

                    return {
                        relationDefinitionHash,
                        relationName:
                            relationsData?.[relationDefinitionHash]
                                ?.relationName,
                        targetUserHash,
                        disabled: !exists,
                    }
                },
            )

            relations.push({
                userHash: targetUserHash,
                firstName: usersData?.[targetUserHash]?.firstName,
                lastName: usersData?.[targetUserHash]?.lastName,
                userRelations: orderBy(userRelations, "relationName"),
            })
        }
        return relations
    },
)

export const selectActiveJourneyUsers = createSelector(
    selectActiveJourneyHash,
    selectUsers,
    (journey, users) =>
        users.filter(user => user?.journeyHashes?.includes(journey)),
)
export const selectTalentWithMissingRelations = createSelector(
    selectCurrentJourney,
    selectActiveJourneyUsers,
    selectRelationsData,
    (journey, users, relations) => {
        const usersWithoutRelations = []
        // need to calculate how many times each relation used in order to create dynamic columns
        const assignedRelationsCounter = users.reduce((acc, user) => {
            user.userRelations.forEach(rel => {
                acc[rel.relationDefinitionHash]
                    ? (acc[rel.relationDefinitionHash] += 1)
                    : (acc[rel.relationDefinitionHash] = 1)
            })
            return acc
        }, {})

        const missingJourneyRelations =
            journey?.relationDefinitionDependencies?.filter(
                rel => assignedRelationsCounter[rel] !== users.length,
            ) || []

        for (let i = 0; i < users?.length; i += 1) {
            const userRelations = []
            for (let idx = 0; idx < missingJourneyRelations.length; idx += 1) {
                const exists = !!users[i].userRelations?.find(
                    relation =>
                        relation.relationDefinitionHash ===
                        missingJourneyRelations[idx],
                )

                if (!exists) {
                    userRelations.push({
                        relationDefinitionHash: missingJourneyRelations[idx],
                        relationName:
                            relations?.[missingJourneyRelations[idx]]
                                ?.relationName,
                        disabled: false,
                    })
                } else {
                    userRelations.push({
                        ...users[i].userRelations[idx],
                        relationName:
                            relations?.[missingJourneyRelations[idx]]
                                ?.relationName,
                        disabled: true,
                    })
                }
            }

            if (!userRelations.every(relation => relation.disabled)) {
                usersWithoutRelations.push({
                    ...users[i],
                    userRelations: orderBy(userRelations, "relationName"),
                })
            }
        }
        return usersWithoutRelations
    },
)
export const selectTalents = createSelector(selectUsers, users =>
    filter(users, ({ roles }) => roles.includes(userRoles.talent)),
)

export const selectStaff = createSelector(selectUsers, users =>
    users?.filter(({ roles }) =>
        roles?.some(role => role !== userRoles.talent),
    ),
)

export const selectHasMultipleCompanies = createSelector(
    selectCompanies,
    selectLoggedInUser,
    (companies, user) => companies?.length > 1 || user?.roles?.length > 1,
)

export const selectStripeRequestDetails = createSelector(
    selectLoggedInUser,
    selectSelectedHash,
    mapToStripeRequestDetails,
)

export const selectIsAppicalPartnerUser = createSelector(
    selectCompanies,
    companies => some(companies, { hash: APPICAL_PARTNER_COMPANY_HASH })
)

export const selectLoggedInRoles = createSelector(selectCompanies, companies =>
    flatMap(companies, "roles"),
)

export const selectLoggedInUserRolesInCurrentCompany = createSelector(
    selectCurrentCompany,
    currentCompany => currentCompany?.roles || [],
)

export const selectHasMultipleUserTypes = createSelector(
    selectLoggedInRoles,
    roles => hasUserTypeByRoles(roles, [userType.talent, userType.staff]),
)

export const selectHasMultipleUserTypesInCurrentCompany = createSelector(
    selectLoggedInUserRolesInCurrentCompany,
    roles => hasUserTypeByRoles(roles, [userType.talent, userType.staff]),
)

export const selectHasActiveRoles = createSelector(selectCompanies, companies =>
    some(companies, hasActiveRoles),
)

export const selectHasLoggedInUserAgreedToAllPolicies = createSelector(
    selectLoggedInUser,
    user => user?.agreesToAllPolicies ?? true,
)

export const selectUsersOrderedByName = createSelector(selectUsers, users =>
    orderBy(users, "firstName"),
)

export const selectStaffOrderedByName = createSelector(selectStaff, users =>
    orderBy(users, "firstName"),
)

export const selectUserByParamHash = createSelector(
    selectData,
    (
        _,
        {
            match: {
                params: { userHash },
            },
        },
    ) => userHash,
    (users, hash) => users[hash],
)
// Reducer
export const reducer = createReducer(reducers, initialState)
