import { schema, normalize } from "normalizr"

import { journeySchema } from "../journeys/schema"

// Define your comments schema
export const workspaceSchema = new schema.Entity(
    "workspaces",
    {
        journeys: [journeySchema],
    },
    { idAttribute: "hash" },
)

export const normalizer = (originalData, entitySchema) =>
    normalize(originalData, entitySchema)
