import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import memoize from "memoize-one"
import classNames from "classnames"

import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import { validations } from "../../../../constants/enums"

import PhasedProgressBar from "../../PhasedProgressBar"
import ValidationCheckBoxes from "./ValidationCheckBoxes"

const upperCaseRegex = /[A-Z]/
const lowercaseRegex = /[a-z]/
const digitRegex = /[0-9]/
const alphanumericRegex = /[^0-9a-zA-Z\d\s:]/
const hasTwoLettersRegex = /([a-z]{2})|([a-z].*[a-z])/i
const hasTwoNumbersRegex = /(\d{2})|(\d.*\d)/i
const hasTwoAlphanumericRegex = /([^0-9a-zA-Z\d\s:]{2}})|([^0-9a-zA-Z\d\s:].*[^0-9a-zA-Z\d\s:])/
const hasThreeLettersRegex = /([a-z]{3})|([a-z].*[a-z]{2})|([a-z]{2}.*[a-z])|([a-z].*[a-z].*[a-z])/i
const hasThreeNumbersRegex = /(\d{3})|(\d.*\d{2})|(\d{2}.*\d)|(\d.*\d.*\d)/

const createBarPhases = (strong, classes) =>
    strong
        ? [
              classes.first,
              classes.second,
              classes.third,
              classes.fourth,
              classes.last,
          ]
        : [classes.first, classes.third, classes.last, classes.last]

class StrengthBar extends React.PureComponent {
    barPhases = memoize(createBarPhases)

    passwordBarTitle

    textColorClass

    calculateStrength = (password, t, classes) => {
        let strength = 0

        if (!password) {
            this.passwordBarTitle = ""
            return strength
        }

        if (password.length > 0) strength += 1

        if (
            upperCaseRegex.test(password) &&
            lowercaseRegex.test(password) &&
            digitRegex.test(password) &&
            alphanumericRegex.test(password) &&
            password.length >= 8
        ) {
            strength += 1

            if (
                password.length >= 9 &&
                (hasTwoNumbersRegex.test(password) ||
                    hasTwoLettersRegex.test(password) ||
                    hasTwoAlphanumericRegex.test(password))
            ) {
                strength += 1

                if (
                    password.length >= 9 &&
                    hasThreeLettersRegex.test(password) &&
                    hasThreeNumbersRegex.test(password)
                ) {
                    strength += 1
                }
            }
        }

        switch (strength) {
            case 2:
                this.passwordBarTitle = t("main:password_fair")
                this.textColorClass = classes.thirdText
                break
            case 3:
                this.passwordBarTitle = t("main:password_good")
                this.textColorClass = classes.lastText
                break
            case 4:
                this.passwordBarTitle = t("main:password_strong")
                this.textColorClass = classes.lastText
                break
            default:
                this.passwordBarTitle = ""
                break
        }

        return strength
    }

    render() {
        const {
            t,
            classes,
            strongPassword,
            current,
            error,
            touched,
            isFocused,
        } = this.props
        const barPhases = this.barPhases(strongPassword, classes)

        const checks = {
            [validations.lengthCorrect]: current && current.length >= 8,
            [validations.upperAndLowerCase]:
                upperCaseRegex.test(current) && lowercaseRegex.test(current),
            [validations.oneNumber]: digitRegex.test(current),
            [validations.oneSpecialChar]: alphanumericRegex.test(current),
        }

        return (
            <Grid
                container
                justify="flex-start"
                className={classes.progressBarWrapper}
            >
                <Grid item xs={12}>
                    <PhasedProgressBar
                        rounded
                        phases={barPhases}
                        current={this.calculateStrength(current, t, classes)}
                    />
                </Grid>
                {(isFocused || (error && touched)) && (
                    <Fragment>
                        <Grid item xs={12}>
                            <Typography
                                className={classNames(
                                    classes.strengthLabel,
                                    this.textColorClass,
                                )}
                                variant="button"
                            >
                                {this.passwordBarTitle}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.checkboxes}>
                            <ValidationCheckBoxes
                                error={touched && error}
                                checks={checks}
                            />
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        )
    }
}

StrengthBar.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    strongPassword: PropTypes.bool,
    current: PropTypes.string,
    isFocused: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    touched: PropTypes.bool,
}

StrengthBar.defaultProps = {
    strongPassword: false,
    current: null,
    isFocused: false,
    error: null,
    touched: false,
}

export default withTranslation()(StrengthBar)
