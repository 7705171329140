import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { Provider } from "react-redux"
import persistStore from "redux-persist/lib/persistStore"
import { I18nextProvider } from "react-i18next"
import { ConnectedRouter } from "connected-react-router"
import smoothscroll from "smoothscroll-polyfill"

import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import useDynamicImport from "lib/utils/hooks/useDynamicImport"

import Loading from "lib/views/Loading/Loading"
import EnvironmentGate from "EnvironmentGate"
import EnvironmentThemeProvider from "ThemeProvider/EnvironmentThemeProvider"

import { history } from "./redux/reducers/router"

// kick off the polyfill!
smoothscroll.polyfill()

const AppProviderWrapper = ({ children }) => {
    const [store, loadedStore] = useDynamicImport(
        useCallback(
            () => import(/* webpackChunkName: "store" */ "redux/store"),
            [],
        ),
    )
    const [i18n, loadedI18n] = useDynamicImport(
        useCallback(
            () => import(/* webpackChunkName: "i18n" */ "configure/i18n"),
            [],
        ),
    )

    if (!loadedStore || !loadedI18n) {
        return <Loading />
    }

    const persistor = persistStore(store)

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <EnvironmentThemeProvider>
                    <I18nextProvider i18n={i18n}>
                        <EnvironmentGate persistor={persistor}>
                            <DndProvider backend={HTML5Backend}>
                                {children}
                            </DndProvider>
                        </EnvironmentGate>
                    </I18nextProvider>
                </EnvironmentThemeProvider>
            </ConnectedRouter>
        </Provider>
    )
}

AppProviderWrapper.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AppProviderWrapper
