import { Theme, createStyles, StyleRules } from "@material-ui/core/styles"
import { fade } from "@material-ui/core/styles/colorManipulator"

const styles = (theme: Theme): StyleRules =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            marginBottom: theme.spacing(3),

            [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
            },
        },

        contentContainer: {
            display: "flex",
            justifyContent: "center",
            alignContent: "flex-start",
            flexDirection: "column",
        },

        aboveTitle: {
            color: fade(theme?.palette?.primary?.contrastText, 0.5),
            textAlign: "center",

            [theme.breakpoints.up("sm")]: {
                textAlign: "left",
            },
        },

        title: {
            marginBottom: theme.spacing(),
            textAlign: "center",

            [theme.breakpoints.up("sm")]: {
                textAlign: "left",
            },
        },

        companyName: {
            color: theme?.palette?.primary?.contrastText,
        },

        subtitle: {
            color: theme?.palette?.primary?.contrastText,
            textAlign: "center",
            display: "none",

            [theme.breakpoints.up("sm")]: {
                display: "inline",
                textAlign: "left",
            },
        },

        logoContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: theme?.spacing(2),
            color: theme?.palette?.secondary?.main,

            [theme.breakpoints.up("sm")]: {
                marginBottom: 0,
                marginRight: theme.spacing(3),
            },
        },

        logo: {
            maxHeight: "120px",
            maxWidth: "100%",
        },

        link: {
            "color": theme?.palette?.secondary?.main,
            "fontWeight": 600,
            "textDecoration": "none",
            "marginLeft": theme.spacing(0.5),
            "textAlign": "center",

            "&:active": {
                color: theme?.palette?.secondary?.main,
            },
            "&:hover": {
                color: fade(theme?.palette?.secondary?.main, 0.7),
            },

            [theme.breakpoints.up("sm")]: {
                textAlign: "left",
            },
        },
    })

export default styles
