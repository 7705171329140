import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { compose } from "redux"

// @material-ui/core
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "../Button/Button"

// icons
import Gear from "../Icons/Gear"

import styles from "./styles"

// eslint-disable-next-line no-undef
const errorAction = () => window.location.replace("/")

const ErrorScreen = ({ classes, preMessage, message, reason, action, t }) => (
    <div className={classes.root}>
        <div className={classes.content}>
            <Typography variant="h1" className={classes.title} gutterBottom>
                {t("main:error_title")}
            </Typography>
            <Typography
                variant="body1"
                className={classes.subheading}
                gutterBottom
            >
                {t(preMessage, { message: t(message) })}
            </Typography>
            {reason && (
                <Typography
                    variant="body1"
                    className={classes.subheading}
                    gutterBottom
                >
                    {reason}
                </Typography>
            )}
        </div>

        {action || (
            <Button variant="contained" color="error" onClick={errorAction}>
                {t("main:home")}
            </Button>
        )}

        <Gear variant={1} className={classes.gear1} />
        <Gear variant={2} className={classes.gear2} />
        <Gear variant={3} className={classes.gear3} />
        <Gear variant={4} className={classes.gear4} />
        <Gear variant={5} className={classes.gear5} />
    </div>
)

ErrorScreen.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    preMessage: PropTypes.string,
    message: PropTypes.string,
    reason: PropTypes.string,
    action: PropTypes.node,
}

ErrorScreen.defaultProps = {
    preMessage: "main:system_error_pre_message",
    message: "main:system_error_message",
    reason: "",
    action: null,
}

export default compose(
    withStyles(styles, { withTheme: true, name: "ErrorScreen" }),
    withTranslation(),
    React.memo,
)(ErrorScreen)
