import React from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import classNames from "classnames"

// @material-ui/core
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import Dog from "../Dog"

import styles from "./styles"

const EmptyState = ({
    classes,
    headerMessage,
    message,
    children,
    icon,
    iconWidth,
    iconHeight,
    subheaderClassName,
    className,
}) => (
    <div className={classNames(className, classes.root)}>
        {icon || (
            <Dog
                className={classes.dog}
                width={iconWidth}
                height={iconHeight}
            />
        )}

        <Typography variant="h4" className={classes.title}>
            {headerMessage}
        </Typography>

        {message && (
            <Typography
                variant="body1"
                paragraph
                className={subheaderClassName}
            >
                {message}
            </Typography>
        )}

        {children}
    </div>
)

EmptyState.propTypes = {
    classes: PropTypes.object.isRequired,
    headerMessage: PropTypes.string.isRequired,
    message: PropTypes.string,
    subheaderClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.node,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
}

EmptyState.defaultProps = {
    children: null,
    icon: null,
    message: "",
    iconWidth: 138,
    iconHeight: 128,
    subheaderClassName: "",
    className: "",
}

export default compose(
    withStyles(styles, { name: "EmptyState" }),
    React.memo,
)(EmptyState)
