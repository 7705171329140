import { createSelector, ParametricSelector } from "reselect"
import get from "lodash/get"
import filter from "lodash/filter"
import some from "lodash/some"

import { userType } from "constants/internal"
import { inviteStatus, userJourneyStatus } from "constants/enums"
import { outerJoin } from "lib/utils/helpers/data-sets"
import { createReducer } from "redux/utility"

import { CombinedState } from "redux/reducers/types"

import { createEntitiesFeatureSelector } from "redux/reducers/entities/helpers"
import { selectPublishedJourneys } from "redux/reducers/entities/journeys"

import { selectSelectedUser } from "redux/reducers/entities/users"

import { Invite, InviteState, InviteGroupedByType } from "./types"

import * as selectors from "./selectors"
import { initialState, reducers } from "./reducers"
import { reducerName } from "./actionNames"
import { hasAnotherJourneyInvite } from "./helpers"

export * from "./reducers"
export * from "./schema"

// selectors
const selectFeature: ParametricSelector<
    CombinedState,
    unknown,
    InviteState
> = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)
export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)
export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)
export const selectHasFetched = createSelector(
    selectFeature,
    selectors.selectHasFetched,
)
export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)

export const selectSelectedHash = createSelector(
    selectFeature,
    selectors.selectSelectedHash,
)

export const selectInvites = createSelector(
    selectIdentifiers,
    selectData,
    (identifiers, data): Invite[] => outerJoin(identifiers, data),
)
export const selectSelectedInvite = createSelector(
    selectData,
    selectSelectedHash,
    (data, selectedHash): Invite => data[selectedHash],
)

export const selectUserInvites = createSelector(
    selectData,
    selectSelectedUser,
    (data, user): Invite[] => (user ? outerJoin(user.inviteHashes, data) : []),
)

export const selectGroupedTypeInvites = createSelector(
    selectUserInvites,
    (invites): InviteGroupedByType => {
        const invitesGroupedByType: InviteGroupedByType = {
            [userType.talent]: [],
            [userType.staff]: [],
        }

        for (let idx = 0; idx < invites?.length; idx += 1) {
            const invite = invites[idx]

            invite.type === userType.talent
                ? invitesGroupedByType[userType.talent].push(invite)
                : invitesGroupedByType[userType.staff].push(invite)
        }

        return invitesGroupedByType
    },
)

export const selectUserTalentInvites = createSelector(
    selectGroupedTypeInvites,
    groups =>
        filter(
            get(groups, userType.talent, []),
            ({ journeyHash, userJourneyState, inviteDate }) =>
                !some(
                    get(groups, userType.talent, []),
                    hasAnotherJourneyInvite(
                        journeyHash,
                        userJourneyState,
                        inviteDate,
                    ),
                ),
        ),
)

export const selectUserStaffInvites = createSelector(
    selectGroupedTypeInvites,
    groups => get(groups, userType.staff, []),
)

export const selectUninvitedUserJourneys = createSelector(
    selectPublishedJourneys,
    selectUserTalentInvites,
    (journeys, invites) =>
        filter(
            journeys,
            ({ hash }) =>
                !some(
                    invites,
                    ({ journeyHash, userJourneyState, inviteState }) =>
                        hash === journeyHash &&
                        (inviteState === inviteStatus.accepted ||
                            inviteState === inviteStatus.pending) &&
                        (userJourneyState === userJourneyStatus.active ||
                            userJourneyState === userJourneyStatus.draft),
                ),
        ),
)

export const reducer = createReducer(reducers, initialState)
