import exceptions from "constants/exceptions"

/**
 *
 * @param {object} error | ResponseError type
 * @returns {object} strings
 */
export const getErrorStrings = error => {
    switch (error?.Reason) {
        case exceptions.acceptedInvite:
            return {
                header: "main:invite_accepted_header",
                message: "main:invite_accepted_message",
            }
        case exceptions.expiredInvite:
            return {
                header: "main:invite_expire_header",
                message: "main:invite_expire_message",
            }
        case exceptions.revokedInvite:
            return {
                header: "main:invite_revoked_header",
                message: "main:invite_revoked_message",
            }
        case exceptions.archivedInvite:
            return {
                header: "main:invite_archived_header",
                message: "main:invite_archived_message",
            }
        case exceptions.declinedInvite:
            return {
                header: "main:invite_declined_header",
                message: "main:invite_declined_message",
            }
        default:
            return {
                header: "main:invite_general_error_header",
                message: "main:invite_general_error_message",
            }
    }
}
