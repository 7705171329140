import { logError } from "lib/utils/helpers/logging"
import externalKeys from "constants/externalKeys"

import {
    createWebSocketConnectedAction,
    createWebSocketDisconnectedAction,
} from "redux/middleware/actions"

import { selectAuthToken } from "redux/reducers/core/authentication"

export const onOpen = store => event =>
    store.dispatch(createWebSocketConnectedAction(event?.target?.url))

export const onClose = store => () =>
    store.dispatch(createWebSocketDisconnectedAction())

export const onMessage = (store, typeMessageToActionMap) => event => {
    try {
        const { type, ...payload } = JSON.parse(event.data) || {}

        if (type) {
            store.dispatch({
                type: typeMessageToActionMap[type] || type,
                payload,
            })
        }
    } catch (err) {
        logError(err)
    }
}

export const addTokenToRequest = (state, request) => {
    const authToken = selectAuthToken(state)
    const preppedRequest = { ...request }

    if (authToken) {
        preppedRequest.headers = {
            ...preppedRequest.headers,
            Authorization: `Bearer ${authToken}`,
        }
    }

    if (process.env.REACT_APP_DIAGNOSTICS === "TRUE") {
        preppedRequest.headers = {
            ...preppedRequest.headers,
            "x-diagnostics": externalKeys.diagnosticsAPIKey,
        }
    }

    if (!request.external) {
        preppedRequest.headers = {
            ...preppedRequest.headers,
            "X-Client-Type": "web",
            "X-Client-Version": process.env.REACT_APP_VERSION,
        }
    }

    return preppedRequest
}
