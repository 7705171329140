import { connect } from "react-redux"

import { selectToasts } from "redux/reducers/entities/toasts"
import { createDismissAction as dismiss } from "redux/reducers/entities/toasts/actions"

const mapDispatchToProps = {
    dismiss,
}

const mapStateToProps = state => ({
    toasts: selectToasts(state),
})

export default connect(mapStateToProps, mapDispatchToProps)
