import get from "lodash/get"
import { createSelector } from "reselect"
import { getFormValues } from "redux-form"

import { createReducer } from "../../../utility"
import { createEntitiesFeatureSelector } from "../helpers"

import * as selectors from "./selectors"
import { initialState, reducers } from "./reducers"
import { reducerName } from "./actionNames"

export * from "./reducers"
export * from "./schema"

// selectors
export const selectFeature = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)
export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)

export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)
export const selectHasFetched = createSelector(
    selectFeature,
    selectors.selectHasFetched,
)
export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)
export const selectSelectedHash = createSelector(
    selectFeature,
    selectors.selectSelectedHash,
)

export const selectThemes = createSelector(
    selectFeature,
    selectors.selectThemes,
)

export const selectActiveTheme = createSelector(
    selectFeature,
    selectors.selectActiveTheme,
)
export const selectSelectedTheme = createSelector(
    selectData,
    selectSelectedHash,
    get,
)

export const selectSelectedThemeImageUrlAndName = createSelector(
    selectSelectedTheme,
    theme => ({
        themeName: get(theme, "name"),
        imageUrl: get(theme, "imageUrl"),
    }),
)

export const selectEditedThemeForm = createSelector(
    state => state,
    selectSelectedHash,
    (state, selectedHash) => getFormValues(selectedHash)(state),
)
export const selectCurrentlyEditedTheme = createSelector(
    selectSelectedTheme,
    selectEditedThemeForm,
    (selectedTheme, currentlyEditedThemeColors) => ({
        ...selectedTheme,
        ...currentlyEditedThemeColors,
    }),
)

export const reducer = createReducer(reducers, initialState)
