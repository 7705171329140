import React from "react"
import PropTypes from "prop-types"

import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"

import {
    MdVisibilityOff as VisibilityOff,
    MdVisibility as Visibility,
    MdClose as Close,
} from "react-icons/md"

import Tick from "../../Icons/Tick"

const FieldAdornment = ({
    classes,
    onClick,
    meta: { touched, valid, active },
    hasVisibility,
    passwordVisible,
    disableValidationAdornment,
}) => (
    <InputAdornment position="end" className={classes.adornment}>
        {hasVisibility && (
            <IconButton
                className={classes.visibilityButton}
                tabIndex={-1}
                onClick={onClick}
            >
                {passwordVisible ? (
                    <VisibilityOff className={classes.iconVisibility} />
                ) : (
                    <Visibility className={classes.iconVisibility} />
                )}
            </IconButton>
        )}

        {touched &&
            !active &&
            !disableValidationAdornment &&
            (valid ? (
                <Tick className={classes.iconValid} />
            ) : (
                <Close className={classes.iconInvalid} />
            ))}
    </InputAdornment>
)

FieldAdornment.propTypes = {
    classes: PropTypes.object.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        valid: PropTypes.bool,
        active: PropTypes.bool,
    }).isRequired,
    onClick: PropTypes.func,
    hasVisibility: PropTypes.bool,
    passwordVisible: PropTypes.bool,
    disableValidationAdornment: PropTypes.bool,
}
FieldAdornment.defaultProps = {
    onClick: () => {},
    hasVisibility: false,
    passwordVisible: false,
    disableValidationAdornment: false,
}

export default React.memo(FieldAdornment)
