import React, { useState, useEffect } from "react"
import get from "lodash/get"
import isFunction from "lodash/isFunction"
import * as Sentry from "@sentry/browser"

import { isDevelopment } from "constants/internal"
import Loading from "lib/views/Loading/Loading"

/**
 * @function useDynamicImport
 * @param {func}   loader  | function used to import the module (remember to memoize it)
 * @param {string} segment | the segment of the module that needs to be imported
 * @param {bool}   start   | whether or not the import should start
 */
const useDynamicImport = (
    loader,
    segment = "default",
    start = true,
    LoadingComponent = Loading,
) => {
    if (!loader || !isFunction(loader)) {
        // Type error for the useDynamicImport first parameter
        throw Error(
            "useDynamicImport requires a function returning a Promise as first parameter.",
        )
    }

    const [done, setDone] = useState(false)
    const [restart, setRestart] = useState(false)
    const [DynamicModule, setDynamicModule] = useState(null)
    const [error, setError] = useState(null)
    const handleRetry = () => setRestart(true)
    const Loader = React.memo(props => (
        <LoadingComponent
            transparent
            error={error}
            loading={!done}
            onRetry={handleRetry}
            {...props}
        />
    ))

    useEffect(() => {
        if (!start && !restart) return

        loader()
            .then(module => {
                const importedModule = get(module, segment)

                setDynamicModule(importedModule)
            })
            .catch(err => {
                setError(err)

                // eslint-disable-next-line no-console
                if (isDevelopment) console.error(err)
            })
    }, [loader, start, restart])

    useEffect(() => {
        if (error && process.env.REACT_APP_ENV === "prod") {
            Sentry.withScope(scope => {
                Object.keys(error).forEach(key =>
                    scope.setExtra(key, error[key]),
                )
                Sentry.captureException(error)
            })
        }
    }, [error])

    // Effect used to determine if the module has done with the import
    useEffect(() => {
        if (DynamicModule && !done) {
            setDone(true)
            setRestart(false)
        }

        if (done && !DynamicModule) setDone(false)
    }, [DynamicModule, done])

    return [DynamicModule || Loader, done, error, handleRetry]
}

export default useDynamicImport
