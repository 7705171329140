/* eslint-disable no-undef */
import { push } from "connected-react-router"
import get from "lodash/get"

import { dispatchNewWindow, getFullDomain } from "lib/utils/helpers/uri"
import { createPreppedRoutePart } from "lib/utils/helpers/routing"

import {
    selectCurrentEnvironment,
    selectData,
} from "redux/reducers/entities/companies"
import { selectIsSubdomain } from "redux/reducers/core/environment"
import {
    selectCompanyRoutePrefix,
    selectIsPreviewing,
    selectPreviewingMatch,
} from "redux/reducers/router/index"

export const createNewWindowNavigateAction = routePart => (_, getState) => {
    const { protocol, port } = window.location
    const domain = getFullDomain()
    const prefix = selectCompanyRoutePrefix(getState())
    const preppedRoutePart = createPreppedRoutePart(routePart)

    port
        ? dispatchNewWindow(
              `${protocol}//${domain}:${port}${prefix}/${preppedRoutePart}`,
          )
        : dispatchNewWindow(
              `${protocol}//${domain}${prefix}/${preppedRoutePart}`,
          )
}

export const createEnvironmentNavigationAction = (
    routePart,
    companyHash = null,
) => (dispatch, getState) => {
    const state = getState()
    const isSubdomain = selectIsSubdomain(state)
    const preppedRoutePart = createPreppedRoutePart(routePart)

    if (isSubdomain) {
        dispatch(push(`/${preppedRoutePart}`))
        return
    }

    const environment = companyHash
        ? get(selectData(state), `${companyHash}.environment`)
        : selectCurrentEnvironment(state)

    dispatch(push(`/${environment}/${preppedRoutePart}`))
}

export const createPlayerNavigationAction = url => (dispatch, getState) => {
    const state = getState()
    const isPreviewing = selectIsPreviewing(state)

    if (isPreviewing) {
        const {
            params: { journeyHash },
        } = selectPreviewingMatch(state)

        dispatch(
            createEnvironmentNavigationAction(`/preview/${journeyHash}${url}`),
        )
    } else {
        dispatch(createEnvironmentNavigationAction(url))
    }
}
