import React from "react"
import PropTypes from "prop-types"

const Icon = ({
    width,
    height,
    title,
    path,
    color,
    viewBox,
    className,
    size,
    ...restProps
}) => {
    const selectViewBox = () => {
        if (viewBox) {
            return viewBox
        }
        if (size || (width && height)) {
            return `0 0 ${size || width} ${size || height}`
        }
        return "0 0 24 24"
    }
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={width || size}
            height={height || size}
            viewBox={selectViewBox()}
            fill={color}
            {...restProps}
        >
            {title && <title id="title">{title}</title>}
            {path}
        </svg>
    )
}

Icon.propTypes = {
    path: PropTypes.node.isRequired,
    title: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    className: PropTypes.string,
    viewBox: PropTypes.string,
}

Icon.defaultProps = {
    title: null,
    width: null,
    height: null,
    size: 24,
    color: "currentColor",
    className: "",
    viewBox: "",
}

export default React.memo(Icon)
