import React from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { withTranslation } from "react-i18next"

import withStyles from "@material-ui/core/styles/withStyles"
import FormControl from "@material-ui/core/FormControl"
import Typography from "@material-ui/core/Typography"

import styles from "./styles"

import ErrorOutlet from "../ErrorOutlet"
import StrengthBar from "./StrengthBar"
import FieldAdornment from "./FieldAdornment"
import TextField from "../TextField"

const inputTextType = "text"
const inputPasswordType = "password"

class ValidatedFormField extends React.PureComponent {
    state = {
        passwordVisible: false,
        isFocused: false,
    }

    onFocus = () => {
        const {
            showStrength,
            input: { onFocus },
        } = this.props

        if (showStrength) {
            this.setState({ isFocused: true })
        }

        onFocus()
    }

    onBlur = info => {
        const {
            showStrength,
            input: { onBlur },
        } = this.props

        if (showStrength) {
            this.setState({ isFocused: false })
        }

        onBlur(info)
    }

    flipPasswordVisible() {
        const { passwordVisible } = this.state

        this.setState({ passwordVisible: !passwordVisible })
    }

    render() {
        const {
            t,
            classes,
            label,
            meta,
            disabled,
            input: { value, onChange },
            showStrength,
            placeholder,
            autoFocus,
            type,
            disableAutoCapitalize,
            disableValidationAdornment,
            hasFieldError,
            errorText,
            hideAdornment,
            strongPassword,
        } = this.props
        const { touched, error } = meta
        const { passwordVisible, isFocused } = this.state
        const hasError = (error || hasFieldError) && touched
        const isPassword = type === inputPasswordType
        const isStrengthVisible = isPassword && showStrength
        const inputType = isPassword && passwordVisible ? inputTextType : type

        return (
            <FormControl
                className={classes.formControl}
                error={hasError}
                disabled={disabled}
            >
                <TextField
                    label={label}
                    classes={{
                        textField: classes.textField,
                    }}
                    value={value}
                    type={inputType}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={onChange}
                    disabled={disabled}
                    error={hasError}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    inputProps={{
                        autoCapitalize: disableAutoCapitalize
                            ? "off"
                            : "sentences",
                    }}
                    endAdornment={
                        isPassword && !hideAdornment ? (
                            <FieldAdornment
                                classes={classes}
                                meta={meta}
                                onClick={() => this.flipPasswordVisible()}
                                hasVisibility={isPassword}
                                passwordVisible={passwordVisible}
                                disableValidationAdornment={
                                    disableValidationAdornment
                                }
                            />
                        ) : null
                    }
                />

                {isStrengthVisible && (
                    <StrengthBar
                        classes={classes}
                        isFocused={isFocused}
                        current={value}
                        error={error}
                        touched={touched}
                        strongPassword={strongPassword}
                    />
                )}
                <div className={classes.errorContainer}>
                    {touched && errorText && (
                        <Typography
                            className={classes.error}
                            variant="caption"
                            dangerouslySetInnerHTML={{ __html: t(errorText) }}
                        />
                    )}
                </div>
                {touched && error && error !== inputPasswordType && (
                    <ErrorOutlet meta={meta} disabled={disabled} />
                )}
            </FormControl>
        )
    }
}

ValidatedFormField.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    input: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
    }),
    meta: PropTypes.shape({
        valid: PropTypes.bool,
        touched: PropTypes.bool,
        submitFailed: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
    disabled: PropTypes.bool,
    showStrength: PropTypes.bool,
    strongPassword: PropTypes.bool,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    type: PropTypes.string,
    disableAutoCapitalize: PropTypes.bool,
    disableValidationAdornment: PropTypes.bool,
    hasFieldError: PropTypes.bool,
    errorText: PropTypes.string,
    hideAdornment: PropTypes.bool,
}

ValidatedFormField.defaultProps = {
    label: "",
    disabled: false,
    input: {},
    meta: {
        touched: false,
        error: null,
    },
    showStrength: false,
    strongPassword: false,
    placeholder: null,
    autoFocus: false,
    type: inputTextType,
    disableAutoCapitalize: false,
    disableValidationAdornment: false,
    hasFieldError: false,
    errorText: null,
    hideAdornment: false,
}

export default compose(
    withTranslation(),
    withStyles(styles),
    React.memo,
)(ValidatedFormField)
