import get from "lodash/get"

import client from "configure/client"

import {
    hasCredentialMgmt,
    setCurrentCredentialData,
    storeCurrentCredentials,
} from "lib/utils/helpers/credential-mgmt"
import { outerJoin } from "lib/utils/helpers/data-sets"
import { ERROR, LOADED, LOADING } from "redux/middleware/actionNames"
import { addTokenToRequest } from "redux/middleware/helpers"
import { CREATE, DELETE, SELECT, UPDATE, ADD } from "redux/utility"

import { selectActiveCompany } from "../../core/environment"
import { createEnvironmentNavigationAction } from "../../router/actions"

import { selectSelectedHash as selectSelectedFileHash } from "../files"

import { selectSelectedHash as selectCurrentCompanyHash } from "../companies"
import { selectData as selectInvitesData } from "../invites"
import {
    selectData as selectJourneyData,
    selectSelectedHash as selectSelectedJourneyHash,
} from "../journeys"
import { createWarningAction } from "../toasts/actions"

import {
    CHANGE_PASSWORD,
    USER,
    LOGGED_IN_USER,
    USER_RESEND_MAIL,
    USERS,
    JOURNEY_TALENTS,
    USER_ROLES,
    USER_RELATIONS,
    USER_RELATIONS_INVERSED,
    MULTIPLE,
} from "./actionNames"
import {
    selectData,
    selectSelectedHash,
    selectLoggedInUser,
    selectLoggedInHash,
} from "."
import { mergeUserData } from "./helpers"

// Action creators
export const createLoadLoggedInUserAction = (callback = null) => (
    dispatch,
    getState,
) => {
    const state = getState()
    const activeCompany = selectActiveCompany(state)
    const companyHash = get(activeCompany, "hash")

    dispatch({
        type: LOGGED_IN_USER,
        request: {
            method: "get",
            url: "/editor/User/LoggedInUser",
        },
        payload: { companyHash },
        callback: (...params) => {
            const [
                ,
                ,
                {
                    result: { firstName, lastName, avatarImageUrl },
                },
            ] = params

            if (hasCredentialMgmt) {
                setCurrentCredentialData(
                    null,
                    null,
                    `${firstName} ${lastName}`,
                    avatarImageUrl,
                )
                storeCurrentCredentials()
            }

            if (callback) callback(...params)
        },
    })
}

export const createLoadUserByInviteAction = (inviteHash, callback = null) => ({
    type: LOGGED_IN_USER,
    request: {
        method: "GET",
        url: `/editor/UserInvite/GetUserByInvite/${inviteHash}`,
    },
    payload: {},
    callback,
})

export const createLoadUserAction = (
    userHash,
    companyHash = null,
    callback = null,
) => (dispatch, getState) => {
    const state = getState()
    const currentCompanyHash = companyHash || selectCurrentCompanyHash(state)

    dispatch({
        type: USER,
        request: {
            method: "GET",
            url: `/editor/Company/${currentCompanyHash}/User/${userHash}`,
        },
        payload: {
            userHash,
            companyHash,
        },
        callback,
    })
}

export const createLoadingAction = (companyHash = null) => (
    dispatch,
    getState,
) => {
    const state = getState()
    const usableCompanyHash = companyHash || selectCurrentCompanyHash(state)

    const talentRequest = addTokenToRequest(state, {
        type: "GET",
        url: `/editor/User/Talents/${usableCompanyHash}`,
    })
    const staffRequest = addTokenToRequest(state, {
        type: "GET",
        url: `/editor/User/Staff/${usableCompanyHash}`,
    })

    const requests = [
        client.request(staffRequest),
        client.request(talentRequest),
    ]

    dispatch({ type: USERS + LOADING })
    Promise.all(requests).then(
        ([
            {
                data: { data: staff },
            },
            {
                data: { data: talents },
            },
        ]) => {
            const journeyData = selectJourneyData(getState())

            dispatch({
                type: USERS + LOADED,
                payload: { result: mergeUserData(staff, talents, journeyData) },
            })
        },
        result => dispatch({ type: USERS + ERROR, payload: { result } }),
    )
}

export const createLoadingJourneyTalentsAction = (journeyHash = null) => (
    dispatch,
    getState,
) => {
    const state = getState()
    const currentJourneyHash = journeyHash || selectSelectedJourneyHash(state)

    dispatch({
        type: JOURNEY_TALENTS,
        request: {
            method: "GET",
            url: `/editor/User/Journey/${currentJourneyHash}`,
        },
        payload: { journeyHash: currentJourneyHash },
    })
}

export const createCreateAction = (data, callback = null) => ({
    type: USER + CREATE,
    request: {
        method: "POST",
        url: "/editor/Authentication/Create",
        data: {
            ...data,
        },
    },
    payload: data,
    callback,
})

export const createCreateWithInviteAction = (data, callback = null) => ({
    type: USER + CREATE,
    request: {
        method: "POST",
        url: "/editor/UserInvite/SignupWithInviteCode",
        data: {
            ...data,
        },
    },
    payload: data,
    callback,
})

export const createUpdateAction = (
    { firstName, lastName, wantsPromotionalMail, ...rest },
    callback = null,
) => ({
    type: USER + UPDATE,
    request: {
        method: "POST",
        url: "/editor/User/Update",
        data: {
            firstName,
            lastName,
            wantsPromotionalMail,
            ...rest,
        },
    },
    payload: {
        firstName,
        lastName,
        wantsPromotionalMail,
        ...rest,
    },
    callback,
})

export const createUpdateAvatarAction = (fileHash = null, callback = null) => (
    dispatch,
    getState,
) => {
    const state = getState()
    const usableFileHash = fileHash || selectSelectedFileHash(state)

    dispatch({
        type: USER + UPDATE,
        request: {
            method: "POST",
            url: "/editor/User/UpdateAvatar",
            data: {
                fileHash: usableFileHash,
            },
        },
        payload: {
            fileHash: usableFileHash,
        },
        callback,
    })
}

export const createChangePasswordAction = (data, callback = null) => ({
    type: USER + CHANGE_PASSWORD,
    request: {
        method: "POST",
        url: "/editor/User/ChangePassword",
        data,
    },
    payload: data,
    callback,
})
export const createChangeEmailAction = (emailAddress, callback = null) => ({
    type: USER + UPDATE,
    request: {
        method: "POST",
        url: "/editor/User/ChangeEmail",
        data: {
            emailAddress,
        },
    },
    payload: {
        emailAddress,
        hasConfirmedEmail: false,
        isLoggedInUser: true,
        toast: {
            suppressSuccess: true,
            values: { emailAddress },
        },
    },
    callback,
})

/* TODO: Doesn't work we don't know the email, check request ticket */
export const createReSendMailAction = () => (dispatch, getState) => {
    const state = getState()
    const { emailAddress } = selectLoggedInUser(state) || {}

    if (!emailAddress) {
        dispatch(
            createWarningAction(
                "main:login_required_resend_verification_email",
            ),
        )
    }

    dispatch({
        type: USER_RESEND_MAIL,
        request: {
            method: "POST",
            url: "/editor/Authentication/ResendValidationEmail",
            data: { email: emailAddress },
        },
    })
}

export const createSelectUserAction = userHash => ({
    type: USER + SELECT,
    payload: {
        selectedHash: userHash,
    },
})

export const createDeleteAction = (user, companyHash, callback = null) => ({
    type: USER + DELETE,
    request: {
        method: "POST",
        url: "/editor/User/Terminate",
        data: { userHash: user.userHash, companyHash },
    },
    payload: {
        user,
        toast: {
            suppressSuccess: !!callback,
            values: { userName: `${user.firstName} ${user.lastName}` },
        },
    },
})

export const createTerminateUserAction = (userHash = null) => (
    dispatch,
    getState,
) => {
    const state = getState()
    const usableUserHash = userHash || selectSelectedHash(state)
    const companyHash = selectCurrentCompanyHash(state)
    const user = get(selectData(state), usableUserHash)

    dispatch(createEnvironmentNavigationAction("/editor/users"))

    const terminateUser = () => dispatch(createDeleteAction(user, companyHash))

    terminateUser()
}

// DEPRECATED: This API endpoint has been deprecated with the last update
export const createTerminateTalentUserAction = (
    user,
    companyHash,
    callback = null,
) => ({
    type: USER + DELETE,
    request: {
        method: "POST",
        url: "/editor/TalentUser/Terminate",
        data: { userHash: user.userHash, companyHash },
    },
    payload: {
        user,
        toast: {
            suppressSuccess: !!callback,
            values: { userName: `${user.firstName} ${user.lastName}` },
        },
    },
})

export const createTerminateStaffUserAction = (
    user,
    userRoleType,
    inviteHash,
    callback = null,
) => (dispatch, getState) => {
    const state = getState()
    const invitesData = selectInvitesData(state)
    const companyHash = selectCurrentCompanyHash(state)
    const deletedInvite = invitesData[inviteHash]
    const loggedInUserHash = selectLoggedInHash(state)
    const isLoggedInUser = loggedInUserHash === user?.userHash
    const invites = outerJoin(
        user.inviteHashes.filter(hash => hash !== inviteHash),
        invitesData,
    )

    dispatch({
        type: USER_ROLES + DELETE,
        request: {
            method: "POST",
            url: "/editor/User/TerminateUserRole",
            data: {
                userHash: user.userHash,
                userRoleType,
                companyHash,
                dontSendEmail: false,
            },
        },
        payload: {
            inviteHash,
            deletedInvite,
            isLoggedInUser,
            userHash: user?.userHash,
            invites,
            toast: {
                suppressSuccess: !!callback,
                values: { userName: `${user.firstName} ${user.lastName}` },
            },
        },
    })
}

export const createLoadingUserRelations = (hash = null) => (
    dispatch,
    getState,
) => {
    const state = getState()
    const companyHash = selectCurrentCompanyHash(state)
    const userHash = hash || selectSelectedHash(state)

    dispatch({
        type: USER + USER_RELATIONS,
        request: {
            method: "GET",
            url: `/editor/UserRelation/Company/${companyHash}/User/${userHash}`,
        },
        payload: {
            userHash,
        },
    })
}

export const createAddUserRelations = (
    userRelations,
    targetHash = null,
    callback,
) => (dispatch, getState) => {
    const state = getState()
    const companyHash = selectCurrentCompanyHash(state)
    const userHash = targetHash || selectSelectedHash(state)

    dispatch({
        type: USER + ADD + USER_RELATIONS,
        request: {
            method: "POST",
            url: "/editor/UserRelation/BulkAssign",
            data: {
                companyHash,
                targetUserHash: userHash,
                userRelations,
            },
        },
        payload: {
            userRelations,
            userHash,
        },
        callback,
    })
}

export const createLoadingInversedUserRelations = (hash = null) => (
    dispatch,
    getState,
) => {
    const state = getState()
    const companyHash = selectCurrentCompanyHash(state)
    const userHash = hash || selectSelectedHash(state)

    dispatch({
        type: USER + USER_RELATIONS_INVERSED,
        request: {
            method: "GET",
            url: `/editor/UserRelation/Inversed/Company/${companyHash}/User/${userHash}`,
        },
        payload: {
            userHash,
        },
    })
}

export const createUpdateMultipleUsersRelations = (userRelations, callback) => (
    dispatch,
    getState,
) => {
    const state = getState()
    const companyHash = selectCurrentCompanyHash(state)

    dispatch({
        type: USER + UPDATE + MULTIPLE + USER_RELATIONS,
        request: {
            method: "POST",
            url: "editor//UserRelation/Users/BulkAssign",
            data: {
                companyHash,
                users: userRelations,
            },
        },
        payload: {
            userRelations,
        },
        callback,
    })
}

export const createUpdateUserRelations = (userRelations, callback) => (
    dispatch,
    getState,
) => {
    const state = getState()
    const companyHash = selectCurrentCompanyHash(state)
    const userHash = selectSelectedHash(state)

    dispatch({
        type: USER + UPDATE + USER_RELATIONS,
        request: {
            method: "POST",
            url: "editor/UserRelation/BulkAssign",
            data: {
                companyHash,
                targetUserHash: userHash,
                userRelations,
            },
        },
        payload: {
            userRelations,
            userHash,
        },
        callback,
    })
}
