import get from "lodash/get"
import first from "lodash/first"

import { createSelector } from "reselect"

// Selectors
export const selectData = state => get(state, "data")
export const selectIdentifiers = state => get(state, "identifiers")
export const selectSelectedHash = state => get(state, "selectedHash")
export const selectIsLoading = state => get(state, "isLoading")
export const selectHasFetched = state => get(state, "hasFetched")
export const selectLoadingError = state => get(state, "loadingError")

export const selectWorkspaces = createSelector(
    selectData,
    selectIdentifiers,
    (data, identifiers) => identifiers.map(id => data[id]),
)

export const selectCurrentWorkspace = createSelector(
    selectWorkspaces,
    selectSelectedHash,
    (workspaces, workspaceHash) =>
        workspaces.find(workspace => workspace.hash === workspaceHash),
)

export const selectDefaultWorkspace = createSelector(
    selectWorkspaces,
    workspaces =>
        workspaces &&
        (workspaces.find(workspace => get(workspace, "isDefault")) ||
            first(workspaces)),
)
