import get from "lodash/get"
import { createSelector } from "reselect"

// Selectors
export const selectLocation = state => get(state, "location")
export const selectMatch = state => get(state, "match")
export const selectTrails = state => get(state, "trails")
export const selectIsFirstRendering = state => state?.isFirstRendering

export const selectPathname = createSelector(selectLocation, location =>
    get(location, "pathname"),
)
