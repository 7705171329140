import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { compose } from "redux"
import FormHelperText from "@material-ui/core/FormHelperText"

import { parseValidationString } from "../../../utils/helpers/validation"

const ErrorOutlet = ({
    t,
    meta: { touched, error },
    disabled,
    className,
    noTouchRequired,
}) => {
    const errorMessage =
        (noTouchRequired || touched) &&
        error &&
        t(...parseValidationString(error))

    return (
        <FormHelperText
            error
            aria-errormessage={errorMessage}
            className={className}
            disabled={disabled}
        >
            {errorMessage}
        </FormHelperText>
    )
}

ErrorOutlet.propTypes = {
    t: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    noTouchRequired: PropTypes.bool,
}

ErrorOutlet.defaultProps = {
    className: null,
    disabled: false,
    noTouchRequired: false,
}

export default compose(withTranslation(), React.memo)(ErrorOutlet)
