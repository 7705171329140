import { connect } from "react-redux"

import { selectActiveMUITheme } from "../redux/reducers/core/environment"
import ThemeProvider from "."

const mapStateToProps = state => ({
    theme: selectActiveMUITheme(state),
})

export default connect(mapStateToProps)(ThemeProvider)
