import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"

import { withStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import { Typography } from "@material-ui/core"
import FormLabel from "@material-ui/core/FormLabel"

import Checkbox from "../../Checkbox"
import ErrorOutlet from "../ErrorOutlet"

import styles from "./styles"

const CheckboxField = ({
    classes,
    label,
    disabled,
    required,
    noValidation,
    meta: { error, touched },
    input: { value, onChange, onBlur },
    meta,
    children,
    ...restProps
}) => {
    const handleChange = useCallback(() => {
        if (disabled) return

        if (onBlur) {
            onBlur()
        }

        if (onChange) {
            onChange(!value)
        }
    }, [disabled, value])

    return (
        <FormControl
            error={touched && !!error}
            disabled={disabled}
            className={disabled && classes.disabled}
        >
            <div className={classes.checkboxContainer}>
                <Checkbox
                    {...restProps}
                    className={classes.checkbox}
                    checked={!!value}
                    onClick={handleChange}
                    error={!noValidation && touched && error}
                />

                {label && (
                    <FormLabel
                        disabled={disabled}
                        className={classes.formLabel}
                        onClick={handleChange}
                    >
                        {label.length === 2 ? (
                            <Typography
                                className={classes.text}
                                variant="caption"
                            >
                                {label[0]}
                                {label[1]}
                                {required && <span>&nbsp;*</span>}
                            </Typography>
                        ) : (
                            <Typography
                                className={classes.text}
                                variant="body1"
                            >
                                {label}

                                {required && <span>&nbsp;*</span>}
                            </Typography>
                        )}
                    </FormLabel>
                )}

                {children}
            </div>

            {!noValidation && touched && error && (
                <ErrorOutlet className={classes.helperText} meta={meta} />
            )}
        </FormControl>
    )
}

CheckboxField.propTypes = {
    classes: PropTypes.exact({
        checkboxContainer: PropTypes.string,
        checkbox: PropTypes.string,
        disabled: PropTypes.string,
        formLabel: PropTypes.string,
        text: PropTypes.string,
        helperText: PropTypes.string,
    }).isRequired,
    label: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.array,
    ]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
    }),
    noValidation: PropTypes.bool,
    children: PropTypes.node,
}

CheckboxField.defaultProps = {
    label: null,
    disabled: false,
    required: false,
    meta: {
        touched: false,
        error: null,
    },
    input: {
        value: false,
    },
    noValidation: false,
    children: null,
}

export default compose(
    withStyles(styles, { name: "CheckboxField" }),
    React.memo,
)(CheckboxField)
