import get from "lodash/get"

const styles = theme => ({
    root: {
        position: "absolute",
        bottom: "88px",
        right: get(theme, "appicalTheme.spacing.small"),
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
        maxWidth: "500px",
        height: "auto",
        zIndex: 2147483647,
    },

    animationWrapper: {
        width: "100%",
    },
})

export default styles
