import { createActionName } from "../../../utility"

export const reducerName = "users"

// Actions
export const LOGGED_IN_USER = createActionName(reducerName, "LOGGED_IN_USER")
export const USER = createActionName(reducerName, "USER")
export const USER_ROLES = createActionName(reducerName, "USER_ROLES")
export const USERS = createActionName(reducerName, "USERS")
export const JOURNEY_TALENTS = createActionName(reducerName, "JOURNEY_TALENTS")
export const USER_RESEND_MAIL = `${USER}_RESEND_UPDATE`
export const CHANGE_PASSWORD = "_CHANGE_PASSWORD"
export const USER_RELATIONS = "_USER_RELATIONS"
export const USER_RELATIONS_INVERSED = "_USER_RELATIONS_INVERSED"
export const MULTIPLE = "MULTIPLE"
