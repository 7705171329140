import get from "lodash/get"

import { emptyQuillRegex } from "lib/views/Fields/TextEditor/helpers"

export const isRequired = (translation = "main:field_required") => value =>
    value || typeof value === "number" ? undefined : translation

export const isValidString = (
    translation = "main:field_invalid_value",
) => value =>
    value && typeof value === "string" && !!value.replace(/\s/g, "")
        ? undefined
        : translation

export const isRequiredTextInHtml = (
    translation = "main:field_required",
) => html =>
    html && html?.replace(/<\s*[^>]*>/gi, "") ? undefined : translation

export const isRequiredQuill = (translation = "main:field_required") => value =>
    value && !emptyQuillRegex.test(value) ? undefined : translation

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

export const isValidEmail = (
    translation = "main:email_invalid",
    options = { isRequired: true },
) => value =>
    (options.isRequired && value && emailRegex.test(value)) ||
    (!value && !options.isRequired) ||
    emailRegex.test(value)
        ? undefined
        : translation

const upperCaseRegex = /[A-Z]/
const lowerCaseRegex = /[a-z]/
const digitRegex = /[0-9]/
const alphanumericRegex = /[^0-9a-zA-Z\d\s:]/

export const isValidPassword = (
    translation = "main:password_too_weak",
) => value => {
    const hasUpercase = upperCaseRegex.test(value)
    const hasLowerCase = lowerCaseRegex.test(value)
    const hasDigit = digitRegex.test(value)
    const hasAlphaNumeric = alphanumericRegex.test(value)
    const isLongEnough = value && value.length >= 8

    return hasUpercase &&
        hasLowerCase &&
        hasDigit &&
        hasAlphaNumeric &&
        isLongEnough
        ? undefined
        : translation
}

export const maxLength = (
    max,
    translation = "main:max_length_exceeded",
) => value =>
    !value || value.length <= max ? undefined : `${translation}|max-${max}`

export const maxLengthTextInHtml = (
    max,
    translation = "main:max_length_exceeded",
) => html =>
    !html || html?.replace(/<\s*[^>]*>/gi, "").length <= max
        ? undefined
        : translation && `${translation}|max-${max}`

export const minLength = (
    min,
    translation = "main:below_min_length",
) => value =>
    value && value.length >= min ? undefined : `${translation}|min-${min}`

export const isSameAs = (
    fieldName,
    translation = "main:field_value_mismatch",
) => (value, values) =>
    value && get(values, fieldName) && value === get(values, fieldName)
        ? undefined
        : translation

const urlValueRegex = /^(?:(http|ftp|ssh|git)(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$|^(?:[\w]*?):(?!\/\/)[\w\-._~:/?#[\]@!$&'()*+,;=]+$/
const subdomainValueRegex = /^[\w][\w-]{1,61}[\w]$/
const VATValueRegex = /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/

export const isValidUrlValue = (
    translation = "main:field_invalid_url_value",
    options = { isRequired: true },
) => value =>
    (options.isRequired && value && urlValueRegex.test(value)) ||
    (!value && !options.isRequired) ||
    urlValueRegex.test(value)
        ? undefined
        : translation

export const isValidSubdomain = (
    translation = "main:field_invalid_subdomain_value",
) => value =>
    value && subdomainValueRegex.test(value) ? undefined : translation

export const isValidVAT = (
    translation = "main:field_invalid_vat_value",
) => value => (value && VATValueRegex.test(value) ? undefined : translation)

export const parseValidationString = string => {
    if (!string || !string.includes("|")) return [string]

    const [translation, argString] = string.split("|")

    const interpolations = argString.split(",").reduce((interpol, arg) => {
        const [name, value] = arg.split("-")
        return { ...interpol, [name]: value }
    }, {})

    return [translation, interpolations]
}
