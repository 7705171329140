import get from "lodash/get"
import flatMap from "lodash/flatMap"

import { filterCategories } from "constants/internal"
import { fileType, fileVersionType } from "constants/enums"

export const extractFiles = results =>
    flatMap(results, folder => [
        ...get(folder, "files", []),
        ...flatMap(
            get(folder, "folders", []),
            ({ files: subFiles }) => subFiles,
        ),
    ])

export const getFileVersionsByFileType = type => {
    switch (type) {
        case fileType.image:
            return [
                fileVersionType.original,
                fileVersionType.thumbnail,
                fileVersionType.contentLogo,
                fileVersionType.fullHD,
                fileVersionType.standardHD,
            ]
        case fileType.video:
            return [fileVersionType.original, fileVersionType.contentLogo]
        default:
            return [fileVersionType.original]
    }
}

export const extractFiltersFromFiles = files =>
    files.reduce(
        (filters, { type, createdBy }) => {
            const {
                [filterCategories.uploaders]: uploaders,
                [filterCategories.fileType]: fileTypes,
            } = filters

            if (type && !fileTypes.includes(type)) {
                fileTypes.push(type)
            }

            const userHash = get(createdBy, "userHash")
            if (userHash && !uploaders.has(userHash)) {
                uploaders.set(userHash, createdBy)
            }

            return filters
        },
        {
            [filterCategories.uploaders]: new Map(),
            [filterCategories.fileType]: [],
        },
    )
