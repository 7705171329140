import psl from "psl"
import get from "lodash/get"
import memoize from "memoize-one"

import { editorDomainKey } from "constants/internal"

export const getFullDomain = () => {
    const { REACT_APP_TEST_DOMAIN: testDomain } = process.env

    return testDomain || window.location.hostname
}

const domainRegex = /([a-z-0-9]{2,63})\.?([a-z.]{2,5})?(:?[0-9]{2,4})?$/

const parseSubDomainLocal = memoize(hostname => {
    const parts = domainRegex.exec(hostname)

    const sld = parts[1]
    const tld = parts[2]
    const prt = parts[3]

    // extract port length if present
    const prtLength = get(prt, "length", 0)

    // The regex covers sld & tld extraction
    // We need to cut that off the full domain to find the sub
    const removeLength =
        hostname.length -
        (get(sld, "length", 0) + get(tld, "length", 0) + prtLength + 1)

    return hostname.substring(0, removeLength)
})

export const isEditorDomain = name => name === editorDomainKey

const devDomainRegex = /\.dev|\.uat|\.demo|\.translation/
export const isDevDomain = subDomain => devDomainRegex.test(subDomain)

const domainOverrides = {
    "dev.appical.io": editorDomainKey,
    "uat.appical.io": editorDomainKey,
    "translations.appical.io": editorDomainKey,
    "demo.appical.io": editorDomainKey,
    "appical.io": editorDomainKey,
    "onboarding.mindef.nl": editorDomainKey,
    "localhost": editorDomainKey,
}

export const getDomainEnvironment = () => {
    const domain = getFullDomain()
    const actualDomain = get(domainOverrides, domain, domain)

    if (isEditorDomain(actualDomain)) {
        return actualDomain
    }
    const localSubdomain = parseSubDomainLocal(actualDomain)

    const { subdomain } = psl.parse(actualDomain)
    const actualSubdomain = subdomain || localSubdomain

    return isDevDomain(actualSubdomain)
        ? actualSubdomain.replace(devDomainRegex, "")
        : actualSubdomain
}

export const getDomain = () => {
    const { host } = window.location
    const subdomain = getDomainEnvironment()

    if (isEditorDomain(subdomain)) {
        const domain = getFullDomain()

        return domain
    }

    if (subdomain) return host.replace(`${subdomain}.`, "")

    return host
}

export const getProtocol = () => window.location.protocol

export const getPort = () => window.location.port

/* TODO: Remove the dedupe when back-end finally dedupes their URL's */
export const dedupeUrl = url => {
    const secondUrlStart = url && url.lastIndexOf("https://")

    if ((url && url.indexOf("https://")) === secondUrlStart) return url

    return url.substring(secondUrlStart)
}

export const dispatchNewWindow = url => {
    const anchor = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
    anchor.href = url
    anchor.target = "_blank"
    anchor.rel = "noopener noreferrer"

    const event = new MouseEvent("click", {
        view: window,
        bubbles: false,
        cancelable: true,
    })

    anchor.dispatchEvent(event)
}

const searchParamRegex = /\??(?<key>.*)=(?<value>.*)/

export const getSearchParams = memoize((search = "") => {
    const params = search.split("&")

    return params.reduce((result, param) => {
        const regexRes = searchParamRegex.exec(param)
        const { key, value } = get(regexRes, "groups", {})

        if (!key) return result

        return {
            ...result,
            [key]: value,
        }
    }, {})
})

export const redirect = window.location.replace
