import { UPDATE, CREATE, DELETE } from "redux/utility"
import { LOADING, LOADED, ERROR } from "redux/middleware/actionNames"

import { LOGOUT } from "redux/reducers/core/authentication/actionNames"
import { INVITE } from "redux/reducers/entities/invites/actionNames"

import { USER_INVITES, USER_INVITE } from "./actionNames"
import normalize from "./schema"
import { selectData, selectIdentifiers } from "./selectors"

export const initialState = {
    /* Holds the normalized user invites */
    data: {},
    /* Holds the normalized user invite identifiers */
    identifiers: [],
    /* Indicates whether or not user invites have been fetched */
    hasFetched: false,
    /* Indicates whether actions are working on a user invite */
    isLoading: false,
    /* Holds an error if the last action was erroneous  */
    loadingError: null,
    /* Holds the hash of user invite that is currently selected */
    selectedHash: null,
}

export const reducers = {
    [USER_INVITES + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER_INVITES + LOADED](state, { result }) {
        const [identifiers, data] = normalize(result)

        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            data,
            identifiers,
        }
    },

    [USER_INVITES + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            isLoading: false,
            loadingError,
            hasFetched: true,
        }
    },

    [USER_INVITE + UPDATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [USER_INVITE + UPDATE + LOADED](
        state,
        { originalPayload: { inviteHash } },
    ) {
        const identifiers = selectIdentifiers(state).filter(
            hash => hash !== inviteHash,
        )
        const data = { ...selectData(state) }

        delete data[inviteHash]

        return {
            ...state,
            isLoading: false,
            data,
            identifiers,
        }
    },

    [USER_INVITE + UPDATE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [INVITE + CREATE + LOADED](state, { originalPayload: { isLoggedInUser } }) {
        if (!isLoggedInUser) return state

        return {
            ...state,
            hasFetched: false,
        }
    },

    [INVITE + DELETE + LOADED](state, { originalPayload: { isLoggedInUser } }) {
        if (!isLoggedInUser) return state

        return {
            ...state,
            hasFetched: false,
        }
    },

    // reset state
    [LOGOUT + LOADED]() {
        return {
            ...initialState,
        }
    },
}
