import { createSelector } from "reselect"

import { outerJoin } from "lib/utils/helpers/data-sets"

export const selectData = state => state?.data
export const selectIdentifiers = state => state?.identifiers
export const selectIsLoading = state => state?.isLoading
export const selectHasFetched = state => state?.hasFetched
export const selectLoadingError = state => state?.loadingError
export const selectSelectedHash = state => state?.selectedHash

export const selectFiles = createSelector(
    selectIdentifiers,
    selectData,
    outerJoin,
)
