import { createSelector } from "reselect"

import { status } from "redux/utility"
import { createEntitiesFeatureSelector } from "redux/reducers/entities/helpers"

import { policyType } from "constants/policies"

import { reducerName } from "./actionNames"

const selectFeature = createEntitiesFeatureSelector(reducerName)

const selectData = createSelector(selectFeature, state => state?.data || {})

export const selectIsPoliciesLoading = createSelector(
    selectFeature,
    state => state.status === status.loading,
)

export const hasFetchedPolicies = createSelector(
    selectFeature,
    state => state.status === status.success,
)

export const isLoadingPolicies = createSelector(
    selectFeature,
    state => state.status === status.loading,
)

export const selectAgreedPolicies = createSelector(selectData, policies =>
    Object.values(policies)?.filter(({ hasAgreed }) => hasAgreed),
)

export const selectNotAgreedPolicies = createSelector(selectData, policies =>
    Object.values(policies)?.filter(
        ({ hasAgreed, isLatest }) => isLatest && !hasAgreed,
    ),
)

export const selectNotAgreedPolicy = createSelector(
    selectNotAgreedPolicies,
    policies => policies?.shift(),
)

export const selectTermsOfServicePolicy = createSelector(
    selectData,
    policiesData =>
        Object.values(policiesData)?.find(
            ({ type, isLatest }) =>
                type === policyType.termsOfService && isLatest,
        ),
)

export const selectPrivacyPolicy = createSelector(selectData, policiesData =>
    Object.values(policiesData)?.find(
        ({ type, isLatest }) => type === policyType.privacyPolicy && isLatest,
    ),
)
