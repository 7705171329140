import get from "lodash/get"
import { createSelector } from "reselect"

import { outerJoin } from "lib/utils/helpers/data-sets"

// Selectors
export const selectData = state => state?.data
export const selectIdentifiers = state => state?.identifiers
export const selectSelectedHash = state => state?.selectedHash
export const selectIsLoading = state => state?.isLoading
export const selectHasFetched = state => state?.hasFetched
export const selectLoadingError = state => state?.loadingError

export const selectSelectedRelation = createSelector(
    selectData,
    selectSelectedHash,
    get,
)
export const selectRelations = createSelector(
    selectIdentifiers,
    selectData,
    outerJoin,
)
