import get from "lodash/get"

import { CREATE, UPDATE, DELETE, SELECT, RENAME } from "../../../utility"
import { LOADING, LOADED, ERROR } from "../../../middleware/actionNames"

import { LOGOUT } from "../../core/authentication/actionNames"
import { ENVIRONMENT } from "../../core/environment/actionNames"
import { COMPANY } from "../companies/actionNames"

import itemNormalizer from "./schema"
import {
    selectData,
    selectIdentifiers,
    selectActiveTheme,
    selectSelectedHash,
} from "./selectors"
import { THEME, THEMES } from "./actionNames"

export const initialState = {
    /* Holds the normalized topics */
    data: {},
    /* Holds the normalized topic identifiers */
    identifiers: [],
    /* Holds the normalized talent topic identifiers */
    talentIdentifiers: [],
    /* Indicates whether or not topics have been fetched */
    hasFetched: false,
    /* Indicates whether actions are working on a topic */
    isLoading: false,
    /* Holds an error if the last action was erroneous  */
    loadingError: null,
    /* Holds the selected topic hash */
    selectedHash: null,
}

export const reducers = {
    [THEMES + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [THEMES + LOADED](state, { result }) {
        const { data, identifiers } = itemNormalizer(result)
        const activeThemeHash = identifiers.reduce(
            (activeHash, hash) =>
                !activeHash && get(data, `[${hash}].isActive`)
                    ? hash
                    : activeHash,
            null,
        )

        return {
            ...state,
            hasFetched: true,
            isLoading: false,
            data,
            identifiers,
            selectedHash: activeThemeHash,
        }
    },

    [THEMES + ERROR](state, { result }) {
        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError: result,
        }
    },

    [THEME + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [THEME + LOADED](state, { result }) {
        const data = itemNormalizer([result])
        const { hash } = result

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                data,
            },
            identifiers: [...state.identifiers, hash],
        }
    },

    [THEME + ERROR](state, { result }) {
        return { ...state, isLoading: true, loadingError: result }
    },

    [THEME + CREATE + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [THEME + CREATE + LOADED](
        state,
        { originalPayload: { theme }, result: { hash } },
    ) {
        const data = selectData(state)
        const identifiers = selectIdentifiers(state)

        return {
            ...state,
            data: {
                ...data,
                [hash]: {
                    hash,
                    ...theme,
                },
            },
            identifiers: [...identifiers, hash],
            selectedHash: hash,
            isLoading: false,
        }
    },

    [THEME + CREATE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [THEME + UPDATE + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [THEME + UPDATE + LOADED](state, { originalPayload: { theme } }) {
        const hash = get(theme, "hash")
        const data = selectData(state)
        const identifiers = selectIdentifiers(state)
        const oldTheme = data[hash]

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [hash]: {
                    ...oldTheme,
                    ...theme,
                },
            },
            identifiers,
        }
    },

    [THEME + UPDATE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [THEME + RENAME + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [THEME + RENAME + LOADED](state, { originalPayload: { theme } }) {
        const hash = get(theme, "hash")
        const data = selectData(state)
        const identifiers = selectIdentifiers(state)
        const oldTheme = data[hash]

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [hash]: {
                    ...oldTheme,
                    ...theme,
                },
            },
            identifiers,
        }
    },

    [THEME + RENAME + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [THEME + DELETE + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [THEME + DELETE + LOADED](state, { originalPayload: { theme } }) {
        const hash = get(theme, "hash")
        const oldData = selectData(state)
        const oldIdentifiers = selectIdentifiers(state)
        const identifiers = oldIdentifiers.filter(id => id !== hash)
        const data = { ...oldData }
        const currentlySelectedHash = selectSelectedHash(state)
        const selectedHash =
            currentlySelectedHash === hash
                ? get(selectActiveTheme(state), "hash")
                : currentlySelectedHash

        delete data[hash]

        return {
            ...state,
            isLoading: false,
            selectedHash,
            data,
            identifiers,
        }
    },

    [THEME + DELETE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [THEME + SELECT + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [THEME + SELECT + LOADED](state, { originalPayload }) {
        const hash = get(originalPayload, "theme.hash")
        const data = selectData(state)
        const { hash: prevActiveThemeHash, ...rest } = selectActiveTheme(state)
        const theme = data[hash]

        return {
            ...state,
            isLoading: false,
            data: {
                ...data,

                [prevActiveThemeHash]: {
                    ...rest,
                    hash: prevActiveThemeHash,
                    isActive: false,
                },

                [hash]: {
                    ...theme,
                    isActive: true,
                },
            },
        }
    },

    [THEME + SELECT + ERROR](state, { result }) {
        return { ...state, isLoading: false, loadingError: result }
    },

    [THEME + SELECT](state, { selectedHash }) {
        return { ...state, selectedHash }
    },

    [ENVIRONMENT + LOADED](state, { result: { theme } }) {
        const { data, identifiers } = itemNormalizer([theme])
        const { hash: selectedHash } = theme

        return {
            ...state,
            data,
            identifiers,
            selectedHash,
        }
    },

    // reducer reset

    [COMPANY + SELECT](state) {
        return {
            ...initialState,
            data: state.data,
        }
    },

    [LOGOUT + LOADED](state, { originalPayload: { isSubdomain } }) {
        if (!isSubdomain) {
            return initialState
        }

        return {
            ...initialState,
            data: state.data,
        }
    },
}
