import uuid from "uuid"
import get from "lodash/get"
import i8n from "i18next"

import toastTypes from "constants/toastTypes"
import exceptions from "constants/exceptions"

import { selectData, selectIdentifiers } from "./selectors"

export const fromPayloadConfig = (payload, key) =>
    get(payload, `toast.${key}`) || get(payload, `originalPayload.toast.${key}`)

export const createToastReducer = (
    toastCode,
    toastType = toastTypes.warning,
    interpolations = {},
    title = "",
) => (state, payload) => {
    const suppressEmission =
        (toastType === toastTypes.error &&
            fromPayloadConfig(payload, "suppressError")) ||
        (toastType === toastTypes.success &&
            fromPayloadConfig(payload, "suppressSuccess")) ||
        (toastType === toastTypes.warning &&
            fromPayloadConfig(payload, "suppressWarning"))

    if (suppressEmission) {
        return state
    }

    const toasts = selectData(state)
    const identifiers = selectIdentifiers(state)
    const toastInterpolations = {
        ...interpolations,
        ...fromPayloadConfig(payload, "values"),
    }

    const identifier = uuid()

    return {
        ...state,
        data: {
            ...toasts,
            [identifier]: {
                identifier,
                toastCode,
                toastType,
                title,
                interpolations: toastInterpolations,
            },
        },
        identifiers: [...identifiers, identifier],
    }
}

export const createPrerequisiteToast = (state, payload) => {
    const exception = payload?.result?.Reason

    switch (exception) {
        case exceptions.sourceNotInJourney:
            return createToastReducer(
                "main:source_not_in_journey_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.targetNotInJourney:
            return createToastReducer(
                "main:target_not_in_journey_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.circularWorkflow:
            return createToastReducer(
                "main:circular_workflow_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.targetAndSourceSameStep:
            return createToastReducer(
                "main:target_and_source_same_step_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.targetPrerequisiteConflict:
            return createToastReducer(
                "main:target_prerequisite_definition_conflict_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.sourcePrerequisiteConflict:
            return createToastReducer(
                "main:source_prerequisite_definition_conflict_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.invalidPrerequisiteDefinition:
            return createToastReducer(
                "main:invalid_prerequisite_definition_error",
                toastTypes.error,
            )(state, payload)
        default:
            return createToastReducer(
                "main:generic_request_error",
                toastTypes.error,
                { entity: "Rule", action: "creating" },
            )(state, payload)
    }
}

export const createJourneyStepToast = (state, payload) => {
    const exception = payload?.result?.Reason

    switch (exception) {
        case exceptions.sourceNotInJourney:
            return createToastReducer(
                "main:source_not_in_journey_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.targetNotInJourney:
            return createToastReducer(
                "main:target_not_in_journey_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.circularWorkflow:
            return createToastReducer(
                "main:circular_workflow_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.targetPrerequisiteConflict:
            return createToastReducer(
                "main:prerequisite_definition_conflict_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.sourcePrerequisiteConflict:
            return createToastReducer(
                "main:prerequisite_definition_conflict_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.invalidPrerequisiteDefinition:
            return createToastReducer(
                "main:invalid_prerequisite_definition_error",
                toastTypes.error,
            )(state, payload)
        default:
            return createToastReducer(
                "main:update_step_error",
                toastTypes.error,
            )(state, payload)
    }
}

export const createMoveTodoToast = (state, payload) => {
    const exception = payload?.result?.Reason

    switch (exception) {
        case exceptions.circularWorkflow:
            return createToastReducer(
                "main:circular_workflow_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.sourceHigherThanTarget:
            return createToastReducer(
                "main:source_higher_than_target_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.targetLowerThanSource:
            return createToastReducer(
                "main:target_lower_than_source_error",
                toastTypes.error,
            )(state, payload)
        default:
            return createToastReducer(
                "main:update_step_error",
                toastTypes.error,
            )(state, payload)
    }
}

export const createMoveContentToast = (state, payload) => {
    const exception = payload?.result?.Reason

    switch (exception) {
        case exceptions.circularWorkflow:
            return createToastReducer(
                "main:circular_workflow_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.sourceLowerThanTarget:
            return createToastReducer(
                "main:source_lower_than_target_error",
                toastTypes.error,
            )(state, payload)
        case exceptions.targetHigherThanSource:
            return createToastReducer(
                "main:target_higher_than_source_error",
                toastTypes.error,
            )(state, payload)
        default:
            return createToastReducer(
                "main:update_step_error",
                toastTypes.error,
            )(state, payload)
    }
}

export const createPrerequisiteDeleteToast = (state, payload) => {
    const {
        originalPayload: { prerequisite, isNotification = false },
    } = payload

    if (isNotification) {
        return createToastReducer(
            "main:notification_delete_description",
            toastTypes.success,
            {
                sourceName: prerequisite?.sourceName,
                sourceType: i8n.t(
                    `main:tooltip_item_base_type_${prerequisite?.sourceType}`,
                ),
                progressState: i8n.t(
                    `main:tooltip_progress_state_${prerequisite?.userProgressState}`,
                ),
            },
            "main:rule_is_deleted",
        )(state, payload)
    }

    return createToastReducer(
        "main:prerequisite_delete_description",
        toastTypes.success,
        {
            sourceName: prerequisite?.sourceName,
            targetName: prerequisite?.targetName,
            sourceType: i8n.t(
                `main:tooltip_item_base_type_${prerequisite?.sourceType}`,
            ),
            targetType: i8n.t(
                `main:tooltip_item_base_type_${prerequisite?.targetType}`,
            ),
            prerequisiteType: i8n.t(
                `main:tooltip_prerequisite_type_${prerequisite?.prerequisiteType}`,
            ),
            progressState: i8n.t(
                `main:tooltip_progress_state_${prerequisite?.userProgressState}`,
            ),
        },
        "main:rule_is_deleted",
    )(state, payload)
}
