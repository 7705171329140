import first from "lodash/first"

import { ERROR, LOADED, LOADING } from "redux/middleware/actionNames"
import { SELECT, CREATE } from "redux/utility"

import { mapEnvironment } from "redux/reducers/entities/companies/helpers"

import { LOGOUT } from "redux/reducers/core/authentication/actionNames"
import { USER } from "redux/reducers/entities/users/actionNames"
import { COMPANY } from "redux/reducers/entities/companies/actionNames"
import { THEME } from "redux/reducers/entities/themes/actionNames"
import { RequestPayloadWithError } from "redux/reducers/entities/types"
import { CompanyRequestPayload } from "redux/reducers/entities/companies/types"
import { UserRequestPayload } from "redux/reducers/entities/users/types"
import { ENVIRONMENT } from "./actionNames"
import {
    EnvironmentRequestPayload,
    EnvironmentState,
    CompanyThemeRequestPayload,
} from "./types"
import {
    selectData,
    selectIdentifiers,
    selectActiveName,
    selectIsSubdomain,
} from "./selectors"

//  Required variables
export const initialState: EnvironmentState = {
    /* Holds the different environments */
    data: {},
    /* Holds the different environment names */
    identifiers: [],
    /* Holds the flag whether the environment is loading */
    isReady: false,
    /* Holds the last load error from the server */
    loadingError: null,
    /* Holds whether the environment has been extracted from a subdomain domain */
    isSubdomain: false,
    /* Holds the currently active environment name */
    activeName: "",
}

// Reducer
export const reducers = {
    [ENVIRONMENT + LOADING](state: EnvironmentState): EnvironmentState {
        return { ...state, loadingError: null }
    },

    [ENVIRONMENT + LOADED](
        state: EnvironmentState,
        payload: CompanyThemeRequestPayload,
    ): EnvironmentState {
        const {
            result: { company, theme },
            originalPayload: { name },
        } = payload

        return {
            ...state,
            isReady: state.isReady || state.activeName === name,
            data: {
                ...state.data,
                [name]: {
                    companyHash: company.companyHash,
                    themeHash: theme.hash,
                },
            },
            identifiers: [...state.identifiers, name],
        }
    },

    [ENVIRONMENT + ERROR](
        state: EnvironmentState,
        payload: RequestPayloadWithError,
    ): EnvironmentState {
        const { result: loadingError } = payload
        return { ...state, isReady: true, loadingError }
    },

    [ENVIRONMENT + SELECT](
        state: EnvironmentState,
        payload: EnvironmentRequestPayload,
    ): EnvironmentState {
        const { name: activeName, isReady, isSubdomain } = payload

        return {
            ...state,
            activeName,
            isReady,
            isSubdomain,
            loadingError: null,
        }
    },

    [COMPANY + CREATE + LOADED](
        state: EnvironmentState,
        payload: CompanyRequestPayload,
    ): EnvironmentState {
        const {
            result: { hash: companyHash },
            originalPayload,
        } = payload
        const data = selectData(state)
        const identifiers = selectIdentifiers(state)
        const { environment: name } =
            first(mapEnvironment([originalPayload])) || {}

        return {
            ...state,
            data: {
                ...data,
                [name]: {
                    companyHash,
                    themeHash: null,
                },
            },
            identifiers: [...identifiers, name],
            activeName: name,
            isReady: true,
        }
    },

    [USER + CREATE + LOADED](
        state: EnvironmentState,
        payload: UserRequestPayload,
    ): EnvironmentState {
        const {
            result: { companies },
        } = payload
        if (!companies?.length || companies?.length !== 1) {
            return state
        }

        const mappedCompaniesData = mapEnvironment(companies)
        const activeName = mappedCompaniesData?.[0]?.environment

        if (activeName === state.activeName) return { ...state }

        const data = selectData(state)
        const identifiers = selectIdentifiers(state)
        const companyHash = mappedCompaniesData?.[0]?.hash
        const currentCompany = data[activeName]

        return {
            ...state,
            data: {
                ...data,
                [activeName]: {
                    ...currentCompany,
                    companyHash,
                },
            },
            identifiers: [...identifiers, activeName],
            activeName,
            isReady: true,
        }
    },

    [THEME + SELECT + LOADED](
        state: EnvironmentState,
        payload: EnvironmentRequestPayload,
    ): EnvironmentState {
        const {
            originalPayload: {
                theme: { hash: themeHash },
            },
        } = payload
        const activeName: string = selectActiveName(state)
        const data = selectData(state)
        const { [activeName]: environment } = data

        return {
            ...state,
            data: {
                ...data,
                [activeName]: {
                    ...environment,
                    themeHash,
                },
            },
        }
    },

    // reset reducer

    [COMPANY + SELECT](
        state: EnvironmentState,
        payload: CompanyRequestPayload,
    ): EnvironmentState {
        const { selectedHash: companyHash } = payload
        const isSubdomain = selectIsSubdomain(state)

        if (!companyHash && !isSubdomain) {
            return {
                ...state,
                activeName: "",
                isReady: false,
            }
        }

        return state
    },

    [LOGOUT + LOADED](state: EnvironmentState): EnvironmentState {
        const isSubdomain = selectIsSubdomain(state)

        return isSubdomain ? state : initialState
    },
}
