export const embedlyTypes = {
    link: 0,
    video: 1,
    photo: 2,
    rich: 3,
}

export const supportedSocials = [
    "fivehundredpix",
    "bandsintown",
    "behance",
    "codepen",
    "dribbble",
    "dropbox",
    "email: EMAIL_ICON,",
    "facebook",
    "flickr",
    "foursquare",
    "github",
    "google_play",
    "google",
    "instagram",
    "itunes",
    "linkedin",
    "mailto: EMAIL_ICON,",
    "medium",
    "meetup",
    "pinterest",
    "rdio",
    "reddit",
    "rss",
    "sharethis",
    "smugmug",
    "snapchat",
    "soundcloud",
    "spotify",
    "squarespace",
    "tumblr",
    "twitch",
    "twitter",
    "vevo",
    "vimeo",
    "vine",
    "vk",
    "vsco",
    "wechat",
    "yelp",
    "youtube",
]

export default {
    embedlyTypes,
    supportedSocials,
}
