import { schema, normalize } from "normalizr"

export const companySchema = new schema.Entity(
    "companies",
    {},
    { idAttribute: "hash" },
)

export default originalData => {
    const normalized = normalize(originalData, [companySchema])
    const {
        result: keys,
        entities: { companies: normalizedData },
    } = normalized

    return [keys || [], normalizedData || {}]
}
