import React from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import classNames from "classnames"
import get from "lodash/get"

import MuiChip from "@material-ui/core/Chip"
import withStyles from "@material-ui/core/styles/withStyles"

import styles from "./styles"

const Chip = ({ classes, className, color, ...props }) => (
    <MuiChip
        classes={{
            root: classes.chip,
            label: classes.label,
            deleteIcon: classes.deleteIcon,
        }}
        className={classNames(className, get(classes, color))}
        {...props}
    />
)

Chip.propTypes = {
    classes: PropTypes.exact({
        chip: PropTypes.string,
        label: PropTypes.string,
        deleteIcon: PropTypes.string,
        primary: PropTypes.string,
        secondary: PropTypes.string,
        contrast: PropTypes.string,
        success: PropTypes.string,
        error: PropTypes.string,
        warning: PropTypes.string,
        info: PropTypes.string,
        purple: PropTypes.string,
        orange: PropTypes.string,
        green: PropTypes.string,
        pink: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    color: PropTypes.oneOf([
        "default",
        "primary",
        "secondary",
        "contrast",
        "success",
        "error",
        "warning",
        "info",
        "purple",
        "orange",
        "green",
        "pink",
    ]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Chip.defaultProps = {
    className: "",
    color: "default",
    label: null,
}

export default compose(withStyles(styles, { name: "Chip" }), React.memo)(Chip)
