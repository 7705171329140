export default {
    mapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    giphyAPIKey: process.env.REACT_APP_GIPHY_KEY,
    unsplashAPIKey: process.env.REACT_APP_UNSPLASH_KEY,
    stripeApiKey: process.env.REACT_APP_STRIPE_KEY,
    diagnosticsAPIKey: process.env.REACT_APP_DIAGNOSTICS_KEY,
    intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID,
    intercomPrivateKey: process.env.REACT_APP_INTERCOM_PRIVATE_KEY,

    AWSLambdaApiKey: process.env.REACT_APP_LAMBDA_KEY,
}
