import axios from "axios"
import { baseUrl } from "../constants"

export const configureClient = baseURL =>
    axios.create({
        baseURL,
        responseType: "json",

        headers: {
            common: {
                Accept: "application/json",
            },
        },
    })

export default configureClient(baseUrl)
