import find from "lodash/find"
import first from "lodash/first"

import { serverLanguages } from "constants/enums"
import { themeTypes } from "constants/internal"

import { LOADED } from "redux/middleware/actionNames"
import { SELECT, UPDATE } from "redux/utility"

import { LOGIN } from "redux/reducers/core/authentication/actionNames"
import { COMPANY } from "redux/reducers/entities/companies/actionNames"
import { LOGGED_IN_USER } from "redux/reducers/entities/users/actionNames"

import {
    APP_THEME,
    LANGUAGE,
    SHOW_INHERITED_ROLES,
    HIDE_INHERITED_ROLES,
} from "./actionNames"

export const initialState = {
    selectedTheme: themeTypes.light,
    showInheritedRoles: false,
    selectedLanguage: null,
}

export const reducers = {
    [APP_THEME + SELECT](state, { selectedTheme }) {
        return {
            ...state,
            selectedTheme,
        }
    },

    [LANGUAGE + SELECT + LOADED](
        state,
        { originalPayload: { selectedLanguage } },
    ) {
        return {
            ...state,
            selectedLanguage,
        }
    },

    [SHOW_INHERITED_ROLES](state) {
        return {
            ...state,
            showInheritedRoles: true,
        }
    },

    [HIDE_INHERITED_ROLES](state) {
        return {
            ...state,
            showInheritedRoles: false,
        }
    },

    [LOGIN + LOADED](state, { result, originalPayload: { companyHash } }) {
        const { companies, languageId } = result
        const selectedCompany =
            find(companies, { hash: companyHash }) || first(companies)
        return {
            ...state,
            selectedLanguage:
                languageId ||
                selectedCompany?.languageId ||
                serverLanguages.english,
        }
    },

    [LOGGED_IN_USER + LOADED](
        state,
        { result, originalPayload: { companyHash } },
    ) {
        const { companies, languageId } = result
        const selectedCompany =
            find(companies, { hash: companyHash }) || first(companies)

        return {
            ...state,
            selectedLanguage:
                languageId ||
                selectedCompany?.languageId ||
                serverLanguages.english,
        }
    },

    [COMPANY + SELECT](state, { company, loggedInUser }) {
        return {
            ...state,
            selectedLanguage:
                loggedInUser?.languageId ||
                company?.languageId ||
                state?.selectedLanguage ||
                serverLanguages.english,
        }
    },

    [COMPANY + UPDATE + LOADED](state, { originalPayload }) {
        const { userLanguageId } = originalPayload

        return {
            ...state,
            selectedLanguage: userLanguageId,
        }
    },
}
