import map from "lodash/map"

import getUserTypesByRoles from "./getUserTypesByRoles"

const hasUserTypeByRoles = (roles, userTypes) => {
    const flatRoles = map(roles, "role")
    const userTypesByRoles = getUserTypesByRoles(flatRoles)
    const userTypesArr = Array.isArray(userTypes) ? userTypes : [userTypes]

    return userTypesArr.every(userType => userTypesByRoles.includes(userType))
}

export default hasUserTypeByRoles
