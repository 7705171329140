import Delta from "quill-delta"

import TagBlot from "./quillHelpers/TagBlot"

export const toolbarContainerId = "toolbar"

export const emptyQuillRegex = /^<p(\sclass="([a-z]*-[a-z]*)*")?><br><\/p>$/i

export const quillFormats = [
    "bold",
    "italic",
    "underline",
    "bullet",
    "list",
    "align",
    "link",
    "image",
    TagBlot.blotName,
]

export const quillModules = {
    toolbar: {
        container: `#${toolbarContainerId}`,
        handlers: {
            link(value) {
                if (value) {
                    const range = this.quill.getSelection()
                    if (range == null || range.length === 0) return
                    let preview = this.quill.getText(range)
                    if (
                        /^\S+@\S+\.\S+$/.test(preview) &&
                        preview.indexOf("mailto:") !== 0
                    ) {
                        preview = `mailto:${preview}`
                    }
                    const { tooltip } = this.quill.theme
                    tooltip.edit("link", preview)
                } else {
                    this.quill.format("link", false)
                }
            },
        },
    },
    clipboard: {
        matchVisual: false,
        matchers: [
            [
                `.${TagBlot.className}`,
                node =>
                    new Delta().insert({
                        chip: {
                            key: node.getAttribute("data-key"),
                            value: node.getAttribute("data-name"),
                        },
                    }),
            ],
        ],
    },
}
