import React from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import classNames from "classnames"

// @material-ui/core
import CircularProgress from "@material-ui/core/CircularProgress"
import withStyles from "@material-ui/core/styles/withStyles"

import Error from "lib/views/Error"

import styles from "./styles"

const Loading = ({
    classes,
    className,
    loading,
    transparent,
    color,
    error,
    onRetry,
    size,
}) => (
    <div
        className={classNames(
            classes.root,
            className,
            loading && classes.loading,
            transparent && classes.transparent,
            classes[color],
        )}
    >
        {error ? (
            <Error
                message="main:load_component"
                reason="main:no_internet_error"
                onClick={onRetry}
            />
        ) : (
            <CircularProgress
                size={size}
                color="inherit"
                className={classes.loader}
            />
        )}
    </div>
)

Loading.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    /* set loading to false to hide the component without removing it from the DOM */
    loading: PropTypes.bool,
    transparent: PropTypes.bool,
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "textPrimary",
        "textSecondary",
    ]),
    error: PropTypes.bool,
    onRetry: PropTypes.func,
    size: PropTypes.number,
}

Loading.defaultProps = {
    className: null,
    loading: true,
    transparent: false,
    error: false,
    color: "secondary",
    onRetry: null,
    size: 40,
}

export default compose(
    withStyles(styles, { name: "Loading" }),
    React.memo,
)(Loading)
