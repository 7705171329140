import { createSelector } from "reselect"
import get from "lodash/get"
import orderBy from "lodash/orderBy"
import filter from "lodash/filter"

import { createReducer } from "../../../utility"
import { createEntitiesFeatureSelector } from "../helpers"

import * as selectors from "./selectors"
import { initialState, reducers } from "./reducers"
import { reducerName } from "./actionNames"

export * from "./reducers"
export * from "./schema"

// selectors
export const selectFeature = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)
export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)
export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)
export const selectHasFetched = createSelector(
    selectFeature,
    selectors.selectHasFetched,
)
export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)
export const selectSelectedHash = createSelector(
    selectFeature,
    selectors.selectSelectedHash,
)

export const selectFolders = createSelector(
    selectFeature,
    selectors.selectFolders,
)
export const selectUserFolders = createSelector(selectFolders, folders =>
    filter(folders, folder => !folder.isCustom),
)

export const selectOverviewFolders = createSelector(selectFolders, folders => {
    // Hide the custom folders that don't have files
    const filteredFolders = filter(
        folders,
        folder => !folder.isCustom || folder.fileHashes.length,
    )

    return orderBy(filteredFolders, ["isCustom", "name"], ["desc", "asc"])
})
export const selectMoveToFolders = createSelector(selectUserFolders, folders =>
    orderBy(folders, "name"),
)

export const selectLibraryRootHash = createSelector(
    selectFeature,
    selectors.selectLibraryRootHash,
)

export const selectCurrentFolder = createSelector(
    selectData,
    selectSelectedHash,
    selectLibraryRootHash,
    (data, currentHash, rootHash) => get(data, currentHash || rootHash),
)

export const selectSelectedFolder = createSelector(
    selectData,
    selectSelectedHash,
    get,
)

export const reducer = createReducer(reducers, initialState)
