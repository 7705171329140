import { createSelector } from "reselect"

import { createReducer } from "redux/utility"

import { createEntitiesFeatureSelector } from "../helpers"

import * as selectors from "./selectors"
import { reducers, initialState } from "./reducers"
import { reducerName } from "./actionNames"

export * from "./reducers"
export * from "./schema"

export const selectFeature = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)

export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)

export const selectSelectedHash = createSelector(
    selectFeature,
    selectors.selectSelectedHash,
)

export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)

export const selectHasFetched = createSelector(
    selectFeature,
    selectors.selectHasFetched,
)

export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)

export const selectSelectedRelation = createSelector(
    selectFeature,
    selectors.selectSelectedRelation,
)

export const selectRelations = createSelector(
    selectFeature,
    selectors.selectRelations,
)

// Reducer
export const reducer = createReducer(reducers, initialState)
