import React, { Fragment, useMemo } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { withTranslation } from "react-i18next"

import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import InputField, { StandardTextFieldProps } from "@material-ui/core/TextField"
import { Typography } from "@material-ui/core"

import { parseValidationString } from "../../../utils/helpers/validation"

import styles from "./styles"

interface ITextFieldProps extends WithStyles<typeof styles> {
    t: (...args: string[]) => string
    input: {
        value?: string
        onChange: StandardTextFieldProps["onChange"]
        onBlur: StandardTextFieldProps["onBlur"]
        onFocus: StandardTextFieldProps["onFocus"]
    }
    required?: boolean
    defaultValue?: string
    label?: string
    meta: {
        touched?: boolean
        error?: string
    }
    endAdornment?: Node
    startAdornment?: Node
    variant?: "filled" | "outlined" | "standard"
    labelEndAdornment?: string
    disableUnderline?: boolean
    helperText?: string | Node | null
    errorText?: string
    type?: string
    autoFocus?: boolean
    placeholder?: string
    disabled?: boolean
    value?: string
    onChange: StandardTextFieldProps["onChange"]
    onBlur: StandardTextFieldProps["onBlur"]
    onFocus: StandardTextFieldProps["onFocus"]
}

const inputLabelProps = { shrink: true }

const TextField = ({
    t,
    input,
    required,
    defaultValue,
    label,
    meta,
    classes,
    endAdornment,
    startAdornment,
    variant,
    labelEndAdornment,
    disableUnderline,
    helperText,
    errorText,
    type,
    autoFocus,
    placeholder,
    disabled,
    ...formControlProps
}: ITextFieldProps) => {
    const inputClasses = useMemo(() => ({ root: classes.formControl }), [
        classes.formControl,
    ])

    const inputVariant = useMemo(() => {
        switch (variant) {
            case "outlined": {
                return "outlined"
            }
            case "filled": {
                return "filled"
            }
            case "standard":
            default: {
                return "standard"
            }
        }
    }, [variant])

    const inputProps = useMemo(
        () => ({
            classes: {
                root: classes.textField,
            },
            endAdornment,
            startAdornment,
            disableUnderline,
        }),
        [classes.textField, endAdornment, startAdornment, disableUnderline],
    )

    const inputFormHelperTextProps = useMemo(
        () => ({
            classes: { contained: classes.error },
        }),
        [classes.error],
    )

    const inputFormControlProps = useMemo(() => {
        if (input.value || input.onChange) {
            return input
        }
        return formControlProps
    }, [input, formControlProps])

    const { value, onChange, onFocus, onBlur } = inputFormControlProps

    return (
        <Fragment>
            {(label || labelEndAdornment) && (
                <div className={classes.labelContainer}>
                    <Typography className={classes.aboveLabel} variant="body1">
                        {label}
                        {required && <span>&nbsp;*</span>}
                    </Typography>
                    {labelEndAdornment}
                </div>
            )}
            <InputField
                classes={inputClasses}
                InputLabelProps={inputLabelProps}
                InputProps={inputProps}
                autoComplete={label && label.replace("_", "-")}
                value={value || defaultValue || ""}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                error={meta.touched && !!meta.error}
                helperText={
                    (!errorText || (errorText && !meta.touched)) &&
                    ((meta.touched &&
                        meta.error &&
                        t(...parseValidationString(meta && meta.error))) ||
                        helperText)
                }
                FormHelperTextProps={inputFormHelperTextProps}
                type={type}
                autoFocus={autoFocus}
                placeholder={placeholder}
                disabled={disabled}
                variant={inputVariant}
            />
            {meta.touched && errorText && (
                <div className={classes.errorContainer}>
                    <Typography className={classes.error} variant="caption">
                        {errorText}
                    </Typography>
                </div>
            )}
        </Fragment>
    )
}

TextField.propTypes = {
    t: PropTypes.func.isRequired,
    label: PropTypes.string,
    classes: PropTypes.exact({
        labelContainer: PropTypes.string,
        formControl: PropTypes.string,
        aboveLabel: PropTypes.string,
        textField: PropTypes.string,
        error: PropTypes.string,
        errorContainer: PropTypes.string,
    }).isRequired,
    defaultValue: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    }),
    startAdornment: PropTypes.element,
    endAdornment: PropTypes.element,
    labelEndAdornment: PropTypes.element,
    required: PropTypes.bool,
    variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
    disableUnderline: PropTypes.bool,
    helperText: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
    errorText: PropTypes.string,
    type: PropTypes.string,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
}

TextField.defaultProps = {
    defaultValue: null,
    meta: {
        touched: false,
        error: null,
    },
    label: "",
    input: {},
    startAdornment: null,
    endAdornment: null,
    labelEndAdornment: null,
    required: false,
    variant: "outlined",
    disableUnderline: false,
    helperText: null,
    errorText: null,
    type: null,
    autoFocus: false,
    placeholder: null,
    disabled: false,
    value: null,
}

export default compose(
    withStyles(styles, { name: "TextField" }),
    withTranslation(),
    React.memo,
)(TextField)
