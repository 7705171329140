import get from "lodash/get"
import first from "lodash/first"

import { createSelector } from "reselect"
import { createReducer } from "../../../utility"
import { createEntitiesFeatureSelector } from "../helpers"

import { initialState, reducers } from "./reducers"
import { reducerName } from "./actionNames"
import * as selectors from "./selectors"

export * from "./reducers"
export * from "./schema"

// selectors
export const selectFeature = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)
export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)
export const selectSelectedHash = createSelector(
    selectFeature,
    selectors.selectSelectedHash,
)
export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)
export const selectHasFetched = createSelector(
    selectFeature,
    selectors.selectHasFetched,
)
export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)
export const selectWorkspaces = createSelector(
    selectData,
    selectIdentifiers,
    (data, identifiers) => identifiers.map(id => data[id]),
)
export const selectCurrentWorkspace = createSelector(
    selectData,
    selectSelectedHash,
    get,
)
export const selectDefaultWorkspace = createSelector(
    selectWorkspaces,
    workspaces =>
        workspaces.find(workspace => get(workspace, "isDefault")) ||
        first(workspaces),
)

export const reducer = createReducer(reducers, initialState)
