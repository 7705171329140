import get from "lodash/get"
import first from "lodash/first"
import { createSelector } from "reselect"

import {
    userRoles,
    companyRoles,
    roleStates,
    accessTypes,
} from "constants/enums"
import { isPremise } from "constants/internal"

import { unlimitedSubscription } from "lib/utils/helpers/subscriptions"
import { outerJoin } from "lib/utils/helpers/data-sets"

// Selectors
export const selectData = state => state?.data
export const selectIdentifiers = state => state?.identifiers
export const selectSelectedHash = state => state?.selectedHash
export const selectIsLoading = state => state?.isLoading
export const selectHasFetched = state => state?.hasFetched
export const selectLoadingError = state => state?.loadingError
export const selectHasFetchedBillingInfo = state => state?.hasFetchedBillingInfo
export const selectHasCompany = state => !!state?.identifiers?.length

export const selectCurrentCompany = createSelector(
    selectData,
    selectSelectedHash,
    get,
)

export const selectInvitedCompanies = createSelector(
    selectData,
    selectIdentifiers,
    (data, identifiers) => {
        const companies = []

        for (let idx = 0; idx < identifiers?.length; idx += 1) {
            const companyHash = identifiers[idx]
            const company = data[companyHash]

            if (company?.accessType === accessTypes.direct) {
                companies.push(company)
            }
        }

        return companies
    },
)

export const selectInheritedCompanies = createSelector(
    selectData,
    selectIdentifiers,
    (data, identifiers) => {
        const companies = []

        for (let idx = 0; idx < identifiers?.length; idx += 1) {
            const companyHash = identifiers[idx]
            const company = data[companyHash]

            if (company?.accessType === accessTypes.inherited) {
                companies.push(company)
            }
        }

        return companies
    },
)

export const selectCompanies = createSelector(
    selectIdentifiers,
    selectData,
    outerJoin,
)

export const selectCurrentEnvironment = createSelector(
    selectCurrentCompany,
    company => company?.environment,
)

export const selectIsAdmin = createSelector(selectCurrentCompany, company =>
    (company?.roles || []).some(
        ({ role, status }) =>
            status === roleStates.active &&
            (role === userRoles.administrator || role === userRoles.owner),
    ),
)

export const selectIsCompanySubscribed = createSelector(
    selectCurrentCompany,
    company =>
        company?.companyRoleType === companyRoles.partner ||
        company?.companyRoleType === companyRoles.system ||
        isPremise ||
        !!company?.subscriptions?.length,
)

export const selectHasCompanyBillingInfo = createSelector(
    selectCurrentCompany,
    currentCompany => !!currentCompany?.billingInfo,
)

export const selectHasFetchedCompanyBillingInfo = createSelector(
    selectHasFetchedBillingInfo,
    selectSelectedHash,
    (hasFetchedBillingInfo, selectedHash) =>
        !!hasFetchedBillingInfo.includes(selectedHash),
)

export const selectCompanyBillingInfo = createSelector(
    selectCurrentCompany,
    company => company?.billingInfo,
)

export const selectCompanyName = createSelector(
    selectCurrentCompany,
    company => company?.name,
)

export const selectCompanySubscription = createSelector(
    selectCurrentCompany,
    company =>
        isPremise ? unlimitedSubscription : first(company?.subscriptions),
)

export const selectCompanyPlanHash = createSelector(
    selectCompanySubscription,
    subscription => subscription?.planHash,
)
