import { AnyAction, Dispatch } from "redux"
import { Callback, CombinedState } from "redux/reducers/types"
import once from "lodash/once"

import client from "configure/client"
import { getFullDomain } from "lib/utils/helpers/uri"

import {
    hasCredentialMgmt,
    preventAccess,
    setCurrentCredentialData,
} from "lib/utils/helpers/credential-mgmt"

import { createLoadAndNotify as createLoadInvitesAndNotify } from "redux/reducers/entities/userInvites/actions"

import { ThunkDispatch } from "redux-thunk"
import { RequestPayload } from "redux/reducers/entities/types"
import {
    LOGIN,
    LOGOUT,
    SET_REDIRECT,
    REFRESH,
    REFRESHING,
    VALIDATE_EMAIL,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
} from "./actionNames"
import { selectIsSubdomain } from "../environment"
import { selectAuthToken } from "./index"
import { AuthenticationRequestPayload } from "./types"

// Action creators
export function createRedirectUrlAction(url: string) {
    return { type: SET_REDIRECT, payload: url }
}

export function createRefreshingAction() {
    return { type: REFRESHING }
}

export function createRefreshAction(
    authentication: AuthenticationRequestPayload,
) {
    return { type: REFRESH, payload: authentication }
}
export const createLoginAction = (
    formData: { username: string; password: string },
    callback: Callback,
) => ({
    type: LOGIN,
    request: {
        method: "post",
        url: "/editor/Authentication/SignIn",
        data: {
            ...formData,
            DeviceName: getFullDomain(),
            DeviceType: "web",
        },
    },
    payload: formData,

    callback: (
        dispatch: Dispatch<AnyAction>,
        getState: () => CombinedState,
        payload: RequestPayload,
    ) => {
        if (hasCredentialMgmt) {
            setCurrentCredentialData(formData.username)
        }

        if (callback) callback(dispatch, getState, payload)
    },
})

export const logout = () => (
    dispatch: Dispatch,
    getState: () => CombinedState,
) => {
    if (hasCredentialMgmt) preventAccess()

    const isSubdomain = selectIsSubdomain(getState())

    dispatch({
        type: LOGOUT,
        request: {
            method: "POST",
            url: "/editor/User/SignOut",
        },
        payload: { isSubdomain },
    })
}

function executeTokenRefresh(
    refreshToken: string,
    dispatch: ThunkDispatch<CombinedState, void, AnyAction>,
    state: CombinedState,
) {
    const currentToken = selectAuthToken(state)
    const postData = {
        refreshToken,
    }

    dispatch(createRefreshingAction())

    return client
        .request({
            method: "POST",
            url: "/editor/Authentication/RefreshToken",
            data: postData,
            headers: {
                "X-Client-Type": "web",
                "X-Client-Version": process.env.REACT_APP_VERSION,
            },
        })
        .then(result => {
            dispatch(createRefreshAction(result.data))
            dispatch(createLoadInvitesAndNotify())
        })
        .catch(() => {
            if (currentToken !== selectAuthToken(state)) {
                // The token was already refreshed by another
                // request triggered at the same time.
                return
            }

            // Token refresh failed. Log the user out
            dispatch(logout())
        })
}

let refreshFunction:
    | ((
          refreshToken: string,
          dispatch: Dispatch,
          state: CombinedState,
      ) => Promise<void>)
    | null = null

export function tokenRefresh(refreshToken: string) {
    return (dispatch: Dispatch, state: CombinedState) => {
        if (!refreshToken) return

        if (!refreshFunction) {
            // Ensure we only send one token refresh call at a time
            refreshFunction = once(executeTokenRefresh)
        }
        if (refreshFunction) {
            refreshFunction(refreshToken, dispatch, state)
                .then(() => {
                    refreshFunction = null
                })
                .catch(() => {
                    refreshFunction = null
                })
        }
    }
}

export const createResetPasswordAction = (
    { emailAddress }: { emailAddress: string },
    callback: Callback,
) => ({
    type: RESET_PASSWORD,
    request: {
        method: "POST",
        url: "/editor/Authentication/ForgotPassword",
        data: { emailAddress },
    },
    payload: { emailAddress },
    callback,
})

export const createChangePasswordAction = (
    {
        resetKey,
        password,
        passwordConfirmation,
    }: { resetKey: string; password: string; passwordConfirmation: string },
    callback: Callback,
) => ({
    type: CHANGE_PASSWORD,
    request: {
        method: "POST",
        url: "/editor/Authentication/ChangePasswordWithToken",
        data: {
            resetKey,
            password,
            passwordConfirmation,
        },
    },
    callback,
})

export const createValidateEmailAction = (
    activationKey: string,
    callback: Callback,
) => ({
    type: VALIDATE_EMAIL,
    request: {
        method: "POST",
        url: "/editor/UserInvite/ValidateEmailAddress",
        data: {
            activationKey,
        },
    },
    callback,
})
