import { schema, normalize } from "normalizr"

export const themeSchema = new schema.Entity(
    "themes",
    {},
    { idAttribute: "hash" },
)

export default originalData => {
    const {
        entities: { themes: normalizedData },
        result: identifiers,
    } = normalize(originalData, [themeSchema])

    return { data: normalizedData, identifiers } || {}
}
