export enum itemBaseType {
    stepMessageItem = 1, // message
    stepContentItem = 2, // story
    stepTodoItem = 3, // todo
    journeyTopicItem = 4, // topic
    journey = 5,
}

export enum prerequisiteType {
    reached = 1, // When target has reached state s - [Then after n hours]
    notReached = 2, // When target has not reached state s - for n hours then
    inState = 3, // When target is in state s [for x hours]
}

export enum progressState {
    // Default state when there are prerequisites
    locked = 1,
    // Default state when there are no prerequisites, and state after all prerequisites are met.
    unlocked = 2,
    // State after a user has done anything with the related item
    started = 3,
    // State when user has finished the related item
    completed = 4,
    // State when the item will never be unlocked anymore due to impossible conditions
    permaLocked = 5,
}
