import React, { useEffect } from "react"
import PropTypes from "prop-types"
import loadable from "@loadable/component"
import { compose } from "redux"

import { Switch, Route, Redirect } from "react-router-dom"

import withErrorBoundary from "lib/utils/hoc/withErrorBoundary"
import withLanguage from "lib/utils/hoc/withLanguage"
import withToaster from "lib/utils/hoc/withToaster"
import { registerForm } from "constants/uri"

import Loading from "lib/views/Loading/Loading"
import CreateUser from "App/components/CreateUser"
import PolicyAgreementDialog from "App/components/PolicyAgreementDialog"

import connect from "./connect"

const NotFound = loadable(
    () => import(/* webpackChunkName: "not-found" */ "lib/views/NotFound"),
    {
        fallback: (props => <Loading {...props} />)({
            pastDelay: true,
            error: false,
            timedOut: false,
        }),
    },
)
const LoginRedirect = loadable(
    () =>
        import(
            /* webpackChunkName: "login-redirect" */ "App/components/LoginRedirect"
        ),
    {
        fallback: (props => <Loading {...props} />)({
            pastDelay: true,
            error: false,
            timedOut: false,
        }),
    },
)
const NoActiveRoles = loadable(
    () =>
        import(
            /* webpackChunkName: "inactive-user" */ "App/components/NoActiveRoles"
        ),
    {
        fallback: (props => <Loading {...props} />)({
            pastDelay: true,
            error: false,
            timedOut: false,
        }),
    },
)
const RouteLogin = loadable(
    () => import(/* webpackChunkName: "login" */ "App/components/Login"),
    {
        fallback: (props => <Loading {...props} />)({
            pastDelay: true,
            error: false,
            timedOut: false,
        }),
    },
)
const ForgotPassword = loadable(
    () =>
        import(
            /* webpackChunkName: "forgot-password" */ "App/components/ForgotPassword"
        ),
    {
        fallback: (props => <Loading {...props} />)({
            pastDelay: true,
            error: false,
            timedOut: false,
        }),
    },
)
const Roles = loadable(
    () => import(/* webpackChunkName: "roles" */ "App/components/Roles"),
    {
        fallback: (props => <Loading {...props} />)({
            pastDelay: true,
            error: false,
            timedOut: false,
        }),
    },
)
const RoutesRegister = loadable(
    () => import(/* webpackChunkName: "register" */ "App/RoutesRegister"),
    {
        fallback: (props => <Loading {...props} />)({
            pastDelay: true,
            error: false,
            timedOut: false,
        }),
    },
)
const RoutesIntegration = loadable(
    () =>
        import(
            /* webpackChunkName: "integrationsRedirects" */ "App/RoutesIntegration"
        ),
    {
        fallback: (props => <Loading {...props} />)({
            pastDelay: true,
            error: false,
            timedOut: false,
        }),
    },
)
const EnvironmentWrapper = loadable(
    () =>
        import(
            /* webpackChunkName: "environmentWrapper" */ "App/EnvironmentWrapper"
        ),
    {
        fallback: (props => <Loading {...props} />)({
            pastDelay: true,
            error: false,
            timedOut: false,
        }),
    },
)

export const App = ({
    isLoggedIn,
    redirectUrl,
    hasConfirmedEmail,
    hasCompany,
    loadInvites,
    invites,
    hasFetchedInvites,
    isSubscribed,
    hasActiveRoles,
    hasFetchedBillingInfo,
    hasFetchedLoggedInUser,
    isLoadingUserInvites,
    isAdmin,
}) => {
    const hasInvites = hasFetchedInvites && !!invites.length

    useEffect(() => {
        if (isLoggedIn && !hasFetchedInvites) loadInvites()
    }, [isLoggedIn, hasFetchedInvites])

    if (!isLoggedIn) {
        return (
            <Switch>
                <Route exact path="/" component={RouteLogin} />
                <Route exact path="/login" component={RouteLogin} />
                <Route
                    exact
                    path="/forgot-password/:resetToken?"
                    component={ForgotPassword}
                />
                <Route
                    path="/register/:layer?/:activationKey?"
                    component={RoutesRegister}
                />
                <Route component={LoginRedirect} />
            </Switch>
        )
    }

    if (
        hasConfirmedEmail &&
        hasFetchedLoggedInUser &&
        hasCompany &&
        !hasActiveRoles &&
        !hasInvites &&
        !isLoadingUserInvites
    ) {
        return <Route component={NoActiveRoles} />
    }

    if (
        !hasConfirmedEmail ||
        (!hasCompany && !hasInvites) ||
        (hasCompany && hasFetchedBillingInfo && !isSubscribed && isAdmin)
    ) {
        return (
            <React.Fragment>
                <Switch>
                    <Route
                        path="/register/:layer?/:activationKey?"
                        component={RoutesRegister}
                    />
                    <Route render={() => <Redirect to="/register" />} />
                </Switch>

                <PolicyAgreementDialog />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Switch>
                {/* Redirect to the users previous location after login */}
                <Route
                    exact
                    path="/login"
                    render={() => <Redirect to={redirectUrl} />}
                />
                <Route exact path="/roles" component={Roles} />
                <Route
                    path={`/register/${registerForm.user}/:inviteHash?`}
                    component={CreateUser}
                />
                <Route
                    path="/editor/settings/integration/:integration?"
                    component={RoutesIntegration}
                />

                <Route path="/:environment?" component={EnvironmentWrapper} />

                {/* Default error page */}
                <Route render={() => <NotFound withHeader />} />
            </Switch>

            <PolicyAgreementDialog />
        </React.Fragment>
    )
}

App.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    loadInvites: PropTypes.func.isRequired,
    invites: PropTypes.array,
    redirectUrl: PropTypes.string,
    hasConfirmedEmail: PropTypes.bool,
    hasCompany: PropTypes.bool,
    hasFetchedInvites: PropTypes.bool,
    isSubscribed: PropTypes.bool,
    hasFetchedBillingInfo: PropTypes.bool,
    hasActiveRoles: PropTypes.bool,
    hasFetchedLoggedInUser: PropTypes.bool,
    isLoadingUserInvites: PropTypes.bool,
    isAdmin: PropTypes.bool,
}

App.defaultProps = {
    redirectUrl: "/",
    hasConfirmedEmail: false,
    invites: null,
    hasCompany: true,
    hasFetchedInvites: false,
    isSubscribed: false,
    hasFetchedBillingInfo: false,
    hasActiveRoles: false,
    hasFetchedLoggedInUser: false,
    isLoadingUserInvites: false,
    isAdmin: false,
}

// eslint-disable-next-line import/no-unused-modules
export default compose(
    withLanguage(),
    withErrorBoundary(),
    withToaster(),
    connect,
    React.memo,
)(App)
