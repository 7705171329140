import { darken, fade } from "@material-ui/core/styles/colorManipulator"

// Appical's theme is passed as parameter to this function
export default input => ({
    MuiAppBar: {
        colorPrimary: {
            borderBottom: `1px solid ${input.primaryDark}`,
        },
    },

    MuiPickersCalendarHeader: {
        switchHeader: {
            height: "45px",
            margin: `0 ${input.spacing.gap}`,
        },

        dayLabel: {
            ...input.typography.caption,
            color: fade(input.primaryText, 0.5),
            margin: 0,
            textTransform: "uppercase",
            width: "58px",
        },

        daysHeader: {
            margin: `0 ${input.spacing.gap}`,
        },
        transitionContainer: {
            "& > *": {
                ...input.typography.h5,
            },
        },
        iconButton: {
            "& > *": {
                backgroundColor: "transparent",
            },
            "&:hover": {
                backgroundColor: input.hover,
            },
        },
    },

    MuiPickersDay: {
        day: {
            ...input.typography.body1,
            width: "58px",
            height: "24px",
            margin: 0,
            borderRadius: input.sizes.smallBorderRadius,

            "&:hover": {
                backgroundColor: input.hover,
                color: input.contrast,
            },
        },

        current: {
            ...input.typography.body1,
        },

        isSelected: {
            backgroundColor: input.hover,
            color: input.contrast,
        },

        isDisabled: {
            color: fade(input.primaryText, 0.3),
        },
    },

    MuiPickersCalendar: {
        week: {
            marginBottom: input.spacing.gap,
        },
        transitionContainer: {
            minHeight: "185px",
        },
    },

    MuiInput: {
        underline: {
            "&::before, &::after": {
                borderBottom: `1px solid ${fade(input.contrast, 0.7)}`,
            },
        },
    },
    MuiOutlinedInput: {
        root: {
            fontSize: "14px",

            "& > $notchedOutline": {
                border: `1px solid ${input.border}`,
                borderRadius: "4px",

                "&:hover": {
                    borderColor: fade(input.contrast, 0.5),
                },
            },

            "&$focused:not($error) > $notchedOutline": {
                borderColor: input.contrast,
            },

            "&$focused > $notchedOutline": {
                borderWidth: "1px",
            },

            "&:hover:not($disabled):not($focused):not($error) > $notchedOutline": {
                borderColor: fade(input.contrast, 0.5),
            },
        },

        input: {
            padding: `${input.spacing.gap} ${input.spacing.smallest}`,
        },

        notchedOutline: {
            borderColor: input.hover,
        },
    },

    MuiInputBase: {
        root: {
            "&, &$focused, &$disabled": {
                color: input.primaryText,
            },
        },
    },
    MuiInputLabel: {
        root: {
            color: input.label,
            backgroundImage: "none !important",

            "&$focused": {
                color: input.primaryText,
            },
            "&$disabled": {
                color: fade(input.primaryText, 0.5),
            },
        },
    },
    MuiFormLabel: {
        root: {
            color: input.label,

            "&$focused": {
                color: input.primaryText,
            },
            "&$disabled": {
                color: fade(input.primaryText, 0.5),
            },
        },
    },
    MuiIconButton: {
        root: {
            width: "38px",
            height: "38px",
            padding: "4px",
            overflow: "hidden",
            color: input.primaryText,

            "&:focused, &:hover, &:active": {
                color: input.primaryText,
            },

            "&$Mui-disabled": {
                width: "38px",
                height: "38px",
                padding: "4px",
                overflow: "hidden",
                color: fade(input.primaryText, 0.5),
            },
        },
    },

    MuiMenuItem: {
        root: {
            color: input.primaryText,
            transition: "all 300ms ease",

            "& svg": {
                transition: "150ms",
            },

            "&:hover": {
                backgroundColor: input.hover,
                color: input.contrast,

                "& svg": {
                    fill: input.contrast,
                },
            },
            "&:focus": {
                backgroundColor: input.hover,
                color: input.contrast,

                "& svg": {
                    fill: input.contrast,
                },
            },
            "&:active": {
                backgroundColor: fade(input.contrast, 0.2),
                color: input.contrast,

                "& svg": {
                    fill: input.contrast,
                },
            },

            "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: input.hover,
                color: input.contrast,
            },
        },
    },
    MuiPaper: {
        root: {
            backgroundColor: input.primary,
            color: input.primaryText,
        },

        rounded: {
            borderRadius: input.sizes.borderRadius,
        },

        elevation8: {
            boxShadow: `0 8px 16px ${fade(input.primaryText, 0.1)}`,
        },
    },
    MuiTab: {
        textColorSecondary: {
            color: fade(input.primaryText, 0.5),

            "&$disabled": {
                color: fade(input.primaryText, 0.3),
            },
        },
    },
    MuiTypography: {
        colorTextSecondary: {
            color: input.secondaryText,
        },
    },
    MuiSelect: {
        outlined: {
            borderRadius: input.sizes.borderRadius,
        },
        icon: {
            color: input.primaryText,
        },

        select: {
            borderColor: input.border,

            "&:focus": {
                backgroundColor: input.hover,
                color: input.contrast,
            },
        },
    },
    MuiPrivateNotchedOutline: {
        root: {
            borderRadius: input.sizes.borderRadius,
        },
    },
    MuiTableSortLabel: {
        root: {
            color: input.label,

            "&:hover": {
                color: input.primaryText,
            },
            "&:focus": {
                color: input.primaryText,
            },
            "&:active": {
                color: input.primaryText,
            },
            "&$active": {
                color: input.primaryText,
            },
        },
    },
    MuiTableRow: {
        root: {
            backgroundColor: "transparent",
            color: input.primaryText,
        },
    },
    MuiTablePagination: {
        root: {
            color: input.primaryText,
        },
    },
    MuiListItem: {
        root: {
            color: input.primaryText,

            "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: input.hover,
                color: input.contrast,
            },
        },
    },
    MuiListItemIcon: {
        root: {
            color: input.primaryText,
        },
    },
    MuiDivider: {
        root: {
            backgroundColor: fade(input.primaryText, 0.12),
        },
    },
    MuiButton: {
        root: {
            padding: "12px 24px",
        },

        sizeSmall: {
            padding: `${input.spacing.gap} ${input.spacing.smallest}`,
        },

        endIcon: {
            lineHeight: "1em",
            marginRight: 0,
        },

        contained: {
            borderColor: "transparent !important",
            boxShadow: "none",
            color: input.button || input.primaryText,

            "&:active": {
                boxShadow: "none",
            },

            "&:hover": {
                boxShadow: "none",
            },

            "&:disabled": {
                backgroundColor: input.disable,
                color: fade(input.contrastText, 0.75),
            },
        },

        containedSecondary: {
            "&:hover": {
                backgroundColor: darken(input.contrast, 0.25),
            },
        },

        outlinedSizeSmall: {
            padding: `${input.spacing.gap} ${input.spacing.smallest}`,
        },

        outlined: {
            padding: "12px 24px",
            borderColor: fade(input.primaryText, 0.5),
            backgroundColor: "transparent",
            color: "inherit",

            "&:disabled": {
                borderColor: fade(input.primaryText, 0.2),
                color: fade(input.primaryText, 0.5),
            },

            "&:hover:active:enabled": {
                borderColor: input.primaryText,
                backgroundColor: "unset",
                color: "unset",
            },
        },

        text: {
            "&&": {
                minWidth: 0,
                padding: 0,
                justifyContent: "flex-start",
            },
        },

        textSecondary: {
            "&$text:hover": {
                backgroundColor: "transparent",
                color: darken(input.contrast, 0.25),
            },
        },
    },
    MuiDialogTitle: {
        root: {
            color: input.primaryText,
        },
    },
    MuiDialogContentText: {
        root: {
            color: input.primaryText,
        },
    },
    MuiChip: {
        label: {
            ...input.typography.body2,
        },
    },

    MuiStepIcon: {
        root: {
            color: fade(input.primary, 0),
            border: `1px solid ${input.border}`,
            borderRadius: "50%",

            "&$active": {
                color: input.contrast,
            },

            "&$completed": {
                color: input.success,
            },
        },

        text: {
            fill: input.label,
            fontSize: "12px",
            fontWeight: 600,
        },

        active: {
            color: input.contrast,
            border: "none",

            "& $text": {
                fill: input.contrastText,
            },
        },

        completed: {
            color: input.success,
            border: "none",
        },
    },

    MuiStepConnector: {
        line: {
            borderColor: input.border,
        },
    },

    MuiStepLabel: {
        label: {
            color: input.label,
            fontSize: "12px",
            fontWeight: 600,
            letterSpacing: 0,

            "&$active": {
                color: input.contrast,
            },

            "&$completed": {
                color: input.label,
            },

            "&$alternativeLabel": {
                marginTop: "4px",
            },
        },

        active: {
            color: input.contrast,
        },

        completed: {
            color: input.label,
        },
    },

    MuiFormControlLabel: {
        label: {
            color: input.label,
        },
    },
})
