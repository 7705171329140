import React from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import get from "lodash/get"

import { TransitionGroup } from "react-transition-group"

import withStyles from "@material-ui/core/styles/withStyles"
import Collapse from "@material-ui/core/Collapse"

import Toast from "../Toast"

import styles from "./styles"
import connect from "./connect"

export class Toaster extends React.PureComponent {
    // eslint-disable-next-line react/destructuring-assignment
    dismissTimer = this.props.timeout

    dismisser = null

    componentDidMount() {
        const { toasts } = this.props

        if (toasts.length) {
            this.dismisser = setInterval(this.dismiss, this.dismissTimer)
        }
    }

    componentDidUpdate() {
        const { toasts } = this.props

        if (!this.dismisser && toasts.length) {
            this.dismisser = setInterval(this.dismiss, this.dismissTimer)
        }
    }

    componentWillUnmount() {
        this.clearDismissInterval()
    }

    dismiss = () => {
        const { toasts, dismiss } = this.props

        const nextMessageId = get(toasts, "[0].identifier")

        if (nextMessageId) {
            dismiss(nextMessageId)
        }

        if (toasts.length <= 1) this.clearDismissInterval()
    }

    clearDismissInterval = () => {
        if (this.dismisser) {
            clearInterval(this.dismisser)
            this.dismisser = null
        }
    }

    render() {
        const { classes, toasts, dismiss } = this.props

        return (
            <TransitionGroup className={classes.root} appear>
                {toasts.map(toast => (
                    <Collapse
                        in
                        key={toast.identifier}
                        className={classes.animationWrapper}
                    >
                        <Toast
                            type={toast.toastType}
                            message={toast.toastCode}
                            title={toast.title}
                            interpolations={toast.interpolations}
                            canClose={get(toast, "canClose", true)}
                            onClose={() => dismiss(toast.identifier)}
                        />
                    </Collapse>
                ))}
            </TransitionGroup>
        )
    }
}

Toaster.propTypes = {
    classes: PropTypes.object.isRequired,
    dismiss: PropTypes.func.isRequired,
    toasts: PropTypes.array,
    timeout: PropTypes.number,
}

Toaster.defaultProps = {
    toasts: [],
    timeout: 3000,
}

export default compose(
    withStyles(styles, { name: "Toaster" }),
    connect,
)(Toaster)
