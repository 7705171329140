import React from "react"
import PropTypes from "prop-types"
import * as Sentry from "@sentry/browser"
import { compose } from "redux"
import getDisplayName from "@material-ui/utils/getDisplayName"
import hoistNonReactStatics from "hoist-non-react-statics"

import { isDevelopment } from "../../../../constants/internal"
import withEventTracker from "../withEventTracker"

import ErrorScreen from "../../../views/ErrorScreen"

import connect from "./connect"

const withErrorBoundary = ({ stripped = false } = {}) => WrappedComponent => {
    class WithErrorBoundary extends React.PureComponent {
        state = {
            error: null,
        }

        componentDidCatch(error, errorInfo) {
            const { trackEvent, log } = this.props

            log({ message: error.message, exception: error.name })

            this.setState({ error })
            trackEvent("error", "encounteredErrorScreen")

            if (process.env.REACT_APP_ENV === "prod") {
                Sentry.withScope(scope => {
                    Object.keys(errorInfo).forEach(key =>
                        scope.setExtra(key, errorInfo[key]),
                    )
                    Sentry.captureException(error)
                })
            }
        }

        render() {
            const { error } = this.state
            const { trackEvent, log, ...componentProps } = this.props

            return error ? (
                <ErrorScreen />
            ) : (
                <WrappedComponent {...componentProps} />
            )
        }
    }

    WithErrorBoundary.propTypes = {
        trackEvent: PropTypes.func.isRequired,
        log: PropTypes.func.isRequired,
        children: PropTypes.node,
    }

    WithErrorBoundary.defaultProps = {
        children: null,
    }

    if (isDevelopment) {
        WithErrorBoundary.displayName = `WithErrorBoundary(${getDisplayName(
            WrappedComponent,
        )})`
    }

    hoistNonReactStatics(WithErrorBoundary, WrappedComponent)

    return stripped
        ? WithErrorBoundary
        : compose(withEventTracker(), connect)(WithErrorBoundary)
}

export default withErrorBoundary
