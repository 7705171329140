import { SELECT } from "redux/utility"
import { ERROR, LOADED, LOADING } from "redux/middleware/actionNames"

import { LOGOUT } from "redux/reducers/core/authentication/actionNames"
import { COMPANY } from "redux/reducers/entities/companies/actionNames"

import normalize from "./schema"
import { RELATIONS } from "./actionNames"

// required variables
export const initialState = {
    /* Holds the normalized relations redux object */
    data: {},
    /* Holds the identifiers for the relations */
    identifiers: [],
    /* Indicates whether or not relations have been fetched */
    hasFetched: false,
    /* Indicates whether or not a relations request is loading  */
    isLoading: false,
    /* Holds a relations request error */
    loadingError: null,
    /* Holds the hash of the selected relation */
    selectedHash: null,
}

export const reducers = {
    [RELATIONS + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [RELATIONS + LOADED](state, { result: importedUsers }) {
        const [identifiers, data] = normalize(importedUsers)

        return {
            ...state,
            data,
            identifiers,
            isLoading: false,
            hasFetched: true,
            loadingError: null,
        }
    },

    [RELATIONS + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError,
        }
    },

    // Reset reducers
    [COMPANY + SELECT]() {
        return initialState
    },

    [LOGOUT + LOADED]() {
        return initialState
    },
}
