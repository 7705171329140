import has from "lodash/has"
import some from "lodash/some"

import { inviteStatus, userJourneyStatus } from "constants/enums"
import { userType } from "constants/internal"
import { isRoleActive } from "redux/reducers/entities/users/helpers"

export const mapEnvironment = (companies = []) =>
    companies.map(company => ({
        ...company,
        environment: company.environment.toLowerCase(),
    }))

export const IsJourneyInviteActive = ({
    userJourneyState,
    inviteState,
    type,
}) =>
    type === userType.talent &&
    (userJourneyState === userJourneyStatus.active ||
        userJourneyState === userJourneyStatus.draft) &&
    (inviteState === inviteStatus.accepted ||
        inviteState === inviteStatus.pending)

/**
 * @name hasActiveRoles
 * @description checkes whether the user any valid roles in a company
 * @param {object} company | Company object
 * @returns {boolean}
 */
export function hasActiveRoles(company) {
    if (!has(company, "roles") || !company) return false

    const { activeJourneyCount } = company

    return some(company.roles, role => isRoleActive(role, activeJourneyCount))
}
