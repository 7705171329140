const styles = theme => ({
    base: {
        position: "relative",
        display: "flex",
        "align-items": "center",
        "flex-direction": "row",
        flex: "1 1 auto",
        width: "100%",
        height: "auto",
        overflow: "hidden",
        "background-color": theme?.palette?.primary?.contrastText,
        color: theme?.palette?.primary?.main,
        borderRadius: theme?.appicalTheme?.sizes?.borderRadius,
    },

    root: {
        extend: "base",
        maxWidth: "500px",
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
        margin: `calc(${theme.spacing(3)}px / 2) auto`,
        boxShadow: `0 8px 16px ${theme?.appicalTheme?.black10}`,
        borderLeft: `4px solid ${theme?.appicalTheme?.white}`,
    },

    content: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflow: "hidden",
    },

    error: {
        extend: "base",
        borderLeft: `8px solid ${theme?.palette.error.main}`,
    },

    warning: {
        extend: "base",
        borderLeft: `8px solid ${theme?.palette?.warning?.main}`,
    },

    success: {
        extend: "base",
        borderLeft: `8px solid ${theme?.palette?.success?.main}`,
    },

    info: {
        extend: "base",
        borderLeft: `8px solid ${theme?.palette?.info?.main}`,
    },

    title: {
        fontWeight: "600",
    },

    message: {
        display: "box",
        textOverflow: "ellipsis",
        overflow: "hidden",
        lineClamp: "4",
        whiteSpace: "pre-wrap",
        boxOrient: "vertical",
    },

    closeButton: {
        position: "absolute",
        top: theme.spacing(),
        right: theme.spacing(),
        backgroundColor: "transparent !important",
    },
})

export default styles
