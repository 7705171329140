import { createActionName } from "redux/utility"

export const reducerName = "journeySteps"

export const JOURNEY_STEP = createActionName(reducerName, "JOURNEY_STEP")
export const JOURNEY_STEPS = createActionName(reducerName, "JOURNEY_STEPS")

export const ADD_MESSAGE = "_ADD_MESSAGE"
export const ADD_CONTENT = `${JOURNEY_STEP}_ADD_CONTENT`
export const INTRODUCTION = `${JOURNEY_STEP}_INTRODUCTION`
export const MOVE_CONTENT = `${JOURNEY_STEP}_MOVE_CONTENT`

export const DELETE_CONTENT = `${JOURNEY_STEP}_DELETE_CONTENT`
