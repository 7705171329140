import { schema, normalize } from "normalizr"

export const fileSchema = new schema.Entity(
    "files",
    {},
    { idAttribute: "hash" },
)

export default originalData => {
    const {
        entities: { files: normalizedData },
    } = normalize(originalData, [fileSchema])

    return normalizedData || {}
}
