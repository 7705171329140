import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import moment from "moment/moment"
import i18nResources from "../l10n"

export const ENGLISH = "en"
export const DUTCH = "nl"
export const SPANISH = "es"

const configureMomentJS = () => {
    // Localize Moment JS
    const locale = process.env.REACT_APP_DEFAUT_LANG || ENGLISH
    moment.locale(locale)

    // Set up moment custom text
    moment.updateLocale(locale, {
        relativeTime: {
            future: "%s left",
            past: "0 days remaining",
        },
    })
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            lng: process.env.REACT_APP_DEFAUT_LANG || ENGLISH,
            resources: i18nResources,
            fallbackLng: ENGLISH,
            debug: false,
            interpolation: {
                format(value, format) {
                    if (value instanceof Date) {
                        return moment(value).format(format)
                    }

                    return value
                },
            },
        },
        configureMomentJS,
    )

export default i18n
