import React, { useEffect } from "react"
import PropTypes from "prop-types"
import getDisplayName from "@material-ui/utils/getDisplayName"
import hoistNonReactStatics from "hoist-non-react-statics"

import { isDevelopment } from "constants/internal"
import { languages, serverLanguagesMap } from "constants/translations"
import i18n, { ENGLISH } from "configure/i18n"

import connect from "./connect"

const withLanguage = ({ stripped = false } = {}) => WrappedComponent => {
    function WithLanguage({ currentLanguage, ...props }) {
        useEffect(() => {
            i18n.changeLanguage(serverLanguagesMap[currentLanguage] || ENGLISH)
        }, [currentLanguage])

        return <WrappedComponent {...props} />
    }

    WithLanguage.propTypes = {
        currentLanguage: PropTypes.oneOf(languages).isRequired,
    }
    WithLanguage.defaultProps = {}

    if (isDevelopment) {
        WithLanguage.displayName = `WithLanguage(${getDisplayName(
            WrappedComponent,
        )})`
    }

    hoistNonReactStatics(WithLanguage, WrappedComponent)

    return stripped ? WithLanguage : connect(WithLanguage)
}

export default withLanguage
