import get from "lodash/get"
import { createSelector } from "reselect"

import { outerJoin } from "lib/utils/helpers/data-sets"

export const selectData = state => get(state, "data")
export const selectIdentifiers = state => get(state, "identifiers")
export const selectIsLoading = state => get(state, "isLoading")
export const selectTalentIdentifiers = state => get(state, "talentIdentifiers")
export const selectHasFetchedTalent = state => get(state, "hasFetchedTalent")
export const selectHasFetched = state => get(state, "hasFetched")
export const selectLoadingError = state => get(state, "loadingError")
export const selectSelectedHash = state => get(state, "selectedHash")

export const selectJourneys = createSelector(
    selectIdentifiers,
    selectData,
    outerJoin,
)
