import { AGREE } from "redux/utility"

import { selectNotAgreedPolicy } from "./selectors"
import { POLICY } from "./actionNames"

export const createFetchAllPolicies = () => dispatch => {
    dispatch({
        type: POLICY,
        request: {
            method: "GET",
            url: "editor/Policy/List/All",
        },
    })
}

export const createFetchPoliciesByRegion = region => dispatch => {
    dispatch({
        type: POLICY,
        request: {
            method: "GET",
            url: `editor/Policy/List/Region/${region}`,
        },
    })
}

export const createUserAgreeToPolicies = (policyIds, callback) => ({
    type: POLICY + AGREE,
    request: {
        method: "POST",
        url: "/editor/User/AgreeToPolicies",
        data: {
            policyIds,
        },
    },
    payload: {
        policyIds,
    },
    callback(dispatch, getState) {
        const state = getState()
        const allPoliciesAgreed = !selectNotAgreedPolicy(state)

        if (allPoliciesAgreed) {
            window.location.reload()
        }

        if (callback) {
            callback()
        }
    },
})
