const styles = theme => ({
    chip: {
        height: "auto",
        backgroundColor: theme?.palette?.black10,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    },

    label: {
        fontSize: "10px",
        fontWeight: "bold",
        color: theme?.palette?.primary?.contrastText,
        padding: 0,
    },

    primary: {
        backgroundColor: theme?.palette?.primary?.main,

        "& $label": {
            color: theme?.palette?.primary?.contrastText,
        },
    },

    secondary: {
        backgroundColor: theme?.palette?.secondary?.main,

        "& $label": {
            color: theme?.palette?.secondary?.contrastText,
        },
    },

    contrast: {
        backgroundColor: theme?.palette?.secondary?.main,

        "& $label": {
            color: theme?.palette?.secondary?.contrastText,
        },
    },

    info: {
        backgroundColor: theme?.palette?.info?.main,

        "& $label": {
            color: theme?.palette?.info?.contrastText,
        },
    },

    warning: {
        backgroundColor: theme?.palette?.warning?.main,

        "& $label": {
            color: theme?.palette?.warning?.contrastText,
        },
    },

    success: {
        backgroundColor: theme?.palette?.success?.main,

        "& $label": {
            color: theme?.palette?.success?.contrastText,
        },
    },

    error: {
        backgroundColor: theme?.palette?.error?.main,

        "& $label": {
            color: theme?.palette?.error?.contrastText,
        },
    },

    pink: {
        backgroundColor: "#ff839b",

        "& $label": {
            color: theme?.palette?.primary?.main,
        },
    },

    orange: {
        backgroundColor: "#ff8a73",

        "& $label": {
            color: theme?.palette?.primary?.main,
        },
    },

    green: {
        backgroundColor: theme?.appicalTheme?.green,

        "& $label": {
            color: theme?.palette?.primary?.main,
        },
    },

    purple: {
        backgroundColor: "#8395ff",

        "& $label": {
            color: theme?.palette?.primary?.main,
        },
    },

    // Class for override from parent components
    deleteIcon: {},
})

export default styles
