import { connect } from "react-redux"
import { push as navigate } from "connected-react-router"
import {
    createFetchPoliciesByRegion as fetchPoliciesByRegion,
    selectIsPoliciesLoading,
    selectPrivacyPolicy,
    selectTermsOfServicePolicy,
} from "redux/reducers/entities/policy"

import {
    createCreateWithInviteAction as registerWithInvite,
    createLoadUserByInviteAction as loadUserWithInvite,
} from "redux/reducers/entities/users/actions"
import {
    selectIsLoading as selectIsLoadingUser,
    selectLoggedInUser,
    selectHasFetchedUser,
    selectLoadingError as selectLoadingUserError,
} from "redux/reducers/entities/users"

import { selectIsLoggedIn } from "redux/reducers/core/authentication"

import { selectActiveName } from "redux/reducers/core/environment"
import { selectCurrentCompanyName } from "redux/reducers/entities/companies"

const mapDispatchToProps = {
    navigate,
    registerWithInvite,
    loadUserWithInvite,
    fetchPoliciesByRegion,
}

const mapStateToProps = state => ({
    isLoading: selectIsLoadingUser(state),
    hasFetched: selectHasFetchedUser(state),
    error: selectLoadingUserError(state),
    user: selectLoggedInUser(state),
    isLoggedIn: selectIsLoggedIn(state),
    activeName: selectActiveName(state),
    companyName: selectCurrentCompanyName(state),
    termsOfServicePolicy: selectTermsOfServicePolicy(state),
    privacyPolicy: selectPrivacyPolicy(state),
    isPolicyLoading: selectIsPoliciesLoading(state),
})

export default connect(mapStateToProps, mapDispatchToProps)
