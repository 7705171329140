import React from "react"
import hoistNonReactStatics from "hoist-non-react-statics"
import getDisplayName from "@material-ui/utils/getDisplayName"

import trackEvent from "analytics/eventTracker"
import { isDevelopment } from "constants/internal"

const withEventTracker = () => WrappedComponent => {
    function WithEventTracker({ ...props }) {
        return <WrappedComponent trackEvent={trackEvent} {...props} />
    }

    if (isDevelopment) {
        WithEventTracker.displayName = `WithEventTracker(${getDisplayName(
            WrappedComponent,
        )})`
    }

    hoistNonReactStatics(WithEventTracker, WrappedComponent)

    return WithEventTracker
}

export default withEventTracker
