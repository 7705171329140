import { schema, normalize } from "normalizr"

export const invitesSchema = new schema.Entity(
    "invites",
    {},
    {
        idAttribute: ({ inviteHash, userRoleType }) =>
            inviteHash || userRoleType,
        processStrategy: invite =>
            !invite.inviteHash
                ? {
                      ...invite,
                      inviteHash: invite.userRoleType,
                  }
                : invite,
    },
)

export default originalData => {
    const {
        result: keys,
        entities: { invites: normalizedData },
    } = normalize(originalData, [invitesSchema])

    return [keys || [], normalizedData || {}]
}
