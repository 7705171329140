import React from "react"
import PropTypes from "prop-types"

import {
    createGenerateClassName,
    StylesProvider,
    ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"

import configureJss from "../configure/Jss"

const jssWrapper = configureJss()
const generateClassName = createGenerateClassName()

const ThemeProvider = ({ children, theme }) => (
    <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <StylesProvider
                jss={jssWrapper}
                generateClassName={generateClassName}
            >
                {children}
            </StylesProvider>
        </MuiPickersUtilsProvider>
    </MuiThemeProvider>
)

ThemeProvider.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object,
}

ThemeProvider.defaultProps = {
    theme: {},
    children: null,
}

export default React.memo(ThemeProvider)
