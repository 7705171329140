import React from "react"
import PropTypes from "prop-types"
import { lighten, withTheme } from "@material-ui/core"

const ratio = 1.045

const InviteExpired = ({ theme, color, size, ...props }) => (
    <svg
        viewBox={`0 0 ${ratio * size} ${size}`}
        width={ratio * size}
        height={size}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>InviteExpired</title>
        <g
            id="Invite-expired"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Log-in-|-Expired-invite-1.0"
                transform="translate(-626.000000, -180.000000)"
            >
                <g
                    id="Illustr-invite"
                    transform="translate(626.000000, 180.000000)"
                >
                    <path
                        d="M52.3359375,15.5 C53.3454351,15.5 55.4504057,16.7881124 58.6689926,19.2193186 C63.2323219,22.6662955 75.3715852,33.593872 95.0889629,51.9992667 C96.2032884,53.0394483 96.8359375,54.4954476 96.8359375,56.019816 L96.8359375,56.019816 L96.8359375,91 C96.8359375,92.5187831 96.220329,93.8937831 95.2250248,94.8890873 C94.2297206,95.8843915 92.8547206,96.5 91.3359375,96.5 L91.3359375,96.5 L14.3359375,96.5 C12.8171544,96.5 11.4421544,95.8843915 10.4468502,94.8890873 C9.45154597,93.8937831 8.8359375,92.5187831 8.8359375,91 L8.8359375,91 L8.8359375,57.1164738 C8.83594285,54.9183892 9.80020582,52.8309502 11.4738289,51.4059722 C20.7757961,43.4859702 26.9145908,38.0128368 29.8915246,34.9879228 C43.102659,21.5638607 50.0185311,15.5 52.3359375,15.5 Z"
                        id="Rectangle"
                        stroke={theme?.palette?.[color]?.main}
                        fill={lighten(theme?.palette?.[color]?.main, 0.6)}
                        fillRule="nonzero"
                    />
                    <path
                        d="M81.3359375,23.5 C82.8547206,23.5 84.2297206,24.1156085 85.2250248,25.1109127 C86.220329,26.1062169 86.8359375,27.4812169 86.8359375,29 L86.8359375,29 L86.8359375,88.5 L17.8359375,88.5 L17.8359375,29 C17.8359375,27.4812169 18.451546,26.1062169 19.4468502,25.1109127 C20.4421544,24.1156085 21.8171544,23.5 23.3359375,23.5 L23.3359375,23.5 Z"
                        id="Rectangle"
                        stroke={theme?.palette?.[color]?.main}
                        fill={theme?.palette?.[color]?.main}
                        fillRule="nonzero"
                    />
                    <ellipse
                        id="Oval"
                        fill={theme?.palette?.[color]?.main}
                        cx="55"
                        cy="99.8759766"
                        rx="55"
                        ry="10"
                    />
                    <g
                        id="img-alert"
                        transform="translate(72.335937, 0.000000)"
                    >
                        <path
                            d="M22.8,0.5 C28.2473642,0.5 33.1254006,2.73349157 36.4078329,6.34056002 C39.594643,9.84254918 41.2725809,14.6388104 40.5051806,19.9282103 C39.6891569,25.552753 37.3592546,30.6286729 33.7829791,34.2049484 C30.4999575,37.48797 26.1649915,39.5 21,39.5 C18.1582795,39.5 15.3508577,38.9375781 12.7846611,37.9005236 L12.7846611,37.9005236 L2.16170745,41.1248669 L6.70130293,34.1082015 C2.93948275,30.6717582 0.72001138,25.7880114 1.49546894,20.0671605 C2.26917183,14.3592544 4.88623778,9.47346959 8.66030387,6.01102364 C12.4448649,2.53894929 17.3921357,0.5 22.8,0.5 Z"
                            id="Combined-Shape"
                            stroke={theme?.palette?.[color]?.main}
                            fill={theme?.appicalTheme?.mainBg}
                            fillRule="nonzero"
                        />
                        <g
                            id="history-24px"
                            transform="translate(6.000000, 6.000000)"
                        >
                            <polygon id="Path" points="0 0 28 0 28 28 0 28" />
                            <path
                                d="M15.4,5.6 C10.7613333,5.6 7,9.36133333 7,14 L4.2,14 L7.83066667,17.6306667 L7.896,17.7613333 L11.6666667,14 L8.86666667,14 C8.86666667,10.388 11.788,7.46666667 15.4,7.46666667 C19.012,7.46666667 21.9333333,10.388 21.9333333,14 C21.9333333,17.612 19.012,20.5333333 15.4,20.5333333 C13.5986667,20.5333333 11.9653333,19.796 10.7893333,18.6106667 L9.464,19.936 C10.9853333,21.4573333 13.076,22.4 15.4,22.4 C20.0386667,22.4 23.8,18.6386667 23.8,14 C23.8,9.36133333 20.0386667,5.6 15.4,5.6 Z M14.4666667,10.2666667 L14.4666667,14.9333333 L18.4613333,17.304 L19.1333333,16.1746667 L15.8666667,14.2333333 L15.8666667,10.2666667 L14.4666667,10.2666667 Z"
                                id="Shape"
                                fill={theme?.palette?.[color]?.main}
                                fillRule="nonzero"
                            />
                        </g>
                    </g>
                    <path
                        d="M8.3359375,56 C36.0627976,72.6666667 50.896131,81 52.8359375,81 C55.8502312,81 65.9596614,77.8457542 77.1206614,68.9032993 C80.7308455,66.0107352 87.4692708,61.7096354 97.3359375,56 C96.8808268,72.2220275 96.8808268,81.6562723 97.3359375,84.3027344 C98.3808293,90.3787655 98.6813128,95.7968967 98.2373879,100.557128 C97.9497355,103.641606 95.3611713,106 92.2633095,106 L14.6001859,106 C11.2864759,106.000003 8.60018316,103.31371 8.60018316,100 C8.60018316,99.9174744 8.60188578,99.8349576 8.60528993,99.7525022 C8.74289948,96.4193213 8.81635501,94.3044787 8.82565653,93.4079745 C8.88030863,88.1404643 8.71706895,83.320228 8.3359375,78.9472656 C7.66927083,71.2981771 7.66927083,63.6490885 8.3359375,56 Z"
                        id="Rectangle"
                        stroke={theme?.palette?.[color]?.main}
                        fill={lighten(theme?.palette?.[color]?.main, 0.9)}
                        fillRule="nonzero"
                    />
                    <path
                        d="M47.3205817,51.3023298 C47.3308035,51.3677237 47.3359375,51.433812 47.3359375,51.5 C47.3359375,52.3284271 46.6643646,53 45.8359375,53 L27.8359375,53 C27.0075104,53 26.3359375,52.3284271 26.3359375,51.5 C26.3359375,50.9083905 26.8155317,50.4287963 27.4071412,50.4287963 C27.5151599,50.4287963 27.6225572,50.4451344 27.7256901,50.4772535 C29.9148057,51.1590845 32.0760665,51.5 34.2094727,51.5 C36.5817684,51.5 40.4647145,51.0784614 45.858311,50.2353843 C46.5567318,50.126232 47.2114115,50.6039117 47.3205817,51.3023298 Z M54.8359375,42 C55.6643646,42 56.3359375,42.6715729 56.3359375,43.5 L56.3359375,43.7397624 C56.3359144,44.5057901 55.7587114,45.1487398 54.9971192,45.2310542 C52.9712814,45.4500106 49.7508876,45.3729925 45.3359375,45 C40.1644894,44.5630955 34.3282164,44.5322855 27.8271185,44.9075698 C27.0497322,44.9524596 26.3831565,44.3586417 26.3382808,43.5812546 C26.3367189,43.5541979 26.3359375,43.5271018 26.3359375,43.5 C26.3359375,42.6715729 27.0075104,42 27.8359375,42 L54.8359375,42 L54.8359375,42 Z"
                        id="Combined-Shape"
                        fill={theme?.appicalTheme?.mainBg}
                        fillRule="nonzero"
                    />
                </g>
            </g>
        </g>
    </svg>
)

InviteExpired.propTypes = {
    theme: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "secondary"]),
    size: PropTypes.number,
}

InviteExpired.defaultProps = {
    color: "secondary",
    size: 110,
}

export default withTheme(InviteExpired)
