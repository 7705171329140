import get from "lodash/get"

import {
    UPDATE,
    CREATE,
    DELETE,
    SELECT,
    DUPLICATE,
    MOVE,
} from "../../../utility"
import { ERROR, LOADED, LOADING } from "../../../middleware/actionNames"

import { LOGOUT } from "../../core/authentication/actionNames"
import { JOURNEY } from "../journeys/actionNames"
import { COMPANY } from "../companies/actionNames"

import { workspaceSchema, normalizer } from "./schema"
import {
    selectDefaultWorkspace,
    selectSelectedHash as selectCurrentWorkspaceHash,
    selectData,
    selectIdentifiers,
} from "./selectors"
import { WORKSPACES, WORKSPACE } from "./actionNames"

// Required variables
export const initialState = {
    /* Holds the normalized workspaces redux object */
    data: {},
    /* Holds the identifiers for the workspaces */
    identifiers: [],
    /* Indicates whether or not workspaces have been fetched */
    hasFetched: false,
    /* Are we loading new courses */
    isLoading: false,
    /* Loading courses resulted in an error */
    loadingError: null,
    /* Selected workspace hash */
    selectedHash: null,
}

// Reducer
export const reducers = {
    // FETCH all workspace
    [WORKSPACES + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [WORKSPACES + LOADED](state, { result }) {
        const normalizedData = normalizer(result, [workspaceSchema])
        const { workspaces } = normalizedData.entities
        const identifiers = normalizedData.result
        const fakeState = { data: workspaces, identifiers }
        const defaultWorkspace = selectDefaultWorkspace(fakeState)
        const selectedHash = get(defaultWorkspace, "hash")

        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError: null,
            data: workspaces,
            identifiers,
            selectedHash,
        }
    },

    [WORKSPACES + ERROR](state, payload) {
        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError: payload.result,
        }
    },

    // CREATE a workspace
    [WORKSPACE + CREATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [WORKSPACE + CREATE + LOADED](state, payload) {
        const {
            result: { hash },
            originalPayload: { name },
        } = payload
        const data = selectData(state)
        const identifiers = selectIdentifiers(state)
        const newWorkspace = {
            hash,
            name,
            journeyCount: 0,
            isDefault: false,
        }

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            selectedHash: hash,
            data: {
                ...data,
                [hash]: newWorkspace,
            },
            identifiers: [...identifiers, hash],
        }
    },

    [WORKSPACE + CREATE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    // UPDATE a workspace
    [WORKSPACE + UPDATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [WORKSPACE + UPDATE + LOADED](state, payload) {
        const { name, workspaceHash } = payload.originalPayload
        const data = selectData(state)
        const { [workspaceHash]: changedWorkspace } = data

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: {
                ...data,
                [workspaceHash]: {
                    ...changedWorkspace,
                    name,
                },
            },
        }
    },

    [WORKSPACE + UPDATE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    // DELETE a workspace
    [WORKSPACE + DELETE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [WORKSPACE + DELETE + LOADED](state, payload) {
        const { workspaceHash, moveToWorkspaceHash } = payload.originalPayload

        const data = selectData(state)
        const currentIdentifiers = selectIdentifiers(state)
        const moveToWorkspace = get(data, moveToWorkspaceHash, {})
        const { journeyCount: deletedJourneyCount } = get(
            data,
            workspaceHash,
            {},
        )

        const newData = {
            ...data,
            [moveToWorkspaceHash]: {
                ...moveToWorkspace,
                journeyCount:
                    moveToWorkspace.journeyCount + deletedJourneyCount,
            },
        }

        delete newData[workspaceHash]

        const identifiers = currentIdentifiers.filter(
            hash => hash !== workspaceHash,
        )

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            selectedHash: moveToWorkspaceHash,
            data: newData,
            identifiers,
        }
    },

    [WORKSPACE + DELETE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [WORKSPACE + SELECT](state, { selectedHash }) {
        return { ...state, selectedHash }
    },

    // External flux responses
    [JOURNEY + CREATE + LOADED](state, payload) {
        const data = selectData(state)
        const {
            originalPayload: { workspaceHash },
        } = payload
        const workspace = data[workspaceHash]

        if (!workspace) return state

        return {
            ...state,
            data: {
                ...data,
                [workspaceHash]: {
                    ...workspace,
                    journeyCount: get(workspace, "journeyCount", 0) + 1,
                },
            },
        }
    },

    [JOURNEY + DUPLICATE + LOADED](state) {
        const data = selectData(state)
        const currentWorkspaceHash = selectCurrentWorkspaceHash(state)
        const currentWorkspace = data[currentWorkspaceHash]

        if (!currentWorkspace) return state

        return {
            ...state,
            data: {
                ...data,
                [currentWorkspaceHash]: {
                    ...currentWorkspace,
                    journeyCount: get(currentWorkspace, "journeyCount", 0) + 1,
                },
            },
        }
    },

    [JOURNEY + DELETE + LOADED](state) {
        const data = selectData(state)
        const currentWorkspaceHash = selectCurrentWorkspaceHash(state)
        const currentWorkspace = data[currentWorkspaceHash]

        if (!currentWorkspace) return state

        return {
            ...state,
            data: {
                ...data,
                [currentWorkspaceHash]: {
                    ...currentWorkspace,
                    journeyCount: get(currentWorkspace, "journeyCount", 0) - 1,
                },
            },
        }
    },

    [JOURNEY + MOVE + LOADED](state, payload) {
        const { workspaceHash, previousWorkspaceHash } = payload.originalPayload
        const data = selectData(state)
        const {
            [workspaceHash]: nextWorkspace,
            [previousWorkspaceHash]: prevWorkspace,
        } = data

        return {
            ...state,
            data: {
                ...data,
                [workspaceHash]: {
                    ...nextWorkspace,
                    journeyCount: get(nextWorkspace, "journeyCount", 0) + 1,
                },
                [previousWorkspaceHash]: {
                    ...prevWorkspace,
                    journeyCount: get(prevWorkspace, "journeyCount", 0) - 1,
                },
            },
        }
    },

    // Reset reducers
    [LOGOUT + LOADED]() {
        return initialState
    },

    [COMPANY + SELECT]() {
        return initialState
    },
}
