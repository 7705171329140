import memoize from "memoize-one"
import get from "lodash/get"
import { Reducer } from "redux"

import { ReducerMap } from "redux/types"

import { CombinedState } from "redux/reducers/types"

// default action names
export const UPDATE = "_UPDATE"
export const CREATE = "_CREATE"
export const DELETE = "_DELETE"
export const DUPLICATE = "_DUPLICATE"
export const EDIT = "_EDIT"
export const SELECT = "_SELECT"
export const ADD = "_ADD"
export const MOVE = "_MOVE"
export const CLONE = "_CLONE"
export const PUBLISH = "_PUBLISH"
export const UNPUBLISH = "_UNPUBLISH"
export const REPUBLISH = "_REPUBLISH"
export const REORDER = "_REORDER"
export const TALENT = "_TALENT"
export const PREVIEW = "_PREVIEW"
export const RENAME = "_RENAME"
export const SUBSCRIBE = "_SUBSCRIBE"
export const SEARCH = "_SEARCH"
export const ACTIVATE = "_ACTIVATE"
export const CALLBACK = "_CALLBACK"
export const FILTER = "_FILTER"
export const USER_DATA = "_USER_DATA"
export const AGREE = "_AGREE"

export const createActionName = (reducerName: string, name: string): string =>
    `app/${reducerName}/${name}`

export const createReducer = (
    reducer: ReducerMap,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialState: Record<string, any>,
): Reducer => (state = initialState, { type, payload }) => {
    if (reducer[type]) {
        return reducer[type](state, payload)
    }
    return state
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createFeatureSelector = (featureName: string) =>
    memoize((state: CombinedState) => get(state, featureName))

export enum status {
    idle = "idle",
    loading = "loading",
    success = "success",
    failure = "failure",
}
