import uuid from "uuid"

import toastTypes from "constants/toastTypes"

import { DISMISS_TOAST, SHOW_TOAST } from "./actionNames"

export const createToastAction = (
    toastCode,
    toastType = toastTypes.warning,
    interpolations = {},
    identifier = uuid(),
) => ({
    type: SHOW_TOAST,
    payload: {
        toastType,
        identifier,
        toastCode,
        interpolations,
    },
})

export const createInfoAction = (
    toastCode,
    interpolations = {},
    identifier = uuid(),
) => ({
    type: SHOW_TOAST,
    payload: {
        toastType: toastTypes.info,
        identifier,
        toastCode,
        interpolations,
    },
})

export const createErrorAction = (
    toastCode,
    interpolations = {},
    identifier = uuid(),
) => ({
    type: SHOW_TOAST,
    payload: {
        toastType: toastTypes.error,
        identifier,
        toastCode,
        interpolations,
    },
})

export const createWarningAction = (
    toastCode,
    interpolations = {},
    identifier = uuid(),
) => ({
    type: SHOW_TOAST,
    payload: {
        toastType: toastTypes.warning,
        identifier,
        toastCode,
        interpolations,
    },
})

export const createSuccessAction = (
    toastCode,
    interpolations = {},
    identifier = uuid(),
) => ({
    type: SHOW_TOAST,
    payload: {
        toastType: toastTypes.success,
        identifier,
        toastCode,
        interpolations,
    },
})

export const createDismissAction = identifier => ({
    type: DISMISS_TOAST,
    payload: {
        identifier,
    },
})

export const createDismissAllAction = () => ({
    type: DISMISS_TOAST,
})

export const createTimedInfoAction = (
    toastCode,
    showTime = 1500,
    identifier = uuid(),
) => dispatch => {
    dispatch(createInfoAction(toastCode, identifier))
    setTimeout(() => dispatch(createDismissAction(identifier)), showTime)
}

export const createTimedErrorAction = (
    toastCode,
    showTime = 1500,
    identifier = uuid(),
) => dispatch => {
    dispatch(createErrorAction(toastCode, identifier))
    setTimeout(() => dispatch(createDismissAction(identifier)), showTime)
}
