import React from "react"
import hoistNonReactStatics from "hoist-non-react-statics"

import getDisplayName from "@material-ui/utils/getDisplayName"

import { isDevelopment } from "constants/internal"
import Toaster from "lib/views/Toaster"

const withToaster = () => Component => {
    const WithToaster = (props = {}) => {
        return (
            <React.Fragment>
                <Component {...props} />
                <Toaster />
            </React.Fragment>
        )
    }

    if (isDevelopment) {
        WithToaster.displayName = `WithToaster(${getDisplayName(Component)})`
    }

    hoistNonReactStatics(withToaster, Component)

    return WithToaster
}

export default withToaster
