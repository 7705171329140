import get from "lodash/get"
import { createSelector } from "reselect"

/* Selectors */
export const selectData = state => get(state, "data")
export const selectIdentifiers = state => get(state, "identifiers")

export const selectMessages = createSelector(
    selectData,
    selectIdentifiers,
    (messages, identifiers) => identifiers.map(id => messages[id]),
)
