import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { compose } from "redux"

// @material-ui/core
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"

import { checkIfTranslation } from "lib/utils/helpers/translations"

import Button from "lib/views/Button/Button"

// icons
import WarningOutline from "lib/views/Icons/WarningOutline"

import styles from "./styles"

// eslint-disable-next-line no-undef
const errorAction = () => window.location.reload()

const Error = ({ classes, preMessage, message, reason, onClick, buttonText, t }) => (
    <div className={classes.root}>
        <div className={classes.warningIcon}>
            <WarningOutline className={classes.icon} />
        </div>
        <Typography variant="h6" className={classes.subheading} gutterBottom>
            {t(preMessage, { message: checkIfTranslation(message) ? t(message) : message })}
        </Typography>
        <Typography variant="body1" className={classes.subheading} gutterBottom>
            {checkIfTranslation(reason) ? t(reason) : reason}
        </Typography>

        <Button color="secondary" variant="outlined" onClick={onClick || errorAction}>
            {checkIfTranslation(buttonText) ? t(buttonText) : buttonText}
        </Button>
    </div>
)

Error.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    preMessage: PropTypes.string,
    message: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
}

Error.defaultProps = {
    preMessage: "main:error_message",
    onClick: errorAction,
    buttonText: "main:try_again",
}

export default compose(
    withStyles(styles, { name: "Error" }),
    withTranslation(),
    React.memo,
)(Error)
