import get from "lodash/get"
import { fade } from "@material-ui/core/styles/colorManipulator"

export const formStyles = theme => ({
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },

    button: {
        width: "100%",
        height: "50px",
        marginTop: theme.spacing(3),
        borderRadius: "50px",
    },

    bottomSpace: {
        marginBottom: theme.spacing(2),
    },

    formField: {
        marginBottom: get(theme, "appicalTheme.spacing.smallest"),
    },

    formFieldLast: {
        marginBottom: theme.spacing(1),
    },

    center: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "nowrap",
        marginTop: theme.spacing(2),
    },

    link: {
        color: theme?.palette?.secondary?.main,
        fontWeight: 600,
        textDecoration: "none",
        marginLeft: theme.spacing(0.5),
        textAlign: "center",

        "&:active": {
            color: theme?.palette?.secondary?.main,
        },
        "&:hover": {
            color: fade(theme?.palette?.secondary?.main, 0.7),
        },
    },
})

const styles = theme => ({
    scrollContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "auto",
    },

    icon: {
        marginBottom: theme.spacing(8),
    },

    subheader: {
        marginBottom: theme.spacing(3),
    },
})

export default styles
