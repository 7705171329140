import React from "react"
import PropTypes from "prop-types"

const Dog = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 138 128"
        {...props}
    >
        <defs>
            <path
                id="a"
                d="M72.207 115.115c-.512 1.356-2.578 3.173-5.943 4.783-.935.484-2.05.963-3.506 1.42-6.417 2.19-15.677 3.44-23.664 3.44-11.028 0-20.578-1.455-25.226-3.577-1.787-.816-2.85-1.73-2.992-2.699-.082-.37.005-.753.263-1.145.925-1.413 1.617-3.164 2.522-4.544 6.694-10.214 15.615-13.907 30.037-28.897C54.43 72.743 54.033 72.36 63.967 60.507c5.542-6.613 4.55-7.638 10.683-13.543 3.564-3.432 5.77-1.346 8.709-5.132 4.73-6.09 6.288-6.903 7.016-14.217.223-2.237.021-4.311-.514-5.869-.589-1.715-1.521-3.357-3.255-5.142-2.737-2.817-5.505-3.379-8.18-3.77-1.211-.177-2.842-.63-4.636-.63-1.333 0-3.855-.143-7.414.838-3.587.989-5.402 1.256-6.59 1.639-2.365.762-3.877-2.646-3.388-3.713 1.392-3.046 2.75-2.587 6.36-5.563 4.53-3.734 5.797-1.818 9.88-4.895 1.863-1.403 15.446.24 19.986 3.066 3.99 2.483 6.38 4.25 8.344 8.925 1.724 4.103 2.194 12.133 1.724 15.8-.484 3.786-.454 2.525-1.724 6.133-.931 2.648-3.946 5.609-2.12 7.398 2.606 2.556 2.783 2.272 4.835 3.188 1.704.761 3.64 1.395 5.891 2.76 2.123 1.288 3.92 2.598 5.923 4.786 2.026 2.213 3.263 4.996 3.841 6.563.752 2.04.763 1.818 1.747 5.736.475 1.887.986 4.781 1.133 5.908.356 2.719.32 4.34 1.308 8.83.14.64.652 1.367 1.468 1.595.96.269 1.68-.354 2.735-.587 1.073-.237 1.42-.237 3.114.232 3.696 1.022 5.93 3.44 6.478 6.706.405 2.416-.059 3.311-1.442 5.739-7.92 13.897-7.242 14.06-17.055 26.467-2.39 3.022-3.162 3.066-6.923 3.736-1.496.266-2.268.208-3.1.2-.784-.008-1.87-.018-3.351-.2-1.481-.182-2.33-.057-3.274-2.173-.67-1.501-.31-4.312.91-4.842 2.807-1.22 5.799 1.478 8.429-1.113 8.228-8.105 7.449-8.98 13.479-19.6.502-.884.682-1.87.54-2.959a1 1 0 00-1.718-.558c-1.515 1.603-3.18 2.776-4.992 3.517-2.533 1.036-7.759 1.236-11.068-.307-7.398-3.45-5.65-2.386-10.813-7.135-4.101-3.773-4.599-5.719-7.872-5.184-5.874.96-7.282 4.542-11.002 14.02-1.981 5.046-2.323 5.439-3.688 10.539-1.19 4.45-.22 2.836-1.673 6.51-.115.294-.29.6-.501.91z"
            />
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-866 -200)">
                <g transform="translate(866 200.703)">
                    <path
                        fill="#005FE6"
                        stroke="#005FE6"
                        d="M39.077 126.797c19.465 0 35.27-6.297 35.27-12.26 0-5.381-14.662-9.74-35.287-9.74-21.914 0-38.56 3.85-38.56 8.847 0 5.877 18.977 13.153 38.577 13.153z"
                    />
                    <path
                        fill="#006AFF"
                        fillOpacity="0.36"
                        fillRule="nonzero"
                        stroke="#006AFF"
                        d="M83.343 119.403c1.648 1 3.74 1.349 7.79 1 2.639-.227 3.125-.168 5.006-2.831 5.732-8.115 4.754-9.93 10.219-18.725.977-1.573 2.191-2.767 3.642-3.584l-10.563-6.528a77.26 77.26 0 00-1.872-1.126c-.708-.403-1.245-1.59-1.608-1.968-.315-.328-.41-.75-.673-1.229-.587-1.065-1.253-1.31-1.677-1.727-.513-.503-.778-1.062-1.145-1.576-.328-.46-.76-.88-1.15-1.276-.346-.353-.796-.805-1.252-1.071-.395-.231-.665-.465-1.174-.465-.976 0-1.932.696-2.868 2.089-.48.928-.823 1.695-1.027 2.299-.203.604-.414 1.513-.632 2.727.482 2.748 2.382 4.822 5.701 6.222 3.844 1.622 6.506-2.429 7.505 1.055 1.014 3.534.557 5.753-1.02 10.272-.38 1.089-.805 2.311-1.261 3.72-1.082 3.344-1.773 4.614-3.971 5.988-3.27 2.046-4.056 0-7.97.34-1.461.126-2.343 1.256-2.343 2.886 0 2.067.709 2.517 2.343 3.508z"
                    />
                    <mask id="b" fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <use
                        fill="#D7DDFF"
                        fillRule="nonzero"
                        stroke="#006AFF"
                        xlinkHref="#a"
                    />
                    <g fill="#005FE6" mask="url(#b)">
                        <g transform="translate(25.259 91.87)">
                            <circle
                                cx="17.291"
                                cy="2.977"
                                r="1"
                                transform="rotate(6 17.29 2.977)"
                            />
                            <circle
                                cx="12.1"
                                cy="1.5"
                                r="1"
                                transform="rotate(6 12.1 1.5)"
                            />
                            <circle
                                cx="11.885"
                                cy="5.594"
                                r="1"
                                transform="rotate(6 11.885 5.594)"
                            />
                            <circle
                                cx="7.896"
                                cy="5.385"
                                r="1"
                                transform="rotate(6 7.896 5.385)"
                            />
                            <circle
                                cx="8.682"
                                cy="9.478"
                                r="1"
                                transform="rotate(6 8.682 9.478)"
                            />
                            <circle
                                cx="19.863"
                                cy="7.012"
                                r="1"
                                transform="rotate(6 19.863 7.012)"
                            />
                            <circle
                                cx="15.291"
                                cy="5.977"
                                r="1"
                                transform="rotate(6 15.29 5.977)"
                            />
                            <circle
                                cx="16.66"
                                cy="8.896"
                                r="1"
                                transform="rotate(6 16.66 8.896)"
                            />
                            <circle
                                cx="12.456"
                                cy="10.781"
                                r="1"
                                transform="rotate(6 12.456 10.78)"
                            />
                            <circle
                                cx="4.704"
                                cy="8.06"
                                r="1"
                                transform="rotate(6 4.704 8.06)"
                            />
                            <circle
                                cx="1.5"
                                cy="9.944"
                                r="1"
                                transform="rotate(6 1.5 9.944)"
                            />
                            <circle
                                cx="4.285"
                                cy="12.038"
                                r="1"
                                transform="rotate(6 4.285 12.038)"
                            />
                        </g>
                    </g>
                    <path
                        fill="#006AFF"
                        d="M48.161 87.537c1.172 1.685 1.978 2.871 2.42 3.558 1.906 2.969 4.013 4.304 3.508 4.676-.246.18-3.56 1.953-4.301 1.534-.741-.419-1.551-.638-3.97-.77-.616-.035-1.397.22-2.997-1.555-1.6-1.774-4.461.177-5.08.69a445.191 445.191 0 00-5.55 4.682c-1.122.96-2.988 2.571-5.6 4.833-2.264 2.093-3.909 3.786-4.934 5.077-1.13 1.423-2.113 2.661-3.158 4.827-.847 1.756-1.377 4.16-1.59 7.21-1.996-.745-3.364-1.334-4.104-1.764-.74-.431-1.393-1.048-1.959-1.85.97-2.255 1.78-3.927 2.428-5.016.649-1.089 1.779-2.672 3.39-4.748L28.18 98.095l7.348-5.938 9.024-8.952 3.61 4.332z"
                        mask="url(#b)"
                        opacity="0.362"
                    />
                    <path
                        fill="#005FE6"
                        d="M94.899 79.49c1.304 2.36-2.274 3.107-2.111 4.427.404 3.259.27 3.005-1.164 3.568-5.029 1.97-16.853-1.221-22.276-.225-4.107.752-7.737-6.075-15.5-7.992-1.836-.451-2.053-4.564-8.555-6.304-5.111-1.368-6.114-8.104-4.902-13.028.682-2.781 2.536-6.145 5.018-8.566 1.265.803 2.494.23 4.055.31 1.216.062 2.75.755 3.896 1.293 5.79 2.711 27.179 14.297 37.479 17.81.712.244 1.825.741 2.424.91.477 1.082 1.776 2.198.902 3.668-1.005 1.693-.42 2.04.734 4.13z"
                        transform="scale(-1 1) rotate(25 0 -234.97)"
                    />
                    <path
                        fill="#006AFF"
                        d="M85.558 47.78c3.66-5.29 6.403-10.334 8.23-15.131 1.602-4.203 2.15-6.523.843-10.793-1.8-5.887-2.516-6.63-6.8-9.797-2.258-1.67-6.296-2.646-9.224-2.945-5.253-.536-1.991-.208-7.305 0-3.77.148-3.925.768-7.355 1.985-1.53.543-2.837 1.66-3.924 3.353l8.272-1.735 6.285-.658 7.534 1.17 2.991 1.6 4.302 5.645.873 8.098-1.689 5.779-5.232 7.481-2.591 2.435 4.79 3.514z"
                        opacity="0.36"
                    />
                    <path
                        stroke="#006AFF"
                        strokeLinecap="round"
                        strokeLinejoin="bevel"
                        d="M90.618 64.908c-1.12 2.006-1.68 4.039-1.68 6.097 0 2.071.56 4.168 1.68 6.292"
                        transform="rotate(4 89.778 71.103)"
                    />
                </g>
            </g>
        </g>
    </svg>
)

Dog.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}

Dog.defaultProps = {
    width: 138,
    height: 138,
}

export default Dog
