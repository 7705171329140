import React from "react"
import Icon from "./Icon"

const path = (
    <path
        d="M10.2009672,3.01319075 C10.7854873,2.03624085 12.0666561,1.70910585 13.0625377,2.28251427 C13.3705726,2.45987455 13.6271569,2.71168816 13.8078231,3.01394274 L13.8078231,3.01394274 L22.7124599,17.9113944 C23.2965567,18.8885879 22.9625378,20.1452625 21.9664079,20.7182556 C21.6456801,20.9027444 21.2806059,21 20.9088071,21 L20.9088071,21 L3.09085698,21 C1.93610856,21 1,20.0816869 1,18.9488902 C1,18.5838635 1.09930105,18.2254498 1.28765386,17.9106424 L1.28765386,17.9106424 Z M12.0041703,4.05143854 L3.09085698,18.9488902 L20.9088071,18.9488902 L12.0041703,4.05143854 Z M12.0046094,15.359448 C12.5819836,15.359448 13.0500379,15.8186046 13.0500379,16.3850029 C13.0500379,16.9514012 12.5819836,17.4105578 12.0046094,17.4105578 C11.4272352,17.4105578 10.9591809,16.9514012 10.9591809,16.3850029 C10.9591809,15.8186046 11.4272352,15.359448 12.0046094,15.359448 Z M12.0046094,9.2061185 C12.5795951,9.2061185 13.0500379,9.66761821 13.0500379,10.2316734 L13.0500379,12.2827832 C13.0500379,12.8468384 12.5795951,13.3083382 12.0046094,13.3083382 C11.4296237,13.3083382 10.9591809,12.8468384 10.9591809,12.2827832 L10.9591809,10.2316734 C10.9591809,9.66761821 11.4296237,9.2061185 12.0046094,9.2061185 Z"
        id="path-1"
    />
)

const WarningOutline = props => <Icon {...props} path={path} />

export default React.memo(WarningOutline)
