export const journey = "Journey"
export const topic = "Topic"
export const story = "Story"
export const introductionStory = "introductionStory"
export const todo = "ToDo"
export const message = "Message"
export const mainEvent = "MainEvent"
export const journeyStepItem = "JourneyStepItem"
export const page = "Page"
export const field = "Field"

export const pageElements = {
    assign: "Assign",
    header: "Header",
    text: "Text",
    image: "Image",
    video: "Video",
    giphy: "Giphy",
    externalReference: "ExternalReference",
    button: "Button",
    score: "Score",
    essay: "Essay",
    multipleChoice: "MultipleChoice",
    singleChoice: "SingleChoice",
    choiceImage: "ChoiceImage",
    choiceText: "ChoiceText",
    opinionScale: "OpinionScale",
    rating: "Rating",
    ranking: "Ranking",
    matchingImage: "MatchingImage",
    matchingText: "MatchingText",
    embed: "Embed",
}

export const mediaElements = [
    pageElements.image,
    pageElements.video,
    pageElements.embed,
    pageElements.button,
    pageElements.giphy,
]

export default {
    journey,
    topic,
    story,
    introductionStory,
    todo,
    message,
    journeyStepItem,
    page,
    field,
    pageElements,
}
