import get from "lodash/get"
import { darken } from "@material-ui/core/styles/colorManipulator"

const styles = theme => ({
    root: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },

    checkCircle: {
        color: darken(get(theme, "appicalTheme.success"), 0.1),
    },

    dot: {
        padding: "3px",
    },

    error: {
        color: get(theme, "appicalTheme.error"),
    },

    text: {
        paddingLeft: get(theme, "appicalTheme.spacing.smallest"),
    },
})

export default styles
