import get from "lodash/get"
import { lighten, fade } from "@material-ui/core/styles/colorManipulator"

const styles = theme => ({
    checkbox: {
        width: "24px",
        height: "24px",
        border: "1.5px solid",
        "border-color": fade(get(theme, "palette.primary.contrastText"), 0.3),
        "border-radius": get(theme, "appicalTheme.sizes.borderRadius"),
        transition: `background-color ${get(
            theme,
            "transitions.duration.standard",
        )}ms`,
        background: "transparent",
        outline: "none",
        cursor: "pointer",
    },

    checkboxSmall: {
        width: "18px",
        height: "18px",
        border: "1.5px solid",
        "border-color": fade(get(theme, "palette.primary.contrastText"), 0.3),
        borderRadius: get(theme, "appicalTheme.sizes.smallBorderRadius"),
        transition: `background-color ${get(
            theme,
            "transitions.duration.standard",
        )}ms`,
        background: "transparent",
        outline: "none",
        cursor: "pointer",
    },

    blue: {
        color: lighten(get(theme, "appicalTheme.checkmarkBlue", "#fff"), 0.7),

        "&$checked": {
            background: lighten(
                get(theme, "appicalTheme.checkmarkBlue", "#fff"),
                0.7,
            ),
            color: get(theme, "appicalTheme.checkmarkBlue"),
            fill: get(theme, "appicalTheme.checkmarkBlue"),
            borderColor: lighten(
                get(theme, "appicalTheme.checkmarkBlue", "#fff"),
                0.7,
            ),
        },
    },

    green: {
        color: lighten(get(theme, "appicalTheme.checkmark", "#fff"), 0.7),
        borderRadius: get(theme, "appicalTheme.sizes.smallBorderRadius"),

        "&$checked": {
            background: get(theme, "appicalTheme.checkmark", "#fff"),
            color: get(theme, "appicalTheme.checkmarkWhite"),
            fill: get(theme, "appicalTheme.checkmarkWhite"),
            borderColor: get(theme, "appicalTheme.checkmark", "#fff"),
        },
    },

    primary: {
        color: lighten(get(theme, "appicalTheme.primary", "#fff"), 0.7),

        "&$checked": {
            background: lighten(
                get(theme, "appicalTheme.primary", "#fff"),
                0.7,
            ),
            color: get(theme, "appicalTheme.primary"),
            fill: get(theme, "appicalTheme.primary"),
            borderColor: lighten(
                get(theme, "appicalTheme.primary", "#fff"),
                0.7,
            ),
        },
    },

    contrast: {
        color: lighten(get(theme, "appicalTheme.contrast", "#fff"), 0.7),

        "&$checked": {
            background: lighten(
                get(theme, "appicalTheme.contrast", "#fff"),
                0.7,
            ),
            color: get(theme, "appicalTheme.contrast"),
            fill: get(theme, "appicalTheme.contrast"),
            borderColor: lighten(
                get(theme, "appicalTheme.contrast", "#fff"),
                0.7,
            ),
        },
    },

    incorrect: {
        background: lighten(get(theme, "appicalTheme.error", "#fff"), 0.7),
        color: get(theme, "appicalTheme.error"),
        fill: get(theme, "appicalTheme.error"),
        borderColor: lighten(get(theme, "appicalTheme.error", "#fff"), 0.7),
    },

    error: {
        borderColor: get(theme, "appicalTheme.error"),
    },

    checked: {},

    icon: {
        width: "20px",
        height: "20px",
        fill: "currentColor",
    },

    iconSmall: {
        width: "14px",
        height: "14px",
        fill: "currentColor",
        marginBottom: "1px",
    },

    hidden: {
        position: "absolute",
        top: 0,
        left: 0,
        opacity: 0,
        visibility: "hidden",
    },
})

export default styles
