import React from "react"
import PropTypes from "prop-types"
import { PersistGate } from "redux-persist/integration/react"

import RedirectExternal from "../lib/views/RedirectExternal"

import {
    getDomainEnvironment,
    isEditorDomain,
    getDomain,
    getProtocol,
} from "../lib/utils/helpers/uri"

import Loading from "../lib/views/Loading/Loading"

import connect from "./connect"

export const EnvironmentGate = ({
    activateEnvironment,
    persistor,
    isReady,
    isSubdomain,
    children,
    hasLoadError,
}) => {
    const [activated, setActivated] = React.useState(false)
    const isGateLifted =
        !hasLoadError && (isReady || (activated && !isSubdomain))
    const protocol = getProtocol()
    const domain = getDomain()

    const handlePersistLifted = () => {
        const name = getDomainEnvironment()

        if (!isEditorDomain(name)) {
            activateEnvironment(name)
        } else {
            setActivated(true)
        }
    }

    return (
        <PersistGate
            loading={<Loading />}
            persistor={persistor}
            onBeforeLift={handlePersistLifted}
        >
            {isGateLifted ? children : <Loading />}

            {hasLoadError && (
                <RedirectExternal to={`${protocol}//${domain}/login`} />
            )}
        </PersistGate>
    )
}

EnvironmentGate.propTypes = {
    activateEnvironment: PropTypes.func.isRequired,
    persistor: PropTypes.object.isRequired,
    isReady: PropTypes.bool,
    isSubdomain: PropTypes.bool,
    hasLoadError: PropTypes.bool,
    children: PropTypes.node,
}
EnvironmentGate.defaultProps = {
    isReady: false,
    isSubdomain: false,
    hasLoadError: false,
    children: null,
}

export default connect(EnvironmentGate)
