import { normalize } from "normalizr"
import { ERROR, LOADED, LOADING } from "redux/middleware/actionNames"
import { LOGOUT } from "redux/reducers/core/authentication/actionNames"
import { policySchema } from "redux/reducers/entities/policy/schema"
import { AGREE, createReducer, status } from "redux/utility"
import { POLICY } from "./actionNames"

export const initialState = {
    data: {},
    status: status.idle,
}

const reducers = {
    [POLICY + LOADING](state) {
        return { ...state, status: status.loading }
    },

    [POLICY + LOADED](state, { result }) {
        const {
            entities: { policies },
        } = normalize(result, [policySchema])

        return {
            ...state,
            status: status.success,
            data: {
                ...state.data,
                ...policies,
            },
        }
    },

    [POLICY + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            status: status.failure,
            loadingError,
        }
    },

    [POLICY + AGREE + LOADED](state) {
        return { ...state, status: status.loading }
    },

    [POLICY + AGREE + LOADED](state, { originalPayload: { policyIds = [] } }) {
        const updatedPolicies = policyIds.reduce((policies, id) => {
            const policy = state.data[id]

            return {
                ...policies,
                [id]: {
                    ...policy,
                    agreedAt: new Date(),
                    hasAgreed: true,
                },
            }
        }, {})

        return {
            ...state,
            data: {
                ...state.data,
                ...updatedPolicies,
            },
        }
    },

    [POLICY + AGREE + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            status: status.failure,
            loadingError,
        }
    },

    [LOGOUT + LOADED]() {
        return initialState
    },
}

export const reducer = createReducer(reducers, initialState)
