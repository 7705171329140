import { connect } from "react-redux"

import {
    selectRedirectUrl,
    selectHasConfirmedEmail,
    selectIsLoggedIn,
} from "redux/reducers/core/authentication"

import {
    selectHasCompany,
    selectIsCompanySubscribed,
    selectHasFetchedCompanyBillingInfo,
    selectIsAdmin,
} from "redux/reducers/entities/companies"

import {
    selectHasActiveRoles,
    selectHasFetchedUser,
} from "redux/reducers/entities/users"

import { createLoadingAction as loadInvites } from "redux/reducers/entities/userInvites/actions"
import {
    selectUserInvites,
    selectHasFetched as selectHasFetchedInvites,
    selectIsLoading as selectIsLoadingUserInvites,
} from "redux/reducers/entities/userInvites"

const mapDispatchToProps = {
    loadInvites,
}

const mapStateToProps = state => ({
    redirectUrl: selectRedirectUrl(state),
    hasConfirmedEmail: selectHasConfirmedEmail(state),
    hasCompany: selectHasCompany(state),
    hasFetchedInvites: selectHasFetchedInvites(state),
    invites: selectUserInvites(state),
    isSubscribed: selectIsCompanySubscribed(state),
    isLoggedIn: selectIsLoggedIn(state),
    hasFetchedBillingInfo: selectHasFetchedCompanyBillingInfo(state),
    hasActiveRoles: selectHasActiveRoles(state),
    hasFetchedLoggedInUser: selectHasFetchedUser(state),
    isLoadingUserInvites: selectIsLoadingUserInvites(state),
    isAdmin: selectIsAdmin(state),
})

export default connect(mapStateToProps, mapDispatchToProps)
