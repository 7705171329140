import { inviteStatus, userRoles, fileType, userStates } from "./enums"
import { environmentTypes } from "./environment"

export const BEFORE_EVENT = "before"
export const AFTER_EVENT = "after"
export const JOURNEY_STEP = 2
export const DESKTOP = "desktop"
export const MOBILE = "mobile"

export const isDevelopment = process.env.REACT_APP_ENV === environmentTypes.dev
export const isTranslation =
    process.env.REACT_APP_ENV === environmentTypes.translation
export const isUAT = process.env.REACT_APP_ENV === environmentTypes.uat
export const isDemo = process.env.REACT_APP_ENV === environmentTypes.demo
export const isProduction = process.env.REACT_APP_ENV === environmentTypes.prod
export const isPremise = process.env.REACT_APP_ENV === environmentTypes.premise
export const isPaymentRequired =
    process.env.REACT_APP_ENV !== environmentTypes.premise
export const disableExternalUpload =
    process.env.REACT_APP_DISABLE_EXTERNAL_UPLOAD === "true"
export const disableEmbed = process.env.REACT_APP_DISABLE_EMBED === "true"

export const isVisualTesting = !!process.env.STORYBOOK_VISUAL_TESTING
export const isMobileTesting = !!process.env.TEST_MOBILE

export const editorDomainKey =
    "0d330e40f18a48b09e5931c329aa4fe8a7f98e5904654303b647f5ec10ac95d8"

export const attachmentTypes = {
    LOCATION: 1,
    FILE: 2,
} as const

export const fileLibrarySections = {
    myFiles: "my-files",
    myLogos: "my-logos",
    giphy: "giphy",
    unsplash: "unsplash",
} as const

export const customFolders = {
    giphy: "giphy-047a825be163473cb21767d1bfaf23ff",
    unsplash: "unsplash-96a64c419fe74269a96c18d1fac5a00c",
} as const

export const externalFileType = {
    giphy: "giphy",
    unsplash: "unsplash",
} as const

export const externalToInternalFileTypeMap = {
    [externalFileType.giphy]: fileType.image,
    [externalFileType.unsplash]: fileType.image,
} as const

export const externalToMimeTypeMap = {
    [externalFileType.giphy]: "image/gif",
    [externalFileType.unsplash]: "image/jpeg",
} as const

export const sortingType = {
    dateDesc: 1,
    dateAsc: 2,
    nameAsc: 3,
    nameDesc: 4,
} as const

export const orderType = {
    ascending: "asc",
    descending: "desc",
} as const

export const filterCategories = {
    uploaders: "uploaders",
    fileType: "fileTypes",
} as const

export const filterUsers = {
    all: "all",
    talent: "talent",
    manager: "manager",
    admin: "admin",
    imported: "imported",
} as const

export const userRoleToFilterMap = {
    [userRoles.talent]: filterUsers.talent,
    [userRoles.manager]: filterUsers.manager,
    [userRoles.administrator]: filterUsers.admin,
    [userRoles.owner]: filterUsers.admin,
    [userRoles.imageProcessor]: filterUsers.all,
} as const

export const userStateToFilterMap = {
    [userStates.imported]: filterUsers.imported,
    [userStates.active]: filterUsers.all,
    [userStates.draft]: filterUsers.all,
    [userStates.archived]: filterUsers.all,
    [userStates.deleted]: filterUsers.all,
} as const

export const userType = {
    talent: "talent",
    staff: "staff",
} as const

export const userRoleToTypeMap = {
    [userRoles.talent]: userType.talent,
    [userRoles.manager]: userType.staff,
    [userRoles.administrator]: userType.staff,
    [userRoles.owner]: userType.staff,
    [userRoles.imageProcessor]: userType.staff,
} as const

export const appicalPartnerHash = "APPICAL-NL"

export const templateChoices = {
    blankPage: "blankPage",
    textPage: "textPage",
    imagePage: "imagePage",
    textChoice: "textChoice",
    imageChoice: "imageChoice",
    essayPage: "essayPage",
    ratingPage: "ratingPage",
    rankingPage: "rankingPage",
} as const

export const pageElementCategories = {
    basic: "basic",
    interactive: "interactive",
} as const

export const themeTypes = {
    dark: "DARK",
    light: "LIGHT",
} as const

export const directions = {
    up: "UP",
    down: "DOWN",
    right: "RIGHT",
    left: "LEFT",
} as const

export const inviteStatusColors = {
    [inviteStatus.pending]: "warning",
    [inviteStatus.accepted]: "success",
    [inviteStatus.declined]: "error",
    [inviteStatus.expired]: "primary",
    [inviteStatus.revoked]: "error",
} as const

export const answerStatus = {
    all: "All",
    correct: "Correct",
    incorrect: "Incorrect",
} as const

export const republishingPrefix = "TEMP" as const

export const noImage = "no-image" as const

export const APPICAL_PARTNER_COMPANY_HASH = "APPICAL-NL" as const

export default {
    BEFORE_EVENT,
    AFTER_EVENT,
    JOURNEY_STEP,
    DESKTOP,
    MOBILE,
    attachmentTypes,
    fileLibrarySections,
    customFolders,
    sortingType,
    filterCategories,
    appicalPartnerHash,
    templateChoices,
    pageElementCategories,
    userType,
    filterUsers,
    userRoleToFilterMap,
} as const
