import React from "react"
import PropTypes from "prop-types"
import flow from "lodash/flow"
import get from "lodash/get"
import classNames from "classnames"

import withStyles from "@material-ui/core/styles/withStyles"

import { MdClose as Cross } from "react-icons/md"
import Tick from "../Icons/Tick"

import styles from "./styles"

const getIcon = (className, checked, incorrect) => {
    if (!checked) {
        return null
    }

    return incorrect ? (
        <Cross color="inherit" className={className} />
    ) : (
        <Tick color="inherit" className={className} />
    )
}

export const Checkbox = ({
    classes,
    checked,
    className,
    onClick,
    incorrect,
    color,
    error,
    small,
    ...rest
}) => (
    <div
        role="checkbox"
        aria-checked={checked}
        tabIndex={0}
        onKeyDown={event => {
            if (event.keyCode === 13) onClick()
        }}
        onClick={onClick}
        className={classNames(
            small ? classes.checkboxSmall : classes.checkbox,
            get(classes, color),
            className,
            checked && classes.checked,
            checked && incorrect && classes.incorrect,
            error && classes.error,
        )}
        {...rest}
    >
        {getIcon(small ? classes.iconSmall : classes.icon, checked, incorrect)}
        <input
            type="checkbox"
            defaultChecked={checked ? "checked" : ""}
            className={classes.hidden}
        />
    </div>
)

Checkbox.propTypes = {
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    incorrect: PropTypes.bool,
    color: PropTypes.oneOf(["green", "blue", "primary", "contrast"]),
    className: PropTypes.string,
    error: PropTypes.bool,
    small: PropTypes.bool,
}

Checkbox.defaultProps = {
    onClick: null,
    checked: false,
    incorrect: false,
    color: "green",
    className: null,
    error: false,
    small: false,
}

export default flow([withStyles(styles), React.memo])(Checkbox)
