import get from "lodash/get"

const styles = theme => ({
    root: {
        position: "relative",
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        // justifyContent: "center",
        flex: "1 100%",
        flexWrap: "wrap",
        flexDirection: "column",
        margin: "0 auto",
        color: get(theme, "appicalTheme.white"),
        backgroundColor: get(theme, "appicalTheme.error"),
        overflow: "hidden",
    },

    content: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        maxWidth: "380px",
        padding: `0 ${get(theme, "appicalTheme.spacing.smallest")}`,
        margin: `96px auto ${get(theme, "appicalTheme.spacing.medium")}`,

        [theme.breakpoints.up("sm")]: {
            maxWidth: "480px",
        },
    },

    title: {
        flex: "1 100%",
        maxWidth: "100%",
        padding: `0 ${get(theme, "appicalTheme.spacing.smallest")}`,
        textAlign: "center",
        marginTop: "120px",
        color: get(theme, "appicalTheme.black20"),
        fontSize: "64px",
        fontWeight: "bold",
    },

    subheading: {
        flex: "1 100%",
        textAlign: "center",
        marginBottom: `${theme.spacing(3)}px`,
        color: "inherit",

        "&:nth-child(3)": {
            marginBottom: `${theme.spacing(6)}px`,
        },
    },

    warningIcon: {
        color: "inherit",
        textAlign: "center",
        marginBottom: `${theme.spacing(2)}px`,

        "& svg": {
            fill: "currentColor",
        },
    },

    gear: {
        position: "absolute",
    },

    gear1: {
        extend: "gear",
        bottom: "0",
        right: "0",
        transform: "translate(40%, 60%)",
        fill: get(theme, "appicalTheme.white"),
        height: "416px",
        width: "416px",
        zIndex: 4,
    },

    gear2: {
        extend: "gear",
        bottom: "96px",
        right: "40px",
        fill: get(theme, "appicalTheme.black"),
        opacity: "0.20",
        height: "147px",
        width: "147px",
        zIndex: 1,
    },

    gear3: {
        extend: "gear",
        bottom: "0",
        left: "0",
        transform: "translate(-20%, 20%)",
        fill: get(theme, "appicalTheme.black"),
        opacity: "0.5",
        height: "210px",
        width: "210px",
        zIndex: 2,
    },

    gear4: {
        extend: "gear",
        bottom: "0",
        left: "0",
        transform: "translate(-60%, 50%)",
        fill: "#BD3B3B",
        height: "556px",
        width: "556px",
        zIndex: 3,
    },

    gear5: {
        extend: "gear",
        bottom: "0",
        right: "0",
        transform: "translate(70%, 20%)",
        fill: "#8A1818",
        height: "310px",
        width: "310px",
        zIndex: 3,
    },
})

export default styles
