import { createStyles, StyleRules, Theme } from "@material-ui/core"
import { fade } from "@material-ui/core/styles/colorManipulator"

const styles = (theme: Theme): StyleRules =>
    createStyles({
        root: {
            position: "relative",
            display: "flex",
            justifyContent: "center",
            flex: "0 auto",
            backfaceVisibility: "hidden",
        },

        label: {
            flex: "0 auto",
            display: "inline-flex",
            whiteSpace: "nowrap",
        },

        loader: {
            marginLeft: theme.spacing(),
        },

        smallest: {
            "padding": theme.spacing(),
            "minWidth": 0,

            "& span": {
                margin: 0,
            },
        },

        small: {
            minWidth: "128px",
            fontSize: "14px",
            lineHeight: "16px",
            padding: `${theme.spacing()}px ${theme.spacing(3)}px`,
        },

        medium: {
            minWidth: "180px",
            fontSize: "14px",
            lineHeight: "18px",
            padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
        },

        large: {
            minWidth: "360px",
            fontSize: "16px",
            lineHeight: "20px",
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        },

        rightIcon: {
            flex: "0 auto",
        },

        rounded: {
            borderRadius: "25px",
        },

        helperText: {
            position: "absolute",
            top: "50%",
            right: `-${theme.spacing()}px`,
            transform: "translate(100%, -50%)",
            display: "flex",
            alignItems: "center",
            width: "100%",
            textAlign: "center",
            marginLeft: theme.spacing(),
            color: fade(theme?.palette?.primary?.contrastText, 0.5),
        },

        success: {
            "extend": "base",

            "backgroundColor": theme?.palette?.success?.main,
            "color": theme?.palette?.success?.contrastText,

            "&$textVariant": {
                color: theme?.palette?.success?.main,
            },

            "&:disabled": {
                opacity: "0.7",
                cursor: "not-allowed",
            },

            "&:hover:enabled": {
                "backgroundColor": theme?.palette?.success?.dark,
                // Reset on touch devices
                "@media (hover: none)": {
                    backgroundColor: theme?.palette?.success?.main,
                },

                "&$textVariant": {
                    color: theme?.palette?.success?.dark,
                },
            },

            "&:hover:active:enabled": {
                "backgroundColor": theme?.palette?.success?.dark,
                "color": theme?.palette?.success?.contrastText,
                "borderColor": theme?.palette?.success?.contrastText,

                "&$textVariant": {
                    color: theme?.palette?.success?.dark,
                },
            },
        },

        error: {
            "extend": "base",

            "backgroundColor": theme?.palette?.error?.main,
            "color": theme?.palette?.error?.contrastText,

            "&$textVariant": {
                color: theme?.palette?.error?.main,
            },

            "&:disabled": {
                opacity: "0.7",
                cursor: "not-allowed",
            },

            "&:hover:enabled": {
                "backgroundColor": theme?.palette?.error?.dark,
                // Reset on touch devices
                "@media (hover: none)": {
                    backgroundColor: theme?.palette?.error?.main,
                },

                "&$textVariant": {
                    color: theme?.palette?.error?.dark,
                },
            },

            "&:hover:active:enabled": {
                "backgroundColor": theme?.palette?.error?.dark,
                "color": theme?.palette?.error?.contrastText,
                "borderColor": theme?.palette?.error?.contrastText,

                "&$textVariant": {
                    color: theme?.palette?.error?.dark,
                },
            },
        },

        textVariant: {
            "background": "transparent",

            "&:hover:enabled": {
                background: "transparent",
            },

            "&:hover:active:enabled": {
                background: "transparent",
            },
        },
    })

export default styles
