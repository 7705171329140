import { createActionName } from "../../../utility"

export const reducerName = "messageTemplates"

export const MESSAGE_TEMPLATES = createActionName(
    reducerName,
    "MESSAGE_TEMPLATES",
)
export const MESSAGE_TEMPLATE = createActionName(
    reducerName,
    "MESSAGE_TEMPLATE",
)
