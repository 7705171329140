import { connect } from "react-redux"
import { selectIsLoggedIn } from "redux/reducers/core/authentication"

import { createLoadUserByInviteAction as loadUserWithInvite } from "redux/reducers/entities/users/actions"
import {
    selectIsLoading as selectIsLoadingUser,
    selectLoggedInUser,
    selectHasFetchedUser,
    selectLoadingError,
} from "redux/reducers/entities/users"

const mapDispatchToProps = {
    loadUserWithInvite,
}

const mapStateToProps = state => ({
    isLoggedIn: selectIsLoggedIn(state),
    isLoadingUserWithInvite: selectIsLoadingUser(state),
    hasFetchedUserWithInvite: selectHasFetchedUser(state),
    loadingUserWithInviteError: selectLoadingError(state),
    user: selectLoggedInUser(state),
})

export default connect(mapStateToProps, mapDispatchToProps)
