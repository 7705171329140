const exceptions = {
    noResponse: "ServerNoResponse",
    noFreeSeats: "NoFreeSeatsException",

    invalidRegionException: "InvalidRegionException",

    // authentication
    invalidCredentials: "InvalidCredentials",
    passwordIncorrect: "PasswordIncorrectException",
    passwordTooOld: "PasswordTooOld",
    tooManyAttempts: "TooManyFailedAttempts",

    // session
    sessionNotFound: "SessionNotFoundException",
    sessionSignOut: "SessionSignedOutException",
    sessionExipired: "SessionExpiredException",

    // invites
    acceptedInvite: "InvalidInviteState_Accepted",
    expiredInvite: "InvalidInviteState_Expired",
    revokedInvite: "InvalidInviteState_Revoked",
    archivedInvite: "InvalidInviteState_Archived",
    declinedInvite: "InvalidInviteState_Declined",

    // user
    userNotFound: "UserNotFoundException",
    emailAlreadyRegistered: "EmailAlreadyRegisteredException",
    userAlreadyAssignedThatJourneyException:
        "UserAlreadyAssignedThatJourneyException",

    // embed
    embedlyServiceNotAvailable: "EmbedlyServiceNotAvailable",

    // stripe
    invalidVat: "tax_id_invalid",
    invalidAddress: "address_line1_check",
    invalidZip: "address_zip_check",
    incorrectCvc: "incorrect_cvc",
    expiredCard: "expired_card",
    cardDeclined: "card_declined",
    processingError: "processing_error",
    incorrectCardNumber: "incorrect_number",
    riskLevel: "risk_level",

    // companies
    companyNameAlreadyExists: "CompanyNameAlreadyExistsException",
    companyEnvironmentNameAlreadyExists:
        "CompanyEnvironmentNameAlreadyExistsException",

    // files
    invalidFileType: "InvalidFileTypeException",

    // prerequisites
    circularWorkflow:
        "CircularWorkflowException" /** @description when the prerequisite created would create a loop */,
    targetAndSourceSameStep:
        "TargetAndSourceSameStepException" /** @description when the prerequisite created would create a loop */,
    targetNotInJourney:
        "TargetNotInJourneyException" /** @description same as prerequisiteItemNotFound */,
    sourceNotInJourney:
        "SourceNotInJourneyException" /** @description same as prerequisiteItemNotFound */,
    prerequisiteItemNotFound: "PrerequisiteItemNotFoundException",
    invalidPrerequisiteDefinition:
        "InvalidPrerequisiteDefinitionException" /** @description weird rules, like using message as source, or creating "When todo is started" */,
    targetPrerequisiteConflict: "TargetPrerequisiteConflictException",
    sourcePrerequisiteConflict: "SourcePrerequisiteConflictException",
    sourceLowerThanTarget: "SourceAfterTargetException",
    targetHigherThanSource: "TargetHigherThanSourceException",

    // delete
    itemIsInUse: "ItemIsInUseException",

    // pages
    textTooLong: "text_too_long",

    // roles
    notCorrectRole: "NotCorrectRoleException",
    invalidUserRolesException: "InvalidUserRolesException",
    userHasActiveOrPendingManagerRoleException:
        "UserHasActiveOrPendingManagerRoleException",

    agreeToPolicies: "AgreeToPoliciesException",

    // integrations
    invalidToken: "IntegrationCustomVariableNotValidException",
}

export default exceptions
