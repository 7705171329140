import { createSelector } from "reselect"
import { outerJoin } from "../../../../lib/utils/helpers/data-sets"

import { createReducer } from "../../../utility"
import { createEntitiesFeatureSelector } from "../helpers"

import * as selectors from "./selectors"
import { initialState, reducers } from "./reducers"
import { reducerName } from "./actionNames"

import { selectSelectedFolder } from "../folders"

import { extractFiltersFromFiles } from "./helpers"

export * from "./reducers"
export * from "./schema"

// selectors
export const selectFeature = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)
export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)
export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)
export const selectHasFetched = createSelector(
    selectFeature,
    selectors.selectHasFetched,
)
export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)
export const selectSelectedHash = createSelector(
    selectFeature,
    selectors.selectSelectedHash,
)

export const selectFiles = createSelector(selectFeature, selectors.selectFiles)

export const selectFolderFileHashes = createSelector(
    selectIdentifiers,
    selectSelectedFolder,
    (identifiers, folder) => (folder ? folder.fileHashes : identifiers),
)

export const selectFolderFiles = createSelector(
    selectFolderFileHashes,
    selectData,
    outerJoin,
)

export const selectFileFilters = createSelector(
    selectFolderFiles,
    extractFiltersFromFiles,
)

export const makeSelectFileByHash = () =>
    createSelector(
        selectData,
        (_, hash) => hash,
        (files, fileHash) => files?.[fileHash],
    )

export const makeSelectFilesByHashes = () =>
    createSelector(
        selectData,
        (state, hashes) => hashes,
        (files, hashes) =>
            hashes?.reduce((result, fileHash) => {
                if (files[fileHash]) {
                    result.push(files[fileHash])
                }

                return result
            }, []),
    )

export const reducer = createReducer(reducers, initialState)
