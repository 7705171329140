import React from "react"
import { Trans, WithTranslation, withTranslation } from "react-i18next"
import { compose } from "redux"
import classNames from "classnames"
import { Link } from "react-router-dom"

import { WithStyles, withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import { isPremise } from "constants/internal"
import { userStates } from "constants/enums"
import { UserStates } from "lib/utils/types/users"

import Appical from "lib/views/Icons/Companies/Appical"

import styles from "./styles"

interface WelcomeToProps extends WithTranslation, WithStyles<typeof styles> {
    className?: string
    companyName?: string
    companyLogoUrl?: string
    userState?: UserStates
    screenType?: "login" | "signup" | "invite"
}

const WelcomeTo: React.VFC<WelcomeToProps> = ({
    classes,
    t,
    className,
    companyName,
    companyLogoUrl,
    userState,
    screenType,
}: WelcomeToProps) => {
    if (userState === userStates.active || screenType === "login") {
        return (
            <div className={classNames(classes.root, className)}>
                <div className={classes.logoContainer}>
                    {companyLogoUrl ? (
                        <img
                            className={classes.logo}
                            src={companyLogoUrl}
                            alt={`${companyName} logo`}
                        />
                    ) : (
                        <Appical color="currentColor" width={80} height={80} />
                    )}
                </div>
                <div className={classes.contentContainer}>
                    {screenType === "invite" ? (
                        <Typography
                            variant="h4"
                            className={classNames(
                                classes.companyName,
                                classes.title,
                            )}
                        >
                            {t("main:log_in_from_invite_title", {
                                companyName,
                            })}
                        </Typography>
                    ) : (
                        <React.Fragment>
                            <Typography
                                variant="h6"
                                className={classes.aboveTitle}
                            >
                                {t("main:welcome_to")}
                            </Typography>
                            <Typography
                                variant="h4"
                                className={classNames(
                                    classes.companyName,
                                    classes.title,
                                )}
                            >
                                {companyName || (
                                    <React.Fragment>
                                        Appical.
                                        <Typography
                                            component="span"
                                            variant="h4"
                                            color="secondary"
                                        >
                                            io
                                        </Typography>
                                    </React.Fragment>
                                )}
                            </Typography>
                        </React.Fragment>
                    )}
                    {!isPremise &&
                        userState !== userStates.active &&
                        !companyName && (
                            <Typography
                                className={classes.subtitle}
                                variant="body1"
                            >
                                <Trans i18nKey="main:no_account">
                                    <Link
                                        className={classes.link}
                                        to="/register"
                                    >
                                        Create Account
                                    </Link>
                                </Trans>
                            </Typography>
                        )}
                </div>
            </div>
        )
    }

    return (
        <div className={classNames(classes.root, className)}>
            <div className={classes.logoContainer}>
                {companyLogoUrl ? (
                    <img
                        className={classes.logo}
                        src={companyLogoUrl}
                        alt={`${companyName} logo`}
                    />
                ) : (
                    <Appical color="currentColor" width={80} height={80} />
                )}
            </div>
            <div className={classes.contentContainer}>
                <Typography className={classes.title} variant="h4">
                    {screenType === "invite"
                        ? t("main:create_user_from_invite_title", {
                              companyName,
                          })
                        : t("main:create_user_title")}
                </Typography>
                {!companyName && (
                    <Typography className={classes.subtitle} variant="body1">
                        <Trans i18nKey="main:have_account">
                            <Link className={classes.link} to="/login">
                                Login
                            </Link>
                        </Trans>
                    </Typography>
                )}
            </div>
        </div>
    )
}

WelcomeTo.defaultProps = {
    className: "",
    companyName: "",
    companyLogoUrl: "",
    userState: userStates.draft,
    screenType: "login",
}

export default compose(
    withTranslation(),
    withStyles(styles, { name: "WelcomeTo" }),
    React.memo,
)(WelcomeTo)
