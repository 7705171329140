import client from "configure/client"
import { connect } from "react-redux"
import { addTokenToRequest } from "redux/middleware/helpers"

const mapDispatchToProps = {
    log: ({ message, exception }) => (_, getState) => {
        const loggingRequest = addTokenToRequest(getState(), {
            method: "POST",
            url: "/editor/Logging/Log",
            data: {
                message,
                exception,
            },
        })

        return client.request(loggingRequest)
    },
}

export default connect(null, mapDispatchToProps)
