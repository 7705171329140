import get from "lodash/get"
import flatMap from "lodash/flatMap"
import keys from "lodash/keys"

import exceptions from "constants/exceptions"
import { CREATE, DELETE, SELECT, UPDATE } from "redux/utility"
import { LOADING, LOADED, ERROR } from "redux/middleware/actionNames"
import {
    RequestPayload,
    RequestPayloadWithError,
} from "redux/reducers/entities/types"

import { LOGOUT } from "redux/reducers/core/authentication/actionNames"
import { COMPANY } from "redux/reducers/entities/companies/actionNames"
import {
    USER,
    USERS,
    USER_ROLES,
} from "redux/reducers/entities/users/actionNames"
import { PLAN } from "redux/reducers/entities/plans/actionNames"

import { INVITE, INVITE_RESEND } from "./actionNames"
import { mapToListInvite } from "./helpers"
import normalize from "./schema"
import { selectData, selectIdentifiers, selectLoadingError } from "./selectors"
import { InviteRequestPayload, InviteState } from "./types"
import { UserRequestPayload } from "../users/types"

export const initialState: InviteState = {
    /* Holds the normalized invites */
    data: {},
    /* Holds the normalized invite identifiers */
    identifiers: [],
    /* Indicates whether or not invites have been fetched */
    hasFetched: false,
    /* Indicates whether actions are working on a invite */
    isLoading: false,
    /* Holds an error if the last action was erroneous  */
    loadingError: null,
    /* Holds the invite that is currently selected */
    selectedHash: "",
}

export const reducers = {
    [USERS + LOADING](state: InviteState): InviteState {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [USERS + LOADED](state: InviteState, payload: RequestPayload): InviteState {
        const { result } = payload || {}
        const invites = flatMap(result, "invites")
        const [identifiers, data] = normalize(invites)

        return {
            ...state,
            data,
            identifiers,
            isLoading: false,
            hasFetched: true,
        }
    },

    [USERS + ERROR](
        state: InviteState,
        payload: RequestPayloadWithError,
    ): InviteState {
        const { result: loadingError } = payload
        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError,
        }
    },

    [USER + LOADING](state: InviteState): InviteState {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [USER + LOADED](state: InviteState, payload: RequestPayload): InviteState {
        const { result } = payload || {}
        const oldData = selectData(state)
        const invites = get(result, "invites")
        const [, normizedInvites] = normalize(invites)
        const data = { ...oldData, ...normizedInvites }
        const identifiers = keys(data)

        return {
            ...state,
            data,
            identifiers,
            isLoading: false,
        }
    },

    [USER + ERROR](
        state: InviteState,
        payload: RequestPayloadWithError,
    ): InviteState {
        const { result: loadingError } = payload
        return {
            ...state,
            isLoading: false,
            loadingError,
        }
    },

    [INVITE + CREATE + LOADING](state: InviteState): InviteState {
        return { ...state, isLoading: true, loadingError: null }
    },

    [INVITE + CREATE + LOADED](
        state: InviteState,
        payload: InviteRequestPayload,
    ): InviteState {
        const {
            result,
            originalPayload: { type, role, journeyName, journeyHash },
        } = payload

        const invite = mapToListInvite(
            result,
            journeyName,
            journeyHash,
            type,
            role,
        )

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [invite.inviteHash]: invite,
            },
            identifiers: [...state.identifiers, invite.inviteHash],
        }
    },

    [INVITE + CREATE + ERROR](
        state: InviteState,
        payload: RequestPayloadWithError,
    ): InviteState {
        const { result: loadingError } = payload
        return { ...state, isLoading: false, loadingError }
    },

    [INVITE + DELETE + LOADING](state: InviteState): InviteState {
        return { ...state, isLoading: true, loadingError: null }
    },

    [INVITE + DELETE + LOADED](
        state: InviteState,
        payload: RequestPayload,
    ): InviteState {
        const { originalPayload: inviteHash } = payload

        const data = { ...selectData(state) }
        const identifiers = selectIdentifiers(state).filter(
            hash => hash !== inviteHash,
        )

        if (typeof inviteHash === "string") {
            delete data[inviteHash]
        }

        return {
            ...state,
            isLoading: false,
            data,
            identifiers,
        }
    },

    [INVITE + DELETE + ERROR](
        state: InviteState,
        payload: RequestPayloadWithError,
    ): InviteState {
        const { result: loadingError } = payload
        return { ...state, isLoading: false, loadingError }
    },

    [INVITE_RESEND + LOADING](state: InviteState): InviteState {
        return { ...state, isLoading: true, loadingError: null }
    },

    [INVITE_RESEND + LOADED](state: InviteState): InviteState {
        return { ...state, isLoading: false }
    },

    [INVITE_RESEND + ERROR](
        state: InviteState,
        payload: RequestPayloadWithError,
    ): InviteState {
        const { result: loadingError } = payload

        return { ...state, isLoading: false, loadingError }
    },

    [INVITE + SELECT](
        state: InviteState,
        payload: RequestPayload,
    ): InviteState {
        const { selectedHash } = payload

        if (!selectedHash) return state

        return { ...state, selectedHash }
    },

    [USER + DELETE + LOADED](
        state: InviteState,
        payload: UserRequestPayload,
    ): InviteState {
        const { originalPayload } = payload

        if (typeof originalPayload !== "object") return state

        const user = originalPayload?.user
        const identifiers = selectIdentifiers(state)
        const data = { ...selectData(state) }
        const loadingError = selectLoadingError(state)

        user.inviteHashes.forEach(hash => {
            delete data[hash]
        })

        return {
            ...state,
            identifiers: identifiers.filter(
                id => !user.inviteHashes.includes(id),
            ),
            data,
            loadingError:
                loadingError?.Reason === exceptions.noFreeSeats
                    ? null
                    : loadingError,
        }
    },

    [USER_ROLES + DELETE + LOADED](
        state: InviteState,
        payload: InviteRequestPayload,
    ): InviteState {
        const {
            originalPayload: { inviteHash },
        } = payload
        const identifiers = selectIdentifiers(state).filter(
            id => !(inviteHash === id),
        )
        const data = { ...selectData(state) }

        if (typeof inviteHash === "string") {
            delete data[inviteHash]
        }

        return {
            ...state,
            identifiers,
            data,
        }
    },

    [PLAN + UPDATE + LOADED](state: InviteState): InviteState {
        return { ...state, loadingError: null }
    },

    [COMPANY + SELECT](): InviteState {
        return initialState
    },

    [LOGOUT + LOADED](): InviteState {
        return initialState
    },
}
