import { environmentTypes } from "./environment"

const awsBucketForEnvironment = () => {
    const { REACT_APP_AWS_BUCKET, REACT_APP_ENV } = process.env

    if (REACT_APP_AWS_BUCKET) return REACT_APP_AWS_BUCKET

    switch (REACT_APP_ENV) {
        case environmentTypes.dev:
            return "user-data-global-dropbox"
        case environmentTypes.translation:
        case environmentTypes.uat:
            return "aio-uat-dropbox"
        case environmentTypes.demo:
            return "aio-demo-dropbox"
        case environmentTypes.prod:
            return "aio-dropbox"
        default:
            return "user-data-global-dropbox"
    }
}

const awsFileLambdaForEnvironment = () => {
    const { REACT_APP_AWS_FILE_LAMBDA, REACT_APP_ENV } = process.env

    if (REACT_APP_AWS_FILE_LAMBDA) return REACT_APP_AWS_FILE_LAMBDA

    switch (REACT_APP_ENV) {
        case environmentTypes.dev:
            return "https://1sm4ikel0a.execute-api.eu-central-1.amazonaws.com/default/AIO_EC2_DownloadFile"
        case environmentTypes.translation:
        case environmentTypes.uat:
            return "https://1sm4ikel0a.execute-api.eu-central-1.amazonaws.com/default/AIO_UAT_DownloadFile"
        case environmentTypes.demo:
            return "https://1sm4ikel0a.execute-api.eu-central-1.amazonaws.com/default/AIO_DEMO_DownloadFile"
        case environmentTypes.prod:
            return "https://1favj9c7vk.execute-api.eu-central-1.amazonaws.com/default/AIO_EC2_DownloadFile"
        default:
            return "https://1sm4ikel0a.execute-api.eu-central-1.amazonaws.com/default/AIO_EC2_DownloadFile"
    }
}

export default {
    uploadBucket: awsBucketForEnvironment(),
    serviceUrl:
        process.env.REACT_APP_AWS_SERVICE_URL ||
        "https://s3.eu-central-1.amazonaws.com",
    externalFileLambdaUrl: awsFileLambdaForEnvironment(),
}
