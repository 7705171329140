import ReactGA from "react-ga"
import constants from "../constants/analytics"

const trackEvent = (category, action) => {
    ReactGA.event({
        category: constants.categories[category],
        action: constants.events[action],
    })
}

export default trackEvent
