import { DUTCH, ENGLISH, SPANISH } from "configure/i18n"
import { serverLanguages } from "./enums"

export const serverLanguagesMap = {
    [serverLanguages.english]: ENGLISH,
    [serverLanguages.dutch]: DUTCH,
    [serverLanguages.spanish]: SPANISH,
}

export const languages = [serverLanguages.english, serverLanguages.dutch]
