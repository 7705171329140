import { schema, normalize } from "normalizr"

export const userSchema = new schema.Entity(
    "relations",
    {},
    { idAttribute: "hash" },
)

export default originalData => {
    const {
        result: keys,
        entities: { relations: normalizedData },
    } = normalize(originalData, [userSchema])

    return [keys || [], normalizedData || {}]
}
