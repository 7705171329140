import reduce from "lodash/reduce"
import partial from "lodash/partial"
import moment from "moment"

import { roleStates, userRoles } from "constants/enums"

import {
    mapRoleInvites,
    mapJourneyInvites,
    mapInviteData,
} from "redux/mappers/users/helpers"

export const mergeUserData = (staff, talents, journeyData) => {
    const users = new Map()

    reduce(
        staff,
        (userMap, { user, userRoleInCompanyModel }) => {
            let userData = userMap.get(user.userHash)
            const { imageUrl, ...userDetails } = user
            const userModel = {
                ...userDetails,
                avatarImageUrl: imageUrl,
            }

            if (!userData) {
                userMap.set(
                    user.userHash,
                    (userData = { user: userModel, invites: [] }),
                )
            }

            const invites = mapRoleInvites(userRoleInCompanyModel)
            userData.invites.push(...invites)

            return userMap
        },
        users,
    )

    reduce(
        talents,
        (userMap, { user, userInJourneys }) => {
            let userData = userMap.get(user.userHash)
            const { imageUrl, ...userDetails } = user
            const userModel = {
                ...userDetails,
                avatarImageUrl: imageUrl,
            }

            if (!userData) {
                userMap.set(
                    user.userHash,
                    (userData = { user: userModel, invites: [] }),
                )
            }

            const invites = mapJourneyInvites(userInJourneys, journeyData)
            userData.invites.push(...invites)

            return userMap
        },
        users,
    )

    return Array.from(users.values()).map(partial(mapInviteData, journeyData))
}

export const mapInviteOntoExisting = (user, invite, journeyName, role) => {
    const { inviteHash, inviteStatus: status } = invite

    return {
        ...user,
        inviteHashes: [...user.inviteHashes, inviteHash],
        statuses: [...user.statuses, status],
        roles: user.roles.includes(role) ? user.roles : [...user.roles, role],
        journeys: journeyName ? [...user.journeys, journeyName] : user.journeys,
    }
}

export const mapInviteToNew = (invite, journeyName, role, journeyHash) => {
    const {
        inviteHash,
        userHash,
        firstName,
        lastName,
        email: emailAddress,
        inviteStatus: status,
        inviteDate,
    } = invite

    return {
        userHash,
        firstName,
        lastName,
        emailAddress,
        invitedOn: moment.utc(inviteDate).toDate(),
        roles: [role],
        statuses: [status],
        inviteHashes: [inviteHash],
        journeyHashes: [journeyHash],
        journeys: journeyName ? [journeyName] : [],
        userRelations: [],
    }
}

export const mapToStripeRequestDetails = (user, companyHash) => ({
    currency: "eur",
    statement_descriptor: "Appical.io Platform",
    owner: {
        name: user && `${user?.firstName} ${user?.lastName}`,
        email: user?.emailAddress,
    },
    metadata: {
        companyHash,
        userHash: user?.userHash,
    },
})

export const mapCurrentJourneyTalentResult = (talents = [], journeyHash) =>
    talents.map(user => ({
        ...user,
        roles: [userRoles.talent],
        journeyHashes: [journeyHash],
    }))

const removeSpecialChars = str =>
    // eslint-disable-next-line no-useless-escape
    str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")

export const searchWith = (searchTerm, stringToTest) => {
    const pattern = new RegExp(removeSpecialChars(searchTerm), "i")
    const nameSearchTest = pattern.test(stringToTest)

    return nameSearchTest
}

/**
 * @name isRoleActive
 * @description identifies if the role is an active role
 * @param {object} role | it receives a role object from a company
 * @param {number} activeJourneyCount | number of active journey in the company
 * @returns {boolean} | whether or not that role is active
 */
export function isRoleActive({ role, status }, activeJourneyCount) {
    return (
        status === roleStates.active &&
        (role !== userRoles.talent ||
            (role === userRoles.talent && !!activeJourneyCount))
    )
}
