/* eslint-disable import/prefer-default-export */

export const convertColorToMui = color => {
    switch (color) {
        case "error":
            return "default"
        case "success":
            return "default"

        default:
            return color
    }
}
