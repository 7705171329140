import moment from "moment"
import get from "lodash/get"
import map from "lodash/map"
import groupBy from "lodash/groupBy"
import uniq from "lodash/uniq"
import reduce from "lodash/reduce"

import { userType } from "constants/internal"
import { userRoles, userJourneyStatus, inviteStatus } from "constants/enums"

const stateWeights = {
    [inviteStatus.accepted]: 2,
    [inviteStatus.pending]: 2,
    [inviteStatus.revoked]: 1,
    [inviteStatus.expired]: 1,
    [inviteStatus.declined]: 1,
}

const determineInviteDate = invites =>
    reduce(
        invites,
        ([date, weight], { inviteDate: dateString, inviteState }) => {
            const nextWeight = get(stateWeights, inviteState)
            if (weight > nextWeight) return [date, weight]

            const inviteDate = moment.utc(dateString).toDate()
            return inviteDate < date ? [inviteDate, nextWeight] : [date, weight]
        },
        [new Date(), 0],
    )[0]

export const mapInviteData = (journeyData, userData) => {
    const { user, invites } = userData
    const groupedInvites = groupBy(invites, "type")
    const talentInvites = get(groupedInvites, userType.talent, [])
    const staffInvites = get(groupedInvites, userType.staff, [])

    const roles = uniq(map(staffInvites, "userRoleType"))
    if (talentInvites.length) roles.push(userRoles.talent)

    Object.assign(user, {
        invitedOn: determineInviteDate(invites),
        statuses: map(invites, "inviteState"),
        journeyStatuses: map(invites, "userJourneyState"),
        inviteHashes: map(
            invites,
            ({ inviteHash, userRoleType }) => inviteHash || userRoleType,
        ),
        journeyHashes: map(talentInvites, "journeyHash"),
        /**
         * @todo remove journeys and add them in selector
         * UserTableRow is using this property
         */
        journeys: talentInvites.reduce((result, current) => {
            if (
                current.userJourneyState === userJourneyStatus.active ||
                current.userJourneyState === userJourneyStatus.draft
            ) {
                result.push(
                    current.journeyName ||
                        get(journeyData, `${current.journeyHash}.name`),
                )
            }
            return result
        }, []),
        roles,
    })

    return userData
}

/**
 * @name mapJourneyInvites
 * @description Internal map to update the journey invite data to our standard
 * @param {array} invites       | array of journey invites
 * @param {object} journeyData  | object containing all the journey data
 * @returns {array} Updated invites
 */
export const mapJourneyInvites = (invites, journeyData) =>
    invites.map(invite => {
        const newInvite = { ...invite }

        newInvite.type = userType.talent
        // eslint-disable-next-line no-param-reassign
        newInvite.journeyName = get(journeyData, `${invite.journeyHash}.name`)

        return newInvite
    })

/**
 * @name mapRoleInvites
 * @description Internal map to update the role invite data to our standard
 * @param {array} invites       | array of journey invites
 * @returns {array} Updated invites
 */
export const mapRoleInvites = invites =>
    invites.map(invite => ({
        ...invite,
        type: userType.staff,
    }))

/**
 * @name mapUserRoleInvite
 * @description maps the value of userRoleInCompany to the one sent in the user list
 * @param {object} userRoleInCompanyDetail
 */
const mapUserRoleInvite = userRoleInCompany => {
    const userRoleType = get(userRoleInCompany, "userRoleType")
    const invite = get(userRoleInCompany, "invite", {})

    return {
        ...userRoleInCompany,
        userRoleType,
        ...invite,
    }
}

/**
 * @name mapUserForMerging
 * @description Map the data from createLoadUserAction to match the one received in user list
 * @param {object} userModel                | object containing the basic user data
 * @param {array} userInJourneyDetail       | list of journey invites for user
 * @param {array} userRoleInCompanyDetail   | list of roles invites for user
 * @returns {object} updated user based on the response of the user list endpoints (Staff & Talents)
 */
export const mapUserForMerging = (
    user,
    userInJourneyDetail,
    userRoleInCompanyDetail,
) => {
    const userRoleInCompanyModel = map(
        userRoleInCompanyDetail,
        mapUserRoleInvite,
    )
    const userInJourneys = userInJourneyDetail

    return {
        user,
        userRoleInCompanyModel,
        userInJourneys,
        invites: [],
    }
}
