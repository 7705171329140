import reduce from "lodash/reduce"
import get from "lodash/get"

import { userJourneyStatus } from "constants/enums"

export const mapToListInvite = (
    { inviteHash, inviteDate, inviteStatus },
    journeyName,
    journeyHash,
    type,
    role,
) => ({
    inviteHash,
    inviteDate,
    inviteState: inviteStatus,
    userRoleType: role,
    journeyName,
    journeyHash,
    type,
    userJourneyState: userJourneyStatus.draft,
})

export const cleanEventData = (events, eventData) =>
    reduce(
        events,
        (data, { hash }) =>
            Object.assign(data, { [hash]: get(eventData, hash) }),
        {},
    )

export const hasAnotherJourneyInvite = (
    journeyHash,
    userJourneyState,
    inviteDate,
) => ({
    journeyHash: secondJourneyHash,
    userJourneyState: secondUserJourneyState,
    inviteDate: secondInviteDate,
}) =>
    journeyHash === secondJourneyHash &&
    (secondUserJourneyState === userJourneyStatus.active ||
        secondUserJourneyState === userJourneyStatus.draft ||
        inviteDate < secondInviteDate) &&
    userJourneyState === userJourneyStatus.archived
