import get from "lodash/get"
import { fade } from "@material-ui/core/styles/colorManipulator"

const styles = theme => ({
    formControl: {
        width: "100%",
    },

    adornment: {
        backgroundColor: "inherit",
    },

    checkboxes: {
        paddingTop: get(theme, "appicalTheme.spacing.gap"),
    },

    strengthLabel: {
        marginTop: "2px",
        fontSize: "10px",
        color: fade(get(theme, "palette.primary.contrastText"), 0.7),
    },

    iconValid: {
        width: get(theme, "appicalTheme.spacing.smallest"),
        height: get(theme, "appicaltheme.spacing.smallest"),
        opacity: ".7",
        fill: get(theme, "appicalTheme.success"),
    },

    iconInvalid: {
        width: get(theme, "appicalTheme.spacing.smallest"),
        height: get(theme, "appicaltheme.spacing.smallest"),
        opacity: ".7",
        fill: get(theme, "appicalTheme.error"),
    },

    iconInformation: {
        // fill: get(theme, "appicalTheme.black35"),
    },

    iconVisibility: {
        // fill: get(theme, "appicalTheme.black35"),
    },

    infoDialogTitle: {
        padding: get(theme, "appicalTheme.spacing.gap"),
        paddingBottom: "0",
    },

    first: {
        backgroundColor: get(theme, "appicalTheme.error"),
    },

    second: {
        backgroundColor: "#ff7700",
    },

    third: {
        backgroundColor: "#f39e15",
    },

    thirdText: {
        color: "#f39e15",
    },

    fourth: {
        backgroundColor: "#ffe62b",
    },

    last: {
        backgroundColor: get(theme, "appicalTheme.success"),
    },

    lastText: {
        color: get(theme, "appicalTheme.success"),
    },

    visibilityButton: {
        "&, &:hover, &:active": {
            backgroundColor: "transparent !important",
            color: get(theme, "palette.primary.contrastText"),
        },
    },

    checkBoxesRoot: {
        display: "flex",
        flexDirection: "column",
    },

    textField: {
        marginBottom: get(theme, "appicalTheme.spacing.gap"),
    },

    touched: {
        "&:before": {
            borderColor: get(theme, "appicalTheme.default"),
        },
    },

    errorContainer: {
        display: "flex",
        alignItems: "center",
    },

    error: {
        fontWeight: "400",
        color: get(theme, "palette.error.main"),
    },
})

export default styles
