import { createActionName } from "../../../utility"

export const reducerName = "companies"

// Actions
export const COMPANY = createActionName(reducerName, "COMPANY")
export const COMPANIES = createActionName(reducerName, "COMPANIES")
export const SET_COMPANIES = createActionName(reducerName, "SET_COMPANIES")
export const SELECT_COMPANY = createActionName(reducerName, "SELECT_COMPANY")
export const BILLING_INFO = "_BILLING_INFO"
export const PAYMENT_INFO = "_PAYMENT_INFO"
export const SEAT_COUNT = "_SEAT_COUNT"
export const TRANSFER_OWNERSHIP = "_TRANSFER_OWNERSHIP"
