import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"
import { withTranslation } from "react-i18next"
import { compose } from "redux"

import withStyles from "@material-ui/core/styles/withStyles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"

import Button from "lib/views/Button/Button"

import connect from "./connect"
import styles from "./styles"

const PolicyAgreementDialog = ({
    t,
    classes,
    hasFetchedPolicies,
    isLoadingPolicies,
    fetchAllPolicies,
    hasUserAgreedToAllPolicies,
    policy,
    logout,
    agreeToPolicy,
}) => {
    useEffect(() => {
        if (!hasFetchedPolicies && !hasUserAgreedToAllPolicies) {
            fetchAllPolicies()
        }
    }, [hasFetchedPolicies, hasUserAgreedToAllPolicies, fetchAllPolicies])

    const onAccept = useCallback(() => {
        agreeToPolicy(policy?.policyId)
    }, [agreeToPolicy, policy])

    return (
        <Dialog
            classes={{ paper: classes.root }}
            fullScreen={isMobile}
            open={!hasUserAgreedToAllPolicies && !!policy}
        >
            <DialogTitle disableTypography className={classes.title}>
                {t("main:policy_updated_title", { name: policy?.name })}
            </DialogTitle>

            <div className={classes.content}>
                <Typography variant="body1">
                    {t("main:policy_updated_text", {
                        name: policy?.name,
                        date: new Date(policy?.createdAt),
                    })}
                </Typography>

                <Link
                    color="secondary"
                    rel="noopener"
                    target="_blank"
                    href={policy?.url || "#"}
                >
                    {t("main:policy_updated_review", {
                        name: policy?.name,
                    })}
                </Link>

                <div className={classes.buttonGroup}>
                    <Button
                        size="medium"
                        color="secondary"
                        onClick={onAccept}
                        loader={isLoadingPolicies}
                        disabled={isLoadingPolicies}
                    >
                        {t("main:policy_updated_accept")}
                    </Button>

                    <Button
                        size="small"
                        color="secondary"
                        variant="text"
                        onClick={logout}
                        disabled={isLoadingPolicies}
                    >
                        {t("main:policy_updated_decline")}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

PolicyAgreementDialog.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.exact({
        root: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        buttonGroup: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,
    agreeToPolicy: PropTypes.func.isRequired,
    fetchAllPolicies: PropTypes.func.isRequired,
    policy: PropTypes.shape({
        policyId: PropTypes.number,
        name: PropTypes.string,
        url: PropTypes.string,
        createdAt: PropTypes.string,
    }),
    hasFetchedPolicies: PropTypes.bool,
    hasUserAgreedToAllPolicies: PropTypes.bool,
    isLoadingPolicies: PropTypes.bool,
}

PolicyAgreementDialog.defaultProps = {
    hasFetchedPolicies: false,
    hasUserAgreedToAllPolicies: true,
    isLoadingPolicies: false,
    policy: null,
}

export default compose(
    withTranslation(),
    withStyles(styles, { name: "PolicyAgreementDialog" }),
    connect,
    React.memo,
)(PolicyAgreementDialog)
