import { schema, normalize } from "normalizr"

export const userInviteSchema = new schema.Entity(
    "invites",
    {},
    { idAttribute: "inviteHash" },
)

export default originalData => {
    const {
        result: keys,
        entities: { invites: normalizedData },
    } = normalize(originalData, [userInviteSchema])

    return [keys || [], normalizedData || {}]
}
