import React from "react"
import Icon from "./Icon"

const title = "Tick"
const path = (
    <polygon id="Path" points="9 16.2 4.8 12 3.4 13.4 9 19 21 7 19.6 5.6" />
)

const Tick = props => <Icon {...props} title={title} path={path} />

export default React.memo(Tick)
