import keys from "lodash/keys"

export const noOp = () => null
export const emptyArray = []
export const emptyObject = {}

export const isEmpty = value => value === emptyObject

export const stringify = value => JSON.stringify(value)

export const merge = (existingData, newData) => {
    const returnData = { ...existingData }

    keys(newData).map(hash => {
        if (!returnData[hash]) {
            returnData[hash] = newData[hash]
        } else {
            returnData[hash] = { ...returnData[hash], ...newData[hash] }
        }

        return hash
    })

    return returnData
}
