import get from "lodash/get"
import { createSelector } from "reselect"

import { createReducer } from "../../../utility"
import { createEntitiesFeatureSelector } from "../helpers"
import { outerJoin } from "../../../../lib/utils/helpers/data-sets"

import * as selectors from "./selectors"
import { initialState, reducers } from "./reducers"
import { reducerName } from "./actionNames"

export * from "./reducers"
export * from "./schema"

// selectors
const selectFeature = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)
export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)
export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)
export const selectHasFetched = createSelector(
    selectFeature,
    selectors.selectHasFetched,
)
export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)
export const selectSelectedHash = createSelector(
    selectFeature,
    selectors.selectSelectedHash,
)

export const selectUserInvites = createSelector(
    selectIdentifiers,
    selectData,
    outerJoin,
)
export const selectSelectedUserInvite = createSelector(
    selectData,
    selectSelectedHash,
    get,
)
export const selectUserInviteCount = createSelector(
    selectUserInvites,
    invites => get(invites, "length", 0),
)
export const selectHasUserInvites = createSelector(
    selectUserInvites,
    userInvites => !!userInvites?.length,
)

export const reducer = createReducer(reducers, initialState)
