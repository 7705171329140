import { createActionName } from "redux/utility"

export const reducerName = "config"

export const APP_THEME = createActionName(reducerName, "APP_THEME")
export const LANGUAGE = createActionName(reducerName, "LANGUAGE")
export const SHOW_INHERITED_ROLES = createActionName(
    reducerName,
    "SHOW_INHERITED_ROLES",
)
export const HIDE_INHERITED_ROLES = createActionName(
    reducerName,
    "HIDE_INHERITED_ROLES",
)
