const styles = theme => ({
    checkboxContainer: {
        display: "flex",
        alignItems: "center",
    },

    disabled: {
        opacity: 0.5,
        pointerEvents: "none",
    },

    checkbox: {
        cursor: "pointer",
        marginRight: theme?.spacing(),
        outline: "none",
    },

    formLabel: {
        display: "inline-block",
        lineHeight: "19px",
        cursor: "pointer",
    },

    text: {
        color: theme?.palette?.primary?.contrastText,

        "& > span": {
            color: theme?.palette?.error?.main,
        },
    },

    helperText: {
        paddingLeft: `calc((${theme?.spacing(1.5)}px) + 24px)`,
    },
})

export default styles
