import get from "lodash/get"
import { createSelector } from "reselect"

import { outerJoin } from "lib/utils/helpers/data-sets"

import { RequestError } from "redux/reducers/entities/types"
import { EnvironmentState, Environment } from "./types"

export const selectData = (
    state: EnvironmentState,
): Record<string, Environment> => state?.data
export const selectIdentifiers = (state: EnvironmentState): string[] =>
    state?.identifiers
export const selectIsReady = (state: EnvironmentState): boolean =>
    state?.isReady
export const selectLoadingError = (
    state: EnvironmentState,
): RequestError | null | undefined => state?.loadingError
export const selectActiveName = (state: EnvironmentState): string =>
    state?.activeName
export const selectIsSubdomain = (state: EnvironmentState): boolean =>
    state?.isSubdomain

export const selectEnvironments = createSelector(
    selectIdentifiers,
    selectData,
    outerJoin,
)
export const selectActiveEnvironment = createSelector(
    selectData,
    selectActiveName,
    get,
)

export const selectActiveThemeHash = createSelector(
    selectActiveEnvironment,
    environment => environment?.themeHash,
)

export const selectActiveCompanyHash = createSelector(
    selectActiveEnvironment,
    environment => environment?.companyHash,
)
