import { RequestError } from "../types"
import { Invite, InviteState } from "./types"

export const selectData = (state: InviteState): Record<string, Invite> =>
    state?.data
export const selectIdentifiers = (state: InviteState): string[] =>
    state?.identifiers
export const selectIsLoading = (state: InviteState): boolean => state?.isLoading
export const selectHasFetched = (state: InviteState): boolean =>
    state?.hasFetched
export const selectLoadingError = (
    state: InviteState,
): RequestError | null | undefined => state?.loadingError
export const selectSelectedHash = (state: InviteState): string =>
    state?.selectedHash
