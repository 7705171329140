import { roleStates, userRoles } from "constants/enums"

import { UPDATE } from "redux/utility"

import { createInfoAction } from "../toasts/actions"

import { selectData, selectIdentifiers } from "."
import { USER_INVITE, USER_INVITES } from "./actionNames"

export const createLoadingAction = (callback = null) => ({
    type: USER_INVITES,
    request: {
        method: "GET",
        url: "/editor/User/Invites",
    },
    callback,
})

export const createAcceptInviteAction = inviteHash => (dispatch, getState) => {
    const state = getState()
    const invitesData = selectData(state)
    const invite = invitesData[inviteHash] || {}
    const {
        companyHash: hash,
        companyName: name,
        companyEnvironmentName: environment,
        userRoleType,
    } = invite

    dispatch({
        type: USER_INVITE + UPDATE,
        request: {
            method: "POST",
            url: "/editor/User/AcceptInvite",
            data: { inviteHash },
        },
        payload: {
            inviteHash,
            company: {
                hash,
                name,
                environment,
                roles: [
                    {
                        role: userRoleType || userRoles.talent,
                        status: roleStates.active,
                    },
                ],
            },
        },
    })
}

export const createDeclineInviteAction = inviteHash => ({
    type: USER_INVITE + UPDATE,
    request: {
        method: "POST",
        url: "/editor/User/DeclineInvite",
        data: { inviteHash },
    },
    payload: {
        declined: true,
        inviteHash,
    },
})

export const createLoadAndNotify = () => (dispatch, getState) => {
    const lastIdentifiers = selectIdentifiers(getState())

    dispatch(
        createLoadingAction(() => {
            const currentIdentifiers = selectIdentifiers(getState())
            const hasNewInvites = currentIdentifiers.some(
                hash => !lastIdentifiers.includes(hash),
            )

            if (hasNewInvites) {
                dispatch(createInfoAction("main:new_invite_message"))
            }
        }),
    )
}
