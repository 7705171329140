import React, { useCallback, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { withTranslation } from "react-i18next"
import values from "lodash/values"
import PerfectScrollbar from "react-perfect-scrollbar"

import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"

import withEventTracker from "lib/utils/hoc/withEventTracker"
import withUserWithInvite from "lib/utils/hoc/withUserWithInvite"

import exceptions from "constants/exceptions"
import { userStates } from "constants/enums"
import { registerForm } from "constants/uri"
import { isPremise } from "constants/internal"
import { policyType } from "constants/policies"

import Loading from "lib/views/Loading/Loading"
import EmptyState from "lib/views/EmptyState"
import Button from "lib/views/Button"
import InviteExpiredIcon from "lib/views/Icons/InviteExpired"

import CreateUserForm from "./CreateUserForm"
import connect from "./connect"
import styles from "./styles"
import { getErrorStrings } from "./helpers"

export const CreateUser = ({
    t,
    classes,
    isLoading,
    hasFetched,
    registerWithInvite,
    navigate,
    inviteHash,
    user,
    isLoggedIn,
    trackEvent,
    activeName,
    fetchPoliciesByRegion,
    termsOfServicePolicy,
    privacyPolicy,
    isPolicyLoading,
    companyName,
    error,
}) => {
    const userState = user?.userState
    const backToLogin = useCallback(() => navigate("/login"), [navigate])
    const initialValues = useMemo(
        () =>
            user
                ? {
                      invite: inviteHash,
                      firstName: user?.firstName,
                      lastName: user?.lastName,
                      emailAddress: user?.emailAddress,
                      password: null,
                      passwordConfirmation: null,
                      agreesToAllPolicies: null,
                  }
                : null,
        [user, inviteHash],
    )

    useEffect(() => {
        if (user && user.regionId) {
            fetchPoliciesByRegion(user.regionId)
        }
    }, [user])

    if (isLoggedIn || userState === userStates.active) {
        navigate("/roles")
        return null
    }

    if ((activeName || isPremise) && !inviteHash) {
        backToLogin()
        return null
    }

    const handleSubmit = formValues => {
        const signupCallback = () => {
            trackEvent("signUp", "createAccount")
            navigate("/roles")
        }

        inviteHash
            ? registerWithInvite({ ...formValues, inviteHash }, signupCallback)
            : navigate(`/register/${registerForm.region}`)
    }

    if (
        ((isLoading && !hasFetched) || !initialValues) &&
        inviteHash &&
        !error
    ) {
        return <Loading />
    }

    if (
        error?.Reason === exceptions.acceptedInvite ||
        error?.Reason === exceptions.expiredInvite ||
        error?.Reason === exceptions.revokedInvite ||
        error?.Reason === exceptions.archivedInvite ||
        error?.Reason === exceptions.declinedInvite
    ) {
        const errorStrings = getErrorStrings(error)

        return (
            <Container
                disableGutters
                component={PerfectScrollbar}
                className={classes.scrollContainer}
            >
                <EmptyState
                    headerMessage={t(errorStrings.header)}
                    message={t(errorStrings.message)}
                    iconWidth={110}
                    iconHeight={110}
                    subheaderClassName={classes.subheader}
                    icon={
                        <InviteExpiredIcon
                            className={classes.icon}
                            color="secondary"
                        />
                    }
                >
                    <Button
                        id="create-company"
                        color="secondary"
                        onClick={backToLogin}
                    >
                        <Typography color="inherit">
                            {t("main:back_to_login")}
                        </Typography>
                    </Button>
                </EmptyState>
            </Container>
        )
    }

    return (
        <CreateUserForm
            isLoading={isLoading}
            onSubmit={handleSubmit}
            inviteHash={inviteHash}
            initialValues={initialValues}
            termsOfServicePolicy={termsOfServicePolicy}
            privacyPolicy={privacyPolicy}
            isPolicyLoading={isPolicyLoading}
            companyName={companyName}
        />
    )
}

CreateUser.propTypes = {
    t: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    registerWithInvite: PropTypes.func.isRequired,
    fetchPoliciesByRegion: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    classes: PropTypes.exact({
        scrollContainer: PropTypes.string,
        subheader: PropTypes.string,
        icon: PropTypes.string,
    }).isRequired,
    inviteHash: PropTypes.string,
    activeName: PropTypes.string,
    companyName: PropTypes.string,
    user: PropTypes.shape({
        emailAddress: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        regionId: PropTypes.number,
        userState: PropTypes.oneOf(values(userStates)),
    }),
    isLoading: PropTypes.bool,
    hasFetched: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isPolicyLoading: PropTypes.bool,
    error: PropTypes.shape({
        Reason: PropTypes.string,
        Type: PropTypes.string,
    }),
    termsOfServicePolicy: PropTypes.shape({
        type: PropTypes.oneOf([...Object.values(policyType)]),
        url: PropTypes.string,
    }),
    privacyPolicy: PropTypes.shape({
        type: PropTypes.oneOf([...Object.values(policyType)]),
        url: PropTypes.string,
    }),
}

CreateUser.defaultProps = {
    isLoading: false,
    hasFetched: false,
    isLoggedIn: false,
    user: null,
    inviteHash: "",
    activeName: "",
    companyName: "",
    isPolicyLoading: false,
    termsOfServicePolicy: null,
    privacyPolicy: null,
    error: null,
}

export default compose(
    withTranslation(),
    withEventTracker(),
    withUserWithInvite(),
    withStyles(styles, { name: "CreateUser" }),
    connect,
)(CreateUser)
