import { environmentTypes } from "./environment"

const gaTrackingIdForEnvironment = env => {
    switch (env) {
        case environmentTypes.dev:
            return "UA-29133681-14"
        case environmentTypes.translation:
        case environmentTypes.uat:
            return null
        case environmentTypes.demo:
            return null
        case environmentTypes.prod:
            return "UA-29133681-4"
        default:
            return "UA-29133681-14"
    }
}

const constants = {
    trackingID: gaTrackingIdForEnvironment(process.env.REACT_APP_ENV),

    // NOT CURRENTLY USING
    // getPage function will decide which page view to send to analytics
    pageViews: {},

    // Event Action Names are stored here
    events: {
        // Sign-up
        createAccount: "Create an account",
        selectRegion: "Select a region",
        createCompany: "Create a company",
        selectPlan: "Select a plan",

        // Profile menu
        openProfile: "Open profile",
        openFileLibrary: "Open file library",
        openTheming: "Open theming",
        openBilling: "Open billing",
        openCompanySettings: "Open company settings",
        openSwitchEnvironment: "Open switch environment",
        openLogout: "Open logout",

        // Workspaces
        addWorkspace: "Add workspace",
        deleteWorkspace: "Delete workspace",
        openWorkspace: "Open workspace",

        // Journeys overview
        addJourney: "Add a journey",
        renameJourney: "Rename a journey",
        duplicateJourney: "Duplicate a journey",
        deleteJourney: "Delete a journey",
        openJourney: "Open a journey",
        previewJourney: "Preview a journey",

        // Journey editor
        openStatus: "Open journey status",
        setLive: "Change journey status to live",
        setDraft: "Change journey status to draft",
        addStep: "Add a step",
        deleteStep: "Delete step",
        addTopicToStep: "Add topic to step",
        addStoryToStep: "Add story to step",
        removeTopicFromStep: "Remove topic from step",
        removeStoryFromStep: "Remove story from step",
        createMessage: "Create new message",
        addMessageToStep: "Add message to step",
        removeMessageFromStep: "Remove message from step",
        addToDoItem: "Add new to do item",
        addToDoItemToStep: "Add to do item to step",
        removeToDoItemFromStep: "Remove to do item from step",
        saveJourney: "Save journey",
        editJourney: "Edit journey",

        // Users
        inviteUser: "Invite a user",
        showAll: "Show all",
        showTalents: "Show talents",
        showManagers: "Show managers",
        showAdmins: "Show admins",
        showImported: "Show imported users",
        openUserProfile: "Open user profile",
        removeUser: "Remove user",
        changeUserRole: "Change user role",
        addJourneyToUser: "Add journey to existing user",
        resendInvitation: "Resend invitation",
        revokeInvitation: "Revoke invitation",
        revokeCourseAccess: "Revoke course access",

        // Content
        addTopic: "Add topic",
        renameTopic: "Rename topic",
        changeTopicImage: "Change image of topic",
        deleteTopic: "Delete topic",
        addStory: "Add story",
        renameStory: "Rename story",
        changeStoryImage: "Change image of story",
        deleteStory: "Delete story",
        addPage: "Add page",
        renamePage: "Rename page",
        deletePage: "Delete page",

        // Page creator
        selectBlank: "Select blank page",
        selectText: "Select text page",
        selectImage: "Select image page",
        selectTextChoice: "Select text choice page",
        selectImageChoice: "Select image choice page",
        selectEssay: "Select essay page",
        selectRating: "Select rating page",
        selectRanking: "Select ranking page",
        closeWithoutCreating: "Close without creating a page",

        // Content editor
        nextPage: "Navigate to next page",
        previousPage: "Navigate to previous page",
        desktopView: "Toggle to desktop view",
        mobileView: "Toggle to mobile view",
        addHeader: "Add header",
        addText: "Add text",
        addImage: "Add image",
        addVideo: "Add video",
        addButton: "Add button",
        addEmbed: "Add embed",
        addScore: "Add score",
        addEssay: "Add essay",
        addChoiceImage: "Add image choice",
        addChoiceText: "Add text choice",
        addOpinionScale: "Add opinion scale",
        addRating: "Add rating",
        addRanking: "Add ranking",
        addMatchingImage: "Add image matching",
        addMatchingText: "Add text matching",

        // Error
        encounteredErrorScreen: "Encountered an error screen",
    },

    categories: {
        signUp: "Sign-up",
        profileMenu: "Profile menu",
        workspaces: "Workspaces",
        journeysOverview: "Journeys overview",
        journeyEditor: "Journey editor",
        users: "Users",
        content: "Content",
        pageCreator: "Page creator",
        contentEditor: "Page editor",
        error: "Error",
    },
}

export default constants
