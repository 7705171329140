import { createSelector } from "reselect"

import { journeyStates } from "constants/enums"
import { createReducer } from "redux/utility"
import { createEntitiesFeatureSelector } from "redux/reducers/entities/helpers"

import { selectRelations } from "redux/reducers/entities/relations"

import { selectSelectedHash as selectCurrentWorkspaceHash } from "../workspaces"

import * as selectors from "./selectors"
import { initialState, reducers } from "./reducers"
import { reducerName } from "./actionNames"

export * from "./reducers"
export * from "./schema"
// selectors
export const selectFeature = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)
export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)
export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)
export const selectHasFetched = createSelector(
    selectFeature,
    selectors.selectHasFetched,
)
export const selectHasFetchedTalentJounrneys = createSelector(
    selectFeature,
    selectors.selectHasFetchedTalent,
)
export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)
export const selectSelectedHash = createSelector(
    selectFeature,
    selectors.selectSelectedHash,
)
export const selectTalentIdentifiers = createSelector(
    selectFeature,
    selectors.selectTalentIdentifiers,
)
export const selectJourneys = createSelector(
    selectData,
    selectIdentifiers,
    (journeysData, identifiers) =>
        identifiers?.reduce((journeys, id) => {
            const journey = journeysData[id]

            if (!journey) return journeys

            const { liveJourneyHash } = journey

            if (!liveJourneyHash) journeys.push(journey)

            return journeys
        }, []),
)
export const selectTalentJourneys = createSelector(
    selectData,
    selectTalentIdentifiers,
    (journeys, identifiers) => identifiers?.map(id => journeys[id]),
)
export const selectCurrentJourney = createSelector(
    selectData,
    selectSelectedHash,
    (journeys, selectedHash) => journeys?.[selectedHash],
)
export const selectWorkspaceJourneys = createSelector(
    selectCurrentWorkspaceHash,
    selectJourneys,
    (workspaceHash, journeys) =>
        journeys?.filter(journey => journey.workSpaces.includes(workspaceHash)),
)
export const selectPublishedJourneys = createSelector(
    selectJourneys,
    journeys =>
        journeys?.filter(journey => journey.state === journeyStates.live),
)

export const selectCurrentJourneyRelations = createSelector(
    selectCurrentJourney,
    selectRelations,
    (journey, relations) =>
        relations?.filter(relation =>
            journey?.relationDefinitionDependencies?.some(
                dependency => dependency === relation.hash,
            ),
        ),
)

export const reducer = createReducer(reducers, initialState)
