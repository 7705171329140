import { fade } from "@material-ui/core"

const styles = theme => ({
    root: {
        display: "flex",
        width: "100%",
        maxWidth: "220px",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        flexWrap: "wrap",
        flexDirection: "column",
        backgroundColor: "transparent",
        padding: `${theme.spacing(2)}px ${theme.spacing()}px`,
        margin: "0 auto",
        color: "inherit",
    },

    subheading: {
        textAlign: "center",
        marginBottom: `${theme.spacing(3)}px`,
        color: "inherit",

        "&:nth-child(3)": {
            marginBottom: `${theme.spacing(5)}px`,
        },
    },

    warningIcon: {
        width: "64px",
        height: "64px",
        borderRadius: "50%",
        padding: theme.spacing(2.5),
        margin: `0 auto ${theme.spacing(4)}px auto`,
        backgroundColor: fade(theme?.palette?.error?.main, 0.1),
        color: theme?.palette?.error?.main,
        textAlign: "center",        
    },

    icon: {
        width: "100%",
        height: "100%",
        fill: "currentColor",
    }
})

export default styles
