import keys from "lodash/keys"
import get from "lodash/get"
import { fileType } from "../../../../constants/enums"

import { noImage } from "../../../../constants/internal"

import { CREATE, UPDATE, DELETE, SELECT } from "../../../utility"
import { LOADING, LOADED, ERROR } from "../../../middleware/actionNames"

import { LOGOUT } from "../../core/authentication/actionNames"
import { COMPANY } from "../companies/actionNames"
import { FOLDERS } from "../folders/actionNames"

import { FILE, UPDATE_TEMP_URL, DELETE_SELECTED_HASH } from "./actionNames"

import { selectData } from "./selectors"
import { extractFiles } from "./helpers"
import itemNormalizer from "./schema"

export const initialState = {
    /* Holds the normalized files */
    data: {},
    /* Holds the normalized file identifiers */
    identifiers: [],
    /* Indicates whether or not files have been fetched */
    hasFetched: false,
    /* Indicates whether actions are working on a file */
    isLoading: false,
    /* Holds an error if the last action was erroneous */
    loadingError: null,
    /* Holds the selected file hash */
    selectedHash: null,
}

export const reducers = {
    [FILE + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [FILE + LOADED](state, { result, originalPayload: { hash } }) {
        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [hash]: {
                    ...result,
                    hash,
                },
            },
            identifiers: [...state.identifiers, hash],
        }
    },

    [FILE + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError,
        }
    },

    [FILE + CREATE + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [FILE + CREATE + LOADED](
        state,
        { result, originalPayload: { file, isSystemFile } },
    ) {
        const hash = isSystemFile
            ? get(result, "hash")
            : get(result, "[0].hash")

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [hash]: {
                    ...file,
                    hash,
                },
            },
            identifiers: [...state.identifiers, hash],
        }
    },

    [FILE + CREATE + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError,
        }
    },

    [FILE + UPDATE + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [FILE + UPDATE + LOADED](
        state,
        {
            result: { pending, originalUrl, type, ...updatedFile },
            originalPayload: hash,
        },
    ) {
        const data = selectData(state)
        const currentFile = get(data, hash, {})
        const thumbnailUrl = fileType.video
            ? updatedFile.thumbnailUrl
            : originalUrl

        return {
            ...state,
            isLoading: false,
            data: {
                ...data,
                [hash]: {
                    ...currentFile,
                    ...updatedFile,
                    pending,
                    thumbnailUrl,
                    originalUrl,
                },
            },
        }
    },

    [FILE + UPDATE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [FILE + DELETE + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [FILE + DELETE + LOADED](state, { originalPayload }) {
        const { data: oldData } = state
        const data = { ...oldData }
        const fileHashes = get(originalPayload, "fileHashes", [])
        const identifiers = keys(data)

        fileHashes.forEach(hash => {
            delete data[hash]
        })

        return {
            ...state,
            isLoading: false,
            data,
            identifiers,
        }
    },

    [FILE + DELETE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [FILE + UPDATE_TEMP_URL](state, { fileHash, externalUrl }) {
        const data = selectData(state)
        const { [fileHash]: currentFile } = data

        return {
            ...state,
            data: {
                ...data,
                [fileHash]: {
                    ...currentFile,
                    thumbnailUrl: externalUrl,
                    originalUrl: externalUrl,
                },
            },
        }
    },

    [FILE + SELECT](state, { selectedHash }) {
        return {
            ...state,
            selectedHash,
        }
    },

    [FOLDERS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [FOLDERS + LOADED](state, { result }) {
        const files = extractFiles(result)
        const data = itemNormalizer(files)

        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            data: {
                ...state.data,
                ...data,
            },
            identifiers: [
                ...new Set([...state.identifiers, ...Object.keys(data)]),
            ],
        }
    },

    [FOLDERS + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [FILE + DELETE_SELECTED_HASH](state, isDelete) {
        const { data: oldData } = state
        const data = { ...oldData }

        return {
            ...state,
            isWorking: false,
            data,
            selectedHash: isDelete ? noImage : null,
        }
    },

    // reducer reset

    [COMPANY + SELECT]() {
        return initialState
    },

    [LOGOUT + LOADED]() {
        return initialState
    },
}
