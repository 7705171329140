import get from "lodash/get"
import first from "lodash/first"
import find from "lodash/find"

import { merge } from "lib/utils/helpers/instances"

import { userRoles, companyRoles, roleStates } from "constants/enums"
import { userType } from "constants/internal"
import { ERROR, LOADED, LOADING } from "redux/middleware/actionNames"
import { CREATE, UPDATE, DELETE, SUBSCRIBE, SELECT } from "redux/utility"

import {
    LOGOUT,
    LOGIN,
    VALIDATE_EMAIL,
} from "redux/reducers/core/authentication/actionNames"
import { ENVIRONMENT } from "redux/reducers/core/environment/actionNames"
import { USER_INVITE } from "../userInvites/actionNames"
import { USER, LOGGED_IN_USER, USER_ROLES } from "../users/actionNames"
import { PLAN } from "../plans/actionNames"
import { INVITE } from "../invites/actionNames"

import {
    COMPANY,
    TRANSFER_OWNERSHIP,
    BILLING_INFO,
    PAYMENT_INFO,
    SEAT_COUNT,
} from "./actionNames"
import { IsJourneyInviteActive, mapEnvironment } from "./helpers"
import normalize from "./schema"

import {
    selectData,
    selectIdentifiers,
    selectHasFetchedBillingInfo,
    selectSelectedHash,
    selectCompanies,
    selectCompanySubscription,
    selectCurrentCompany,
} from "./selectors"

// Required variables
export const initialState = {
    /* holds a description of all the companies identified by their hash */
    data: {},
    /* holds all the companies identifiers */
    identifiers: [],
    /* Indicates whether or not companies have been fetched */
    hasFetched: false,
    /* holds the company identifiers of which the billing has been fetched */
    hasFetchedBillingInfo: [],
    /* Are we loading new courses */
    isLoading: false,
    /* Loading courses resulted in an error */
    loadingError: null,
    /* hold the identifier of the current environment company */
    selectedHash: null,
    /* hold the enum of the role of the user in the current company */
    selectedRole: null,
}

export const reducers = {
    [COMPANY + CREATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [COMPANY + CREATE + LOADED](state, { result: { hash }, originalPayload }) {
        const data = selectData(state)
        const oldIdentifiers = selectIdentifiers(state)
        const oldHasFetchedBillingInfo = selectHasFetchedBillingInfo(state)

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: {
                ...data,
                [hash]: {
                    hash,
                    roles: [
                        { role: userRoles.owner, status: roleStates.active },
                    ],
                    companyRoleType: companyRoles.clientCompany,
                    ...originalPayload,
                },
            },
            identifiers: [...oldIdentifiers, hash],
            hasFetchedBillingInfo: [...oldHasFetchedBillingInfo, hash],
            selectedHash: hash,
        }
    },

    [COMPANY + CREATE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [COMPANY + UPDATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [COMPANY + UPDATE + LOADED](state, { originalPayload: updatedCompany }) {
        const data = selectData(state)

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: {
                ...data,
                [updatedCompany.hash]: {
                    ...data[updatedCompany.hash],
                    ...updatedCompany,
                },
            },
        }
    },

    [COMPANY + UPDATE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [COMPANY + TRANSFER_OWNERSHIP + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [COMPANY + TRANSFER_OWNERSHIP + LOADED](
        state,
        { originalPayload: { hash } },
    ) {
        const data = selectData(state)
        const { roles, ...rest } = data[hash]
        const updatedRoles = [
            ...roles.filter(
                ({ role }) =>
                    role !== userRoles.owner ||
                    role !== userRoles.administrator,
            ),
            userRoles.administrator,
        ]

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: {
                ...data,
                [hash]: {
                    ...rest,
                    roles: updatedRoles,
                },
            },
        }
    },

    [COMPANY + TRANSFER_OWNERSHIP + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [COMPANY + DELETE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [COMPANY + DELETE + LOADED](state, { originalPayload: { hash } }) {
        const oldData = { ...selectData(state) }
        const oldIdentifiers = selectIdentifiers(state)
        const oldHasFetchedBillingInfo = selectHasFetchedBillingInfo(state)
        delete oldData[hash]

        return {
            ...state,
            isLoading: false,
            loadingError: null,
            data: oldData,
            identifiers: oldIdentifiers.filter(
                companyHash => companyHash !== hash,
            ),
            hasFetchedBillingInfo: oldHasFetchedBillingInfo.filter(
                companyHash => companyHash !== hash,
            ),
        }
    },

    [COMPANY + DELETE + ERROR](state, payload) {
        return { ...state, isLoading: false, loadingError: payload.result }
    },

    [COMPANY + BILLING_INFO + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [COMPANY + BILLING_INFO + LOADED](
        state,
        { result, originalPayload: { companyHash } },
    ) {
        const data = selectData(state)
        const hasFetchedBillingInfo = selectHasFetchedBillingInfo(state)
        const company = data[companyHash]
        const { creditCardInfo, ...restInfo } = result

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [companyHash]: {
                    ...company,
                    ...restInfo,
                    paymentMethod: {
                        ...get(company, "paymentMethod", {}),
                        creditCard: {
                            ...get(company, "paymentMethod.creditCard", {}),
                            ...creditCardInfo,
                        },
                    },
                },
            },
            hasFetchedBillingInfo: [...hasFetchedBillingInfo, companyHash],
        }
    },

    [COMPANY + BILLING_INFO + ERROR](
        state,
        { result: loadingError, originalPayload: { companyHash } },
    ) {
        const hasFetchedBillingInfo = selectHasFetchedBillingInfo(state)

        return {
            ...state,
            isLoading: false,
            loadingError,
            hasFetchedBillingInfo: [...hasFetchedBillingInfo, companyHash],
        }
    },

    [COMPANY + BILLING_INFO + UPDATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [COMPANY + BILLING_INFO + UPDATE + LOADED](
        state,
        { originalPayload: { companyHash, billingInfo } },
    ) {
        const data = selectData(state)
        const company = data[companyHash]
        const { billingInfo: oldBillingInfo } = company

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [companyHash]: {
                    ...company,
                    billingInfo: {
                        ...oldBillingInfo,
                        ...billingInfo,
                    },
                },
            },
        }
    },

    [COMPANY + BILLING_INFO + UPDATE + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            isLoading: false,
            loadingError,
        }
    },

    [COMPANY + PAYMENT_INFO + UPDATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [COMPANY + PAYMENT_INFO + UPDATE + LOADED](
        state,
        { result: paymentMethod, originalPayload: { companyHash } },
    ) {
        const data = selectData(state)
        const company = data[companyHash]
        const { paymentMethod: oldPaymentMethod } = company

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [companyHash]: {
                    ...company,
                    paymentMethod: {
                        ...oldPaymentMethod,
                        ...paymentMethod,
                    },
                },
            },
        }
    },

    [COMPANY + PAYMENT_INFO + UPDATE + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            isLoading: false,
            loadingError,
        }
    },

    [COMPANY + SEAT_COUNT + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [COMPANY + SEAT_COUNT + LOADED](
        state,
        { result: subscription, originalPayload: { companyHash } },
    ) {
        const data = selectData(state)
        const hasFetchedBillingInfo = selectHasFetchedBillingInfo(state)
        const company = data[companyHash]

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [companyHash]: {
                    ...company,
                    subscriptions: [subscription],
                },
            },
            hasFetchedBillingInfo: [...hasFetchedBillingInfo, companyHash],
        }
    },

    [COMPANY + SEAT_COUNT + ERROR](
        state,
        { result: loadingError, originalPayload: { companyHash } },
    ) {
        const hasFetchedBillingInfo = selectHasFetchedBillingInfo(state)

        return {
            ...state,
            isLoading: false,
            loadingError,
            hasFetchedBillingInfo: [...hasFetchedBillingInfo, companyHash],
        }
    },

    [COMPANY + SELECT](state, { selectedHash }) {
        if (!selectedHash) {
            return { ...state, selectedHash: null }
        }

        return {
            ...state,
            selectedHash,
        }
    },

    // ENVIRONMENT related reducers

    [ENVIRONMENT + LOADED](
        state,
        { result: { company }, originalPayload: { name } },
    ) {
        const oldData = { ...selectData(state) }
        const oldIdentifiers = selectIdentifiers(state)
        const { companyHash } = company
        const preppedCompany = {
            ...company,
            hash: companyHash,
            environment: name,
        }

        if (oldIdentifiers.includes(companyHash)) {
            const currentCompany = oldData[companyHash] || {}

            return {
                ...state,
                data: {
                    ...oldData,
                    [companyHash]: {
                        ...currentCompany,
                        ...preppedCompany,
                    },
                },
                selectedHash: companyHash,
            }
        }

        const [identifiers, data] = normalize([preppedCompany])

        return {
            ...state,
            identifiers: [...oldIdentifiers, ...identifiers],
            data: {
                ...oldData,
                ...data,
            },
            selectedHash: companyHash,
        }
    },

    // USERS related reducers

    [LOGGED_IN_USER + LOADED](
        state,
        {
            result: { companies },
            originalPayload: { companyHash: activeCompanyHash },
        },
    ) {
        const oldData = selectData(state)
        const currentCompanies = selectCompanies(state)
        const [identifiers, data] = normalize(
            mapEnvironment(companies || currentCompanies),
        )
        const currentlySelectedHash = selectSelectedHash(state)

        const selectedHash =
            activeCompanyHash ||
            currentlySelectedHash ||
            (get(companies, "length") === 1 ? get(companies, "[0].hash") : null)

        return {
            ...state,
            hasFetched: true,
            data: merge(oldData, data),
            identifiers,
            selectedHash,
        }
    },

    [LOGGED_IN_USER + ERROR](state) {
        return { ...state, hasFetched: true }
    },

    [USER + CREATE + LOADED](state, { result: { companies } }) {
        // when a user hasn't been invited to a company just return the original state
        if (!get(companies, "length", 0)) return state

        const oldData = selectData(state)
        const currentCompanies = selectCompanies(state)
        const [identifiers, data] = normalize(
            mapEnvironment(companies || currentCompanies),
        )
        const currentlySelectedHash = selectSelectedHash(state)

        const selectedHash =
            currentlySelectedHash ||
            (get(companies, "length") === 1 ? get(companies, "[0].hash") : null)

        return {
            ...state,
            hasFetched: true,
            data: merge(oldData, data),
            identifiers,
            selectedHash,
        }
    },

    [USER + DELETE + LOADED](state) {
        const data = selectData(state)
        const company = selectCurrentCompany(state)
        const subscription = selectCompanySubscription(state)

        if (get(company, "companyRoleType") !== companyRoles.clientCompany) {
            return state
        }

        // otherwise decrease the current subscription seats used of 1
        return {
            ...state,
            data: {
                ...data,
                [company.hash]: {
                    ...company,
                    subscriptions: [
                        {
                            ...subscription,
                            usedSeats: get(subscription, "usedSeats", 0) - 1,
                        },
                    ],
                },
            },
        }
    },

    // INVITES related reducers

    [USER_INVITE + UPDATE + LOADED](
        state,
        { originalPayload: { declined, company: result } },
    ) {
        if (declined) return state

        const addedRole = first(result.roles)
        if (!result || !addedRole) return state

        const data = selectData(state)
        const identifiers = selectIdentifiers(state)

        const companyExists = !!data[result.hash]
        const company = companyExists ? data[result.hash] : result
        const currentActiveJourneyCount = company?.activeJourneyCount || 0
        const activeJourneyCount =
            addedRole.role === userRoles.talent
                ? currentActiveJourneyCount + 1
                : currentActiveJourneyCount
        const existingRole = find(company?.roles, { role: addedRole.role })

        // if active activeJourneyCount has changed then update it
        if (
            company.activeJourneyCount !== activeJourneyCount &&
            activeJourneyCount
        ) {
            company.activeJourneyCount = activeJourneyCount
        }

        // if added role is a new role add it to the array
        if (!existingRole) company.roles.push(addedRole)

        // if added role already exists and it's not active
        if (existingRole && existingRole.status !== roleStates.active) {
            // acitivate it
            company.roles = company.roles.map(userRole =>
                userRole.role === addedRole.role
                    ? { ...userRole, status: roleStates.active }
                    : userRole,
            )
        }

        return {
            ...state,
            data: {
                ...state.data,
                [company.hash]: company,
            },
            identifiers: companyExists
                ? identifiers
                : [...identifiers, company.hash],
        }
    },

    [INVITE + CREATE + LOADED](state, { originalPayload: { isExistingUser } }) {
        if (isExistingUser) return state

        const data = selectData(state)
        const company = selectCurrentCompany(state)
        const subscription = selectCompanySubscription(state)

        return {
            ...state,
            data: {
                ...data,
                [company.hash]: {
                    ...company,
                    subscriptions: [
                        {
                            ...subscription,
                            usedSeats: get(subscription, "usedSeats", 0) + 1,
                        },
                    ],
                },
            },
        }
    },

    [INVITE + DELETE + LOADED](
        state,
        { originalPayload: { invites, deletedInvite, isLoggedInUser } },
    ) {
        const data = selectData(state)
        const currentCompanyHash = selectSelectedHash(state)

        // if the user has still active invites and it's not the logged in user, do nothing
        if (invites.length && !isLoggedInUser) return state

        const updatedData = { ...data }

        if (isLoggedInUser) {
            if (deletedInvite?.type === userType.talent) {
                const company = updatedData[currentCompanyHash]
                const activeJourneyCount = invites.reduce(
                    (sum, invite) =>
                        IsJourneyInviteActive(invite) ? sum + 1 : sum,
                    0,
                )

                // update the activeJourneyCount
                updatedData[currentCompanyHash] = {
                    ...company,
                    activeJourneyCount,
                }
            }
        }

        if (invites.length) {
            return {
                ...state,
                data: updatedData,
            }
        }

        const company = updatedData[currentCompanyHash]
        const subscription = selectCompanySubscription(state)

        // otherwise decrease the current subscription seats used of 1
        return {
            ...state,
            data: {
                ...data,
                [currentCompanyHash]: {
                    ...company,
                    subscriptions: [
                        {
                            ...subscription,
                            usedSeats: get(subscription, "usedSeats", 0) - 1,
                        },
                    ],
                },
            },
        }
    },

    [USER_ROLES + DELETE + LOADED](
        state,
        { originalPayload: { invites, deletedInvite, isLoggedInUser } },
    ) {
        const data = selectData(state)
        const currentCompanyHash = selectSelectedHash(state)

        // if the user has still active invites and it's not the logged in user, do nothing
        if (invites.length && !isLoggedInUser) return state

        const updatedData = { ...data }

        if (isLoggedInUser) {
            // if the deleted role is a staff role
            if (deletedInvite?.type === userType.staff) {
                const company = updatedData[currentCompanyHash]

                // archive the deleted role
                updatedData[currentCompanyHash] = {
                    ...company,
                    roles: company.roles?.map(userRole =>
                        userRole?.role === deletedInvite.userRoleType
                            ? {
                                  ...userRole,
                                  status: roleStates.archived,
                              }
                            : userRole,
                    ),
                }
            }
        }

        if (invites.length) {
            return {
                ...state,
                data: updatedData,
            }
        }

        const company = updatedData[currentCompanyHash]
        const subscription = selectCompanySubscription(state)

        // otherwise decrease the current subscription seats used of 1
        return {
            ...state,
            data: {
                ...data,
                [currentCompanyHash]: {
                    ...company,
                    subscriptions: [
                        {
                            ...subscription,
                            usedSeats: get(subscription, "usedSeats", 0) - 1,
                        },
                    ],
                },
            },
        }
    },

    [COMPANY + SUBSCRIBE + LOADED](
        state,
        { result: { hash }, originalPayload: { planHash, companyHash, seats } },
    ) {
        const data = selectData(state)
        const company = data[companyHash]
        const oldSubscriptions = get(company, "subscriptions", [])
        const hasFetchedBillingInfo = selectHasFetchedBillingInfo(state)

        return {
            ...state,
            data: {
                ...data,
                [companyHash]: {
                    ...company,
                    subscriptions: [
                        ...oldSubscriptions,
                        {
                            planHash,
                            hash,
                            totalSeats: seats,
                            usedSeats: 1,
                        },
                    ],
                },
            },
            hasFetchedBillingInfo: [...hasFetchedBillingInfo, companyHash],
            loadingError: null,
        }
    },

    [PLAN + UPDATE + LOADED](
        state,
        { originalPayload: { planHash, companyHash, seats } },
    ) {
        const data = selectData(state)
        const company = data[companyHash]
        const subscription = selectCompanySubscription(state)

        return {
            ...state,
            data: {
                ...data,
                [companyHash]: {
                    ...company,
                    subscriptions: [
                        {
                            ...subscription,
                            planHash,
                            totalSeats: seats,
                        },
                    ],
                },
            },
        }
    },

    // set companies once the login request is loaded
    [LOGIN + LOADED](state, { result: { companies } }) {
        const oldData = selectData(state)
        const [identifiers, data] = normalize(mapEnvironment(companies))
        const currentlySelectedHash = selectSelectedHash(state)

        const selectedHash =
            currentlySelectedHash ||
            (companies.length === 1 ? get(companies, "[0].hash") : null)

        return {
            ...state,
            hasFetched: true,
            data: merge(oldData, data),
            identifiers,
            selectedHash,
        }
    },

    [VALIDATE_EMAIL + LOADED](state, { result }) {
        const { companies } = result
        const oldData = selectData(state)
        const currentCompanies = selectCompanies(state)
        const [identifiers, data] = normalize(
            mapEnvironment(companies || currentCompanies),
        )
        const currentlySelectedHash = selectSelectedHash(state)

        const selectedHash = currentlySelectedHash || first(companies)?.hash

        return {
            ...state,
            hasFetched: true,
            data: merge(oldData, data),
            identifiers,
            selectedHash,
        }
    },

    // Reset reducers
    [LOGOUT + LOADED](state, { originalPayload: { isSubdomain } }) {
        if (!isSubdomain) {
            return initialState
        }

        const {
            data: { [state.selectedHash]: company },
            selectedHash,
        } = state

        return {
            ...initialState,
            data: {
                [selectedHash]: company,
            },
            selectedHash,
        }
    },
}
