import { createSelector } from "reselect"

import { companyRoles } from "constants/enums"
import { createReducer } from "redux/utility"

import { selectShowInheritedRoles } from "redux/reducers/ui/config"

import { createEntitiesFeatureSelector } from "../helpers"
import * as selectors from "./selectors"
import { reducers, initialState } from "./reducers"
import { reducerName } from "./actionNames"

export * from "./schema"
export * from "./reducers"

// selectors
export const selectFeature = createEntitiesFeatureSelector(reducerName)

export const selectData = createSelector(selectFeature, selectors.selectData)
export const selectIdentifiers = createSelector(
    selectFeature,
    selectors.selectIdentifiers,
)
export const selectSelectedHash = createSelector(
    selectFeature,
    selectors.selectSelectedHash,
)
export const selectIsLoading = createSelector(
    selectFeature,
    selectors.selectIsLoading,
)
export const selectHasFetched = createSelector(
    selectFeature,
    selectors.selectHasFetched,
)
export const selectLoadingError = createSelector(
    selectFeature,
    selectors.selectLoadingError,
)
export const selectHasFetchedBillingInfo = createSelector(
    selectFeature,
    selectors.selectHasFetchedBillingInfo,
)
export const selectInvitedCompanies = createSelector(
    selectFeature,
    selectors.selectInvitedCompanies,
)
export const selectInheritedCompanies = createSelector(
    selectFeature,
    selectors.selectInheritedCompanies,
)
export const selectCompanies = createSelector(
    selectFeature,
    selectors.selectCompanies,
)
export const selectHasFetchedCompanyBillingInfo = createSelector(
    selectFeature,
    selectors.selectHasFetchedCompanyBillingInfo,
)
export const selectHasCompany = createSelector(
    selectFeature,
    selectors.selectHasCompany,
)
export const selectHasCompanyBillingInfo = createSelector(
    selectFeature,
    selectors.selectHasCompanyBillingInfo,
)
export const selectCurrentCompany = createSelector(
    selectFeature,
    selectors.selectCurrentCompany,
)

export const selectCurrentCompanyName = createSelector(
    selectFeature,
    selectors.selectCompanyName,
)

export const selectCurrentEnvironment = createSelector(
    selectFeature,
    selectors.selectCurrentEnvironment,
)
export const selectIsAdmin = createSelector(
    selectFeature,
    selectors.selectIsAdmin,
)
export const selectIsCompanySubscribed = createSelector(
    selectFeature,
    selectors.selectIsCompanySubscribed,
)
export const selectCompanyBillingInfo = createSelector(
    selectFeature,
    selectors.selectCompanyBillingInfo,
)
export const selectCompanySubscription = createSelector(
    selectFeature,
    selectors.selectCompanySubscription,
)
export const selectCompanyPlanHash = createSelector(
    selectFeature,
    selectors.selectCompanyPlanHash,
)

export const selectIsClientCompany = createSelector(
    selectCurrentCompany,
    currentCompany =>
        currentCompany?.companyRoleType === companyRoles.clientCompany,
)

export const selectVisibleCompanies = createSelector(
    selectInvitedCompanies,
    selectInheritedCompanies,
    selectShowInheritedRoles,
    (invitedCompanies, inheritedCompanies, showInheritedRoles) =>
        showInheritedRoles
            ? [...invitedCompanies, ...inheritedCompanies]
            : invitedCompanies,
)

// Reducer

export const reducer = createReducer(reducers, initialState)
