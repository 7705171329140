import { mainSections } from "constants/uri"

export const routesApp = {
    editor: `/${mainSections.editor}/:layer?`,
    preview: `/${mainSections.preview}/:journeyHash?`,
    glossary: "/glossary",
    todo: "/todo",
    playerJourney: "/journey",
    journeyEdit: "/journeys/:journeyHash?/edit",
    reporting: "/journeys/:journeyHash?/reporting",
    theming: `/${mainSections.editor}/settings/theming`,
    introduction: `/${mainSections.editor}/page/introduction`,
}
