import React, { useEffect } from "react"
import PropTypes from "prop-types"
import getDisplayName from "@material-ui/utils/getDisplayName"
import hoistNonReactStatics from "hoist-non-react-statics"

import { isDevelopment } from "constants/internal"
import connect from "./connect"

const withUserWithInvite = ({ stripped = false } = {}) => WrappedComponent => {
    function WithUserWithInvite({
        isLoggedIn,
        isLoadingUserWithInvite,
        hasFetchedUserWithInvite,
        user,
        match: {
            params: { inviteHash },
        },
        loadUserWithInvite,
        loadingUserWithInviteError,
        ...props
    }) {
        useEffect(() => {
            if (
                !isLoadingUserWithInvite &&
                !hasFetchedUserWithInvite &&
                !loadingUserWithInviteError &&
                !isLoggedIn &&
                inviteHash
            ) {
                loadUserWithInvite(inviteHash)
            }
        }, [isLoadingUserWithInvite, hasFetchedUserWithInvite, inviteHash])

        return (
            <WrappedComponent inviteHash={inviteHash} user={user} {...props} />
        )
    }

    WithUserWithInvite.propTypes = {
        match: PropTypes.object.isRequired,
        loadUserWithInvite: PropTypes.func.isRequired,
        isLoadingUserWithInvite: PropTypes.bool,
        hasFetchedUserWithInvite: PropTypes.bool,
        user: PropTypes.shape({
            emailAddress: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        }),
        loadingUserWithInviteError: PropTypes.object,
        isLoggedIn: PropTypes.bool,
    }

    WithUserWithInvite.defaultProps = {
        isLoadingUserWithInvite: false,
        hasFetchedUserWithInvite: false,
        user: undefined,
        loadingUserWithInviteError: null,
        isLoggedIn: false,
    }

    if (isDevelopment) {
        WithUserWithInvite.displayName = `WithUserWithInvite(${getDisplayName(
            WrappedComponent,
        )})`
    }

    hoistNonReactStatics(WithUserWithInvite, WrappedComponent)

    return stripped ? WithUserWithInvite : connect(WithUserWithInvite)
}

export default withUserWithInvite
