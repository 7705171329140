import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import memoize from "memoize-one"
import classnames from "classnames"

import LinearProgress from "@material-ui/core/LinearProgress"

import styles from "./styles"

class PhasedProgressBar extends React.PureComponent {
    normalizePhases = memoize(phases => {
        const basePhase = [{ percentage: 0, className: "" }]

        if (phases instanceof Array) {
            const percentagePart = Math.round((100 / phases.length) * 100) / 100

            const calculatedPhases = phases.map((className, idx) => {
                const percentage = percentagePart * (idx + 1)
                return { percentage, className }
            }, {})

            return basePhase.concat(calculatedPhases)
        }

        const calculatedPhases = Object.keys(phases).map(percentage => ({
            percentage,
            className: phases[percentage],
        }))

        return basePhase.concat(calculatedPhases)
    })

    render() {
        const { classes, current, phases, rounded } = this.props

        return (
            <div className={classes.root}>
                {phases.map((phase, idx) => (
                    <LinearProgress
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${phase}-${idx}`}
                        className={classnames(
                            idx !== phases.length - 1 &&
                                classes.barContainerMultiBar,
                            idx === phases.length - 1 && classes.lastBar,
                        )}
                        variant="determinate"
                        color="primary"
                        value={current >= idx + 1 ? 100 : 0}
                        classes={{
                            root: classnames(classes.progressBar, {
                                [classes.rounded]: rounded,
                            }),
                            barColorPrimary: classnames(
                                classes.bar,
                                phases[current - 1],
                            ),
                        }}
                    />
                ))}
            </div>
        )
    }
}

PhasedProgressBar.propTypes = {
    classes: PropTypes.object.isRequired,
    current: PropTypes.number,
    phases: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.object,
    ]),
    rounded: PropTypes.bool,
}

PhasedProgressBar.defaultProps = {
    current: 0,
    phases: [],
    rounded: false,
}

export default withStyles(styles)(PhasedProgressBar)
