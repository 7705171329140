import { schema, normalize } from "normalizr"

// Define your comments schema
export const journeySchema = new schema.Entity(
    "journeys",
    {},
    {
        idAttribute: ({ hash, journeyHash }) => hash || journeyHash,
    },
)

export const normalizer = (originalData, entitySchema) =>
    normalize(originalData, entitySchema)
