import { connect } from "react-redux"
import { logout } from "redux/reducers/core/authentication/actions"
import {
    createFetchAllPolicies,
    createUserAgreeToPolicies,
    hasFetchedPolicies,
    isLoadingPolicies,
    selectNotAgreedPolicy,
} from "redux/reducers/entities/policy"
import { selectHasLoggedInUserAgreedToAllPolicies } from "redux/reducers/entities/users"

const mapStateToProps = state => ({
    hasFetchedPolicies: hasFetchedPolicies(state),
    isLoadingPolicies: isLoadingPolicies(state),
    hasUserAgreedToAllPolicies: selectHasLoggedInUserAgreedToAllPolicies(state),
    policy: selectNotAgreedPolicy(state),
})

const mapDispatchToProps = {
    logout,
    agreeToPolicy: (policyId, callback) =>
        createUserAgreeToPolicies([policyId], callback),
    fetchAllPolicies: createFetchAllPolicies,
}

export default connect(mapStateToProps, mapDispatchToProps)
