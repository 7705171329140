import keys from "lodash/keys"
import get from "lodash/get"

import { CREATE, UPDATE, DELETE, SELECT, MOVE } from "../../../utility"
import { LOADING, LOADED, ERROR } from "../../../middleware/actionNames"

import { LOGOUT } from "../../core/authentication/actionNames"
import { COMPANY } from "../companies/actionNames"
import { FILE } from "../files/actionNames"

import { mapFolder, mapFolders } from "./helpers"
import itemNormalizer from "./schema"
import {
    selectLibraryRootHash,
    selectSelectedHash,
    selectData,
    selectIdentifiers,
} from "./selectors"
import { FOLDER, FOLDERS } from "./actionNames"

export const initialState = {
    /* Holds the normalized folders */
    data: {},
    /* Holds the normalized folder identifiers */
    identifiers: [],
    /* Indicates whether or not folders have been fetched */
    hasFetched: false,
    /* Indicates whether actions are working on a folder */
    isLoading: false,
    /* Holds an error if the last action was erroneous  */
    loadingError: null,
    /* Holds the currently selected folder hash */
    selectedHash: null,
}

export const reducers = {
    [FOLDERS + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [FOLDERS + LOADED](state, { result }) {
        const mappedFolders = mapFolders(result)
        const data = itemNormalizer(mappedFolders)
        const identifiers = keys(data)

        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            data,
            identifiers,
        }
    },

    [FOLDERS + ERROR](state, { result: loadingError }) {
        return {
            ...state,
            isLoading: false,
            hasFetched: true,
            loadingError,
        }
    },

    [FOLDER + CREATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [FOLDER + CREATE + LOADED](state, payload) {
        const {
            result: { hash: folderHash },
            originalPayload: { name, parentHash },
        } = payload
        const data = selectData(state)
        const folder = mapFolder({ folderHash, name })
        const parent = get(data, parentHash, {})

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [parentHash]: {
                    ...parent,
                    folderHashes: [
                        ...get(parent, "folderHashes", []),
                        folderHash,
                    ],
                },
                [folderHash]: folder,
            },
            identifiers: [...state.identifiers, folderHash],
        }
    },

    [FOLDER + CREATE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [FOLDER + UPDATE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [FOLDER + UPDATE + LOADED](state, { originalPayload }) {
        const { folderHash, newFolderName } = originalPayload
        const data = selectData(state)
        const folder = get(data, folderHash, {})

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [folderHash]: {
                    ...folder,
                    name: newFolderName,
                },
            },
        }
    },

    [FOLDER + UPDATE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [FOLDER + DELETE + LOADING](state) {
        return {
            ...state,
            isLoading: true,
            loadingError: null,
        }
    },

    [FOLDER + DELETE + LOADED](state, { originalPayload: { folderHash } }) {
        const oldIdentifiers = selectIdentifiers(state)
        const oldData = selectData(state)
        const rootHash = selectLibraryRootHash(state)
        const selectedHash = selectSelectedHash(state)
        const rootFolder = get(oldData, rootHash, {})

        const identifiers = oldIdentifiers.filter(id => id !== folderHash)
        const data = {
            ...oldData,
            [rootHash]: {
                ...rootFolder,
                folderHashes: get(rootFolder, "folderHashes", []).filter(
                    hash => hash !== folderHash,
                ),
            },
        }
        delete data[folderHash]

        return {
            ...state,
            isLoading: false,
            data,
            identifiers,
            selectedHash: selectedHash === folderHash ? null : selectedHash,
        }
    },

    [FOLDER + DELETE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [FOLDER + MOVE + LOADING](state) {
        return { ...state, isLoading: true, loadingError: null }
    },

    [FOLDER + MOVE + LOADED](state, { originalPayload }) {
        const { fileHashes, sourceHash, targetHash } = originalPayload
        const {
            data: { [targetHash]: target, [sourceHash]: source },
        } = state

        const targetFileHashes = [...target.fileHashes, ...fileHashes]
        const sourceFileHashes = source.fileHashes.filter(
            hash => !fileHashes.includes(hash),
        )

        // When in custom folder and no files left, it disappears
        const selectedHash = selectSelectedHash(state)
        const folderDisappears = source.isCustom && !sourceFileHashes.length

        return {
            ...state,
            isLoading: false,
            data: {
                ...state.data,
                [sourceHash]: {
                    ...source,
                    fileHashes: sourceFileHashes,
                },
                [targetHash]: {
                    ...target,
                    fileHashes: targetFileHashes,
                },
            },
            selectedHash: folderDisappears ? null : selectedHash,
        }
    },

    [FOLDER + MOVE + ERROR](state, { result: loadingError }) {
        return { ...state, isLoading: false, loadingError }
    },

    [FOLDER + SELECT](state, { selectedHash }) {
        return { ...state, selectedHash }
    },

    [FILE + CREATE + LOADED](
        state,
        { originalPayload: { folderHash, isSystemFile }, result },
    ) {
        if (isSystemFile) return state

        const fileHash = isSystemFile
            ? get(result, "hash")
            : get(result, "data[0].hash")
        const {
            data: { [folderHash]: folder },
        } = state

        return {
            ...state,
            data: {
                ...state.data,
                [folderHash]: {
                    ...folder,
                    fileHashes: [...folder.fileHashes, fileHash],
                },
            },
        }
    },

    [FILE + DELETE + LOADED](
        state,
        { originalPayload: { folderHash, fileHashes: removedFiles } },
    ) {
        const {
            data: { [folderHash]: folder },
        } = state
        const fileHashes = folder.fileHashes.filter(
            hash => !removedFiles.includes(hash),
        )

        // When in custom folder and no files left, it disappears
        const selectedHash = selectSelectedHash(state)
        const folderDisappears = folder.isCustom && !fileHashes.length

        return {
            ...state,
            data: {
                ...state.data,
                [folderHash]: {
                    ...folder,
                    fileHashes,
                },
            },
            selectedHash: folderDisappears ? null : selectedHash,
        }
    },

    [COMPANY + SELECT]() {
        return initialState
    },

    [LOGOUT + LOADED]() {
        return initialState
    },
}
