import React from "react"
import PropTypes from "prop-types"

import {
    MdFiberManualRecord as Dot,
    MdCheckCircle as Check,
    MdClear as Error,
} from "react-icons/md"

import withStyles from "@material-ui/core/styles/withStyles"

import { Typography } from "@material-ui/core"

import styles from "./styles"

const CheckWithText = ({
    classes,
    isValid,
    text,
    textVariant,
    iconSize,
    error,
}) => {
    const getIcon = () => {
        if (isValid) {
            return <Check size={iconSize} className={classes.checkCircle} />
        }
        if (error) {
            return <Error size={iconSize} className={classes.error} />
        }
        return <Dot size={iconSize} className={classes.dot} />
    }
    return (
        <div className={classes.root}>
            {getIcon()}
            {text && (
                <Typography variant={textVariant} className={classes.text}>
                    {text}
                </Typography>
            )}
        </div>
    )
}

CheckWithText.propTypes = {
    classes: PropTypes.object.isRequired,
    isValid: PropTypes.bool,
    text: PropTypes.string,
    textVariant: PropTypes.string,
    iconSize: PropTypes.number,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

CheckWithText.defaultProps = {
    isValid: false,
    text: null,
    textVariant: "caption",
    iconSize: 12,
    error: false,
}

export default withStyles(styles)(CheckWithText)
