import { redirect } from "lib/utils/helpers/uri"
import PropTypes from "prop-types"

export const RedirectExternal = ({ to }) => {
    // eslint-disable-next-line no-undef
    redirect(to)

    return null
}

RedirectExternal.propTypes = {
    to: PropTypes.string.isRequired,
}

RedirectExternal.defaultProps = {}

export default RedirectExternal
