import { schema, normalize } from "normalizr"
import { arrayify } from "../../../../lib/utils/helpers/array"

export const folderSchema = new schema.Entity(
    "folders",
    {},
    { idAttribute: "folderHash" },
)

export default originalData => {
    const {
        entities: { folders: normalizedData },
    } = normalize(arrayify(originalData), [folderSchema])

    return normalizedData || {}
}
