import "polyfills"
import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/browser"
import CssBaseline from "@material-ui/core/CssBaseline"
import "analytics/initialize"
import { isProduction, isTranslation } from "constants/internal"
import { unregister as unregisterServiceWorker } from "registerServiceWorker"

import AppProviderWrapper from "AppProviderWrapper"
import App from "App"

import "react-perfect-scrollbar/dist/css/styles.css"
import "index.css"

if (!window.MediaStream) window.MediaStream = () => null

// eslint-disable-next-line no-undef,no-underscore-dangle
if (isProduction) {
    Sentry.init({
        release: `appicalio@${process.env.REACT_APP_VERSION}`,
        dsn: "https://c178ba9bdf3048438e37487371ace81b@sentry.io/1404749",
    })
}

if (isTranslation) {
    const script =
        '<script type="text/javascript" src="//cdn.crowdin.com/jipt/jipt.js"></script>'
    // eslint-disable-next-line no-undef
    _jipt.push(["project", "appical-io"])

    document.write(script)
}

// eslint-disable-next-line no-undef
const root = document.getElementById("root")

const renderApp = AppComponent => {
    ReactDOM.render(
        <AppProviderWrapper>
            <div className="App">
                <CssBaseline />
                <AppComponent />
            </div>
        </AppProviderWrapper>,
        root,
    )
}

renderApp(App)

if (isProduction) {
    renderApp(App)
    unregisterServiceWorker()
} else {
    unregisterServiceWorker()

    if (module.hot) {
        module.hot.accept("App", () => {
            // eslint-disable-next-line global-require
            import("App").then(AppHot => renderApp(AppHot.default))
        })
    }
}
