import memoized from "memoize-one"
import {
    darken,
    getLuminance,
    lighten,
    fade,
} from "@material-ui/core/styles/colorManipulator"
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import { checkColorShade } from "./helpers"

import processSheetOverrides from "./processSheetOverrides"
import appicalTheme from "./appicalTheme"

function getTextContrastColor(color) {
    return getLuminance(color) > 0.5 ? appicalTheme.dark : appicalTheme.white
}

const processTypography = memoized(input => ({
    useNextVariants: true,
    fontFamily: "'Muli',Roboto,'Helvetica Neue',Arial,sans-serif",
    fontDisplay: "swap",
    h1: {
        fontSize: "62px",
        fontWeight: "800",
        lineHeight: "1.25",
        color: input.title || input.primaryText,
    },
    h2: {
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "1.25",
        color: input.title || input.primaryText,
    },
    h3: {
        fontSize: "32px",
        fontWeight: "700",
        lineHeight: "1.25",
        color: input.title || input.primaryText,
    },
    h4: {
        fontSize: "24px",
        fontWeight: "700",
        lineHeight: "1.25",
        color: input.title || input.primaryText,
    },
    h5: {
        fontSize: "18px",
        fontWeight: "700",
        color: input.title || input.primaryText,
        lineHeight: "1.20",
    },
    h6: {
        fontSize: "16px",
        fontWeight: "600",
        color: "inherit",
        lineHeight: "1.20",
    },
    body1: {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "1.5",
        color: "inherit",
    },
    body2: {
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "1.5",
        color: "inherit",
    },
    button: {
        fontSize: "16px",
        fontWeight: "600",
        textTransform: "none",
        color: "inherit",
    },

    caption: {
        fontSize: "12px",
        fontWeight: "600",
        color: "inherit",
    },

    overline: {
        fontSize: "12px",
        fontWeight: "400",
        textTransform: "none",
        color: "inherit",
    },
}))

export function convertToMuiTheme(input) {
    return createMuiTheme({
        appicalTheme: input,
        palette: {
            primary: {
                light: input.primaryLight,
                main: input.primary,
                dark: input.primaryDark,
                contrastText: checkColorShade(input.mainBg),
            },
            secondary: {
                light: input.contrastLight,
                main: input.contrast,
                dark: input.contrastDark,
                contrastText: input.contrastText,
            },
            error: {
                light: input.errorLight,
                main: input.error,
                dark: input.errorDark,
                contrastText: input.errorText,
            },
            success: {
                light: input.successLight,
                main: input.success,
                dark: input.successDark,
                contrastText: input.successText,
            },
            info: {
                light: input.infoLight,
                main: input.info,
                dark: input.infoDark,
                contrastText: input.infoText,
            },
            warning: {
                light: input.warningLight,
                main: input.warning,
                dark: input.warningDark,
                contrastText: input.warningText,
            },
            interactive: {
                main: input.primary,
                contrastText: checkColorShade(input.primary),
            },
            button: {
                main: input.contrast,
                contrastText: checkColorShade(input.contrast),
            },
            label: {
                main: input.label,
            },
            border: {
                main: input.border,
            },
            disable: {
                main: input.disable,
            },
            hover: {
                main: input.hover,
            },
            textFieldBorder: {
                main: input.textFieldBorder,
            },
            textField: {
                main: input.textField,
            },
            title: {
                main: input.title,
            },
        },
        typography: processTypography(input),
        overrides: processSheetOverrides({
            ...input,
            typography: processTypography(input),
        }),
        props: {
            // The component name ⚛️
            MuiButtonBase: {
                // The property to apply
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
    })
}

export function fillMissingThemeValues(input = {}) {
    // Set all possible values based on the input
    const theme = { ...appicalTheme }
    // -- BASIC

    // Required values. Fallback to standard appical colors
    theme.primary = input.primary || appicalTheme.primary
    theme.contrast = input.contrast || appicalTheme.contrast
    theme.mainBg = input.mainBg || lighten(theme.primary, 0.12)
    theme.card = input.card || appicalTheme.card

    // The rest can be inferred from the other values or overridden by the user.
    theme.contrastOnWhite = input.contrastOnWhite || theme.contrast

    // -- ADVANCED

    // Dark and light versions are always inferred
    theme.primaryDark = darken(theme.primary, 0.12)
    theme.primaryLight = lighten(theme.primary, 0.12)
    // Goes black or white for maximum contrast
    theme.primaryText = getTextContrastColor(
        input.isCustomTheme ? theme.mainBg : theme.primary,
    )
    theme.title = input.title

    // Dark and light versions are always inferred
    theme.contrastDark = darken(theme.contrast, 0.12)
    theme.contrastLight = lighten(theme.contrast, 0.12)
    theme.contrastText = getTextContrastColor(theme.contrast)

    // Take these values from the default ThemeProvider unless specified in the input
    theme.success = input.success || appicalTheme.success
    theme.successText = theme.white
    theme.successDark = darken(theme.success, 0.12)
    theme.successLight = lighten(theme.success, 0.12)

    theme.error = input.error || appicalTheme.error
    theme.errorLight = lighten(theme.error, 0.12)
    theme.errorDark = darken(theme.error, 0.12)
    theme.errorText = getTextContrastColor(theme.error)

    theme.info = input.info || appicalTheme.info
    theme.infoLight = lighten(theme.info, 0.12)
    theme.infoDark = darken(theme.info, 0.12)
    theme.infoText = getTextContrastColor(theme.info)

    theme.warning = input.warning || appicalTheme.warning
    theme.warningLight = lighten(theme.warning, 0.12)
    theme.warningDark = darken(theme.warning, 0.12)
    theme.warningText = getTextContrastColor(theme.warning)

    theme.score = input.score || appicalTheme.score
    theme.scoreText = getTextContrastColor(theme.score)
    // Normally this is the success color
    theme.checkmark = input.checkmark || theme.success

    theme.label = input.label || fade(theme.primaryText, 0.5)

    theme.border = input.border || fade(theme.primaryText, 0.3)

    theme.disable = input.disable || lighten(theme.contrast, 0.25)

    theme.hover = input.hover || fade(theme.contrast, 0.1)

    theme.textFieldBorder = input.textFieldBorder || fade(theme.contrast, 0.1)

    theme.textField = input.textField || fade(theme.contrast, 0.05)

    return theme
}

export const createThemeFromApi = memoized(input => {
    const theme = input
        ? {
              primary: `#${input.headerColorHex}`,
              secondary: `#${input.contrastBackgroundColorHex}`,
              title: `#${input.textColorHex}`,
              // contrast: input.contrastBackgroundColorHex,
              contrast: `#${input.contrastBackgroundColorHex}`,
              mainBg: `#${input.backgroundColorHex}`,
              label: fade(`#${input.textColorHex}`, 0.5),
              border: fade(`#${input.textColorHex}`, 0.3),
              disable: lighten(`#${input.contrastBackgroundColorHex}`, 0.25),
              hover: fade(`#${input.contrastBackgroundColorHex}`, 0.1),
              button: `#${input.contrastBackgroundColorHex}`,
              isCustomTheme: true,
          }
        : {
              isCustomTheme: false,
          }

    return convertToMuiTheme(fillMissingThemeValues(theme))
})
